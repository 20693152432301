import {
  FETCH_ORDERS,
FETCH_SINGLE_ORDER_BEGIN,
FETCH_SINGLE_ORDER_DONE,
FETCH_SINGLE_ORDER,
TOTAL_ORDER_COUNT,
FETCH_ORDERS_BEGIN,
FETCH_ORDERS_END
    } from "./orderActionTypes";

const initialState = {
    order_details:{},
    ordloading : false,
    orders:[],
    loading:false,
    order_detail : '',
    totalCount:0,
    infiniteLimit:25
};

export default function orderReducer(state = initialState, action) {
     switch (action.type) {
        case TOTAL_ORDER_COUNT:
            return { ...state,
                totalCount: action.count }; 
        case FETCH_ORDERS:
            return { ...state,
                orders: action.orders }; 
        case FETCH_ORDERS_BEGIN:
            return { ...state,
                loading: true };
        case FETCH_ORDERS_END:
            return { ...state,
                loading: false };
        case FETCH_SINGLE_ORDER_BEGIN:
            return { ...state,
                ordloading: true };
        case FETCH_SINGLE_ORDER_DONE:
            return { ...state,
                ordloading: false };
        case FETCH_SINGLE_ORDER:
            return { ...state,
                order_details: action.order };  
        default:
            return state;
           
    }
}
