export default {
    name: 'Goospares.com',
    fullName: 'Goospares - Tools Store',
    url: 'http://ionraffi.co.in/',
    author: {
        name: 'Raffi',
        fb_url: 'https://www.facebook.com/GoosparesOnline/',
        tw_url: 'https://twitter.com/GooSpares',
        gp_url: 'https://plus.google.com/u/0/101321980525036525477',
        ln_url: 'https://www.linkedin.com/company/goosparesonline/',
        inta_url: 'https://www.instagram.com/goospares_onlinewarehouse/',
        yt_url:'https://www.youtube.com/channel/UC6YwVaR6FNmsE17ABbNGpiA'

    },
    contacts: {
        addressline1: 'Module No.F12 Agaram Unit,',
        addressline2:'No 27 & 28 Phase-I SDF-I, MEPZ SEZ Tambaram,',
        addressline3:'Chennai-600045, Tamil Nadu, India.',
        email: 'info@goospares.com',
        phone: '+91 89399 90568',
    },
};
