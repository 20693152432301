import {
  FETCH_SUGGESTIONS,
CLEAR_SUGGESTIONS,
SET_SEARCH_QUERY
    } from "./searchActionTypes";

const initialState = {
    searchQuery : '',
    suggestions : [],
};

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
 		case FETCH_SUGGESTIONS:
            return { ...state,
                suggestions: action.suggestions };
        case CLEAR_SUGGESTIONS:
            return { ...state,
                suggestions: action.suggestions };
        case SET_SEARCH_QUERY:
            return { ...state,
                searchQuery: action.searchQuery };      
        default:
            return state;
   }
}