// react
import React, {Component} from 'react';

// third-party
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// application
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';

// data stubs
import theme from '../../data/theme';
import SimpleReactValidator from 'simple-react-validator';
import {connect} from "react-redux";

import { toast  } from 'react-toastify';
import { loginCustomer,loginStatus,resetPassword,registerCustomer } from '../../store/user';
import {withRouter} from 'react-router-dom';


class AccountPageRegister extends Component  {
      constructor(props) {
    super(props);
    this.state = {
     "password": "",
     "userName": "",
     "custFirstName": "",
     "custLastName": "",
     "emailAddress": "",
     "mobileNumber": "",
     "custGSTIN":"", 
     "storeCode":"DEFAULT",
     "language":"en",
     "forgotPassword":false,
     "reset_success":false,
     "registerOpen":true,
     "loginOpen":false
    };
     this.validator = new SimpleReactValidator();
    this.setStateFromInput = this.setStateFromInput.bind(this);
    this.forgotpasswordManage = this.forgotpasswordManage.bind(this);
    this.registerUserManage = this.registerUserManage.bind(this);
  }
   setStateFromInput = (event) => {
        var obj = {};
        if(event.target.dataset.object){
             var key = event.target.dataset.object;
             var name = event.target.name;
             var value =  event.target.value;
                this.setState(state => {
                    if(name == 'emailAddress'){
                        state[key]['userName'] = value; 
                    } 
                    state[key][name] = value;
                    return state;
                })
           }
           else{
            if(event.target.name == 'emailAddress'){
                obj['userName'] = event.target.value; 
            } 
               obj[event.target.name] = event.target.value;
               this.setState(obj);
        }
    }

     handleSubmit = (event) => {
    console.log(event);
    event.preventDefault();
    if (this.validator.allValid()) {
             this.props.dispatch(registerCustomer(this.state))
              .then(response => {
                      toast.success("Registration Success.");
                })
                  .catch(err => {
                    console.log(err); 
                      toast.error(err.message);
                  });
       
    } else {
              this.validator.showMessages();
              this.forceUpdate();
    }
  }

  forgotpasswordManage = () => {
     this.validator = new SimpleReactValidator();
     this.setState(state => {
            state['forgotPassword'] = !state.forgotPassword;
            state['loginOpen'] = !state.loginOpen;
            state['reset_success'] = false;
            return state;
      });
  }



  


  registerUserManage = () => {
     this.validator = new SimpleReactValidator();
     this.setState(state => {
            state['registerOpen'] = !state.registerOpen;
            state['loginOpen'] = false;
            state['forgotPassword'] = false;
            state['reset_success'] = false;
            return state;
      });
   }

  render(){
    const breadcrumb = [
        { value: 'Home', lineage: '' },
        { value: 'My Account', lineage: '' },
    ];
    const { register_status , register_loading } = this.props;
    let content;
    let loadSection;
    loadSection = () => {
      
         content = (<React.Fragment>
                                       <div className="form-group">
                                            <label htmlFor="login-email">Name</label>
                                            <input
                                                id="register-firstname"
                                                type="text"
                                                name="custFirstName"
                                                className="form-control"
                                                placeholder="Enter name"
                                                value={this.state.custFirstName} onChange={this.setStateFromInput} />
                                                    {this.validator.message('custFirstName', this.state.custFirstName, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="login-email">Email address</label>
                                            <input
                                                id="register-emailId"
                                                type="email"
                                                name="emailAddress"
                                                className="form-control"
                                                placeholder="Enter email"
                                                value={this.state.emailAddress} onChange={this.setStateFromInput} />
                                                    {this.validator.message('emailAddress', this.state.emailAddress, 'required|email')}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="login-password">Password</label>
                                            <input
                                                id="register-password"
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                placeholder="Password"
                                                value={this.state.password} onChange={this.setStateFromInput} />
                                                    {this.validator.message('password', this.state.password, 'required')}
                                        </div>
                                         <div className="form-group">
                                            <label htmlFor="login-email">Contact(Mobile)</label>
                                            <input
                                                id="register-mobile"
                                                type="text"
                                                name="mobileNumber"
                                                className="form-control"
                                                placeholder="Enter contact number"
                                                value={this.state.mobileNumber} onChange={this.setStateFromInput} />
                                                    {this.validator.message('mobileNumber', this.state.mobileNumber, 'required|phone')}
                                        </div>
                                        <div className="form-group d--btn-block">
                                        <button type="submit" className={classNames('btn btn-primary d-flex-btn', {
                                            'btn-loading': register_loading})}>
                                            Register
                                        </button>
                                             <Link to="/account/login"><span className="d-flex-span">Already customer, login now? </span>
                                             </Link>
                                        </div>
                                        </React.Fragment>)
     
   
    return content;
  }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Register | My account | Create account - ${theme.name}`}</title>
                <meta name="Description" content={`Register and get access to top brand industrial products online - Goospares.com | Registration is free, fast and simple and allows you to access your own dashboard.`}/>
            </Helmet>

            <PageHeader header="My Account" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                { register_status == 'init' ? 
                                <div className="card-body">

                                <h3 className="card-title">Register</h3> 
                                    <form onSubmit={this.handleSubmit.bind(this)} noValidate>
                                        {loadSection()}
                                    </form>

                                </div>
                                :
                                <div className="card-body">

                                <h3 className="card-title">Registration Success</h3> 
                                    <p>Welcome to Goospares. We have sent a welcome mail to your email address.</p>

                                </div>
                              }
                            </div>
                        </div>
                        <div className="col-md-5 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title">Benefits of Registering!</h3>
                                    <ul>
                                    <li>Place Bulk Orders</li>
                                    <li>Best Discounts on Lot Sale</li>
                                    <li>Your Requirements on budget</li>
                                    <li>Free access on the Product Catalogue</li>
                                    <li>Transparent pricing models</li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
}


function mapStateToProps(state) {
    return {
        register_loading : state.user.register_loading,
        register_status :  state.user.register_status
    }
}

export default connect(mapStateToProps)(withRouter(AccountPageRegister))




