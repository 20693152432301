import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_UPDATE_QUANTITIES,CART_UPDATE_BEGIN,
    CART_UPDATE_END,
    ADD_TO_CART,
    REMOVE_FROM_CART,
    DECREMENT_QTY,
    LIST_CART,
    READY_TO_ORDER,
    CART_MINIMUM_VALUE,
    CART_INR_TOTAL,
CART_INR_SUBTOTAL,
CART_USD_TOTAL,
CART_USD_SUBTOTAL,
CART_SAR_TOTAL,
CART_SAR_SUBTOTAL,UPDATE_TO_CART,RESET_CART } from './cartActionTypes';


/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */
function findItemIndex(items, product, options) {
    return items.findIndex((item) => {
        if (item.product.id !== product.id || item.options.length !== options.length) {
            return false;
        }

        for (let i = 0; i < options.length; i += 1) {
            const option = options[i];
            const itemOption = item.options.find((itemOption) => (
                itemOption.optionId === option.optionId && itemOption.valueId === option.valueId
            ));

            if (!itemOption) {
                return false;
            }
        }

        return true;
    });
}

function calcSubtotal(items) {
    return items && items.length ? items.reduce((subtotal, item) => subtotal + item.total, 0) : 0;
}

function calcQuantity(items) {
    return items && items.length ? items.reduce((quantity, item) => quantity + item.quantity, 0) : 0;
}

function calcTotal(subtotal, extraLines) {
    return subtotal + extraLines.reduce((total, extraLine) => total + extraLine.price, 0);
}

function addItem(state, product, options, quantity) {
 
    return {
        ...state
    };
}

function removeItem(state, itemId) {
    const { items } = state;
    const newItems = items.filter((item) => item.id !== itemId);

    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.extraLines);

    return {
        ...state,
        items: newItems,
        quantity: calcQuantity(newItems),
        subtotal,
        total,
    };
}

function updateQuantities(state, quantities) {
    let needUpdate = false;

    const newItems = state.items.map((item) => {
        const quantity = quantities.find((x) => x.itemId === item.id && x.value !== item.quantity);

        if (!quantity) {
            return item;
        }

        needUpdate = true;

        return {
            ...item,
            quantity: quantity.value,
            total: quantity.value * item.price,
        };
    });

    if (needUpdate) {
        const subtotal = calcSubtotal(newItems);
        const total = calcTotal(subtotal, state.extraLines);

        return {
            ...state,
            items: newItems,
            quantity: calcQuantity(newItems),
            subtotal,
            total,
        };
    }

    return state;
}

/*
* item example:
* {
*   id: 1,
*   product: {...}
*   options: [
*     {optionId: 1, optionTitle: 'Color', valueId: 1, valueTitle: 'Red'}
*   ],
*   price: 250,
*   quantity: 2,
*   total: 500
* }
* extraLine example:
* {
*   type: 'shipping',
*   title: 'Shipping',
*   price: 25
* }
*/
const initialState = {
    lastItemId: 0,
    quantity: 0,
    items: [],
    subtotal: 0,
    extraLines: [ // shipping, taxes, fees, .etc
       
    ],
    total: 0,
     cart: [],
    cartTotal:'',
    cartUSDTotal:'',
    cartSARTotal:'',
    cartSubTotal:'',
    cartUSDSubTotal:'',
    cartSARSubTotal:'',
    loading:false,
    cartMinimumValue : '',
    readyToOrder:'',
    
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
    case CART_ADD_ITEM:
        return addItem(state, action.product, action.options, action.quantity);

    case CART_REMOVE_ITEM:
        return removeItem(state, action.itemId);

    case CART_UPDATE_QUANTITIES:
        return updateQuantities(state, action.quantities);
     case CART_UPDATE_BEGIN:
                 return { ...state,
                    loading: true };
        case CART_UPDATE_END:
                 return { ...state,
                    loading: false };
        case RESET_CART : 
            localStorage.setItem('myvmarketcartcode','');
            return { ...state,
                items : [], quantity: 0, subtotal: 0, total: 0, cartTotal:'', cartSubTotal:'' };
        case LIST_CART :
             return { ...state,
                items: action.items ? action.items : [],  quantity: calcQuantity(action.items)  };
        case CART_MINIMUM_VALUE :
             return { ...state,
                cartMinimumValue: action.minValue };
        case  CART_INR_TOTAL:
             return { ...state,
                cartTotal: action.total,total: action.total };
        case  CART_INR_SUBTOTAL:
             return { ...state,
                cartSubTotal: action.subtotal,subtotal: action.subtotal };
        case  CART_USD_TOTAL:
             return { ...state,
                cartUSDTotal: action.total,total: action.total };
        case  CART_USD_SUBTOTAL:
             return { ...state,
                cartUSDSubTotal: action.subtotal,subtotal: action.subtotal };
        case  CART_SAR_TOTAL:
             return { ...state,
                cartSARTotal: action.total,total: action.total };
        case  CART_SAR_SUBTOTAL:
             return { ...state,
                cartSARSubTotal: action.subtotal,subtotal: action.subtotal };
        case READY_TO_ORDER:
             return { ...state,
                readyToOrder: action.readyToOrder };
        case UPDATE_TO_CART: 
           const items = state.items.reduce((cartAcc, item) => {
                if (item.id === action.item.id) {
                    cartAcc.push({ ...item, quantity: action.item.quantity,subTotal : action.item.subTotal }) // Increment qty
                } else {
                    cartAcc.push(item)
                }

                return cartAcc
            }, []);

            return { ...state, items ,  quantity: calcQuantity(items) }
        case ADD_TO_CART:
           return { ...state, items: [...state.items, { ...action.item }], quantity: calcQuantity([...state.items, { ...action.item }]) }
        case DECREMENT_QTY:
            
            if (state.items.findIndex(product => parseInt(product.id) === parseInt(action.productId)) !== -1) {
                const items = state.items.reduce((cartAcc, product) => {
                    if (parseInt(product.id) === parseInt(action.productId) && product.qty > 1) {
                        //// // console.log('price: '+product.price+'Qty: '+product.qty)
                        cartAcc.push({ ...product, qty: product.qty-1, sum: (product.price*product.discount/100)*(product.qty-1) }) // Decrement qty
                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return { ...state, items }
            }

            return { ...state, items: [...state.items, { ...action.product, qty: action.qty, sum: action.product.price*action.qty }], quantity: calcQuantity(state.items) }

        case REMOVE_FROM_CART:
            return { ...state, 
                items: state.items.filter(item => item.id !== action.product.id),
                 quantity: calcQuantity(state.items.filter(item => item.id !== action.product.id))
            }
    default:
        return state;
    }
}
