// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'

import { withRouter } from "react-router";
import {filterManufacturers } from '../../store/filters'

// application
import { ArrowRoundedLeft6x9Svg } from '../../svg';
import { Check9x7Svg } from '../../svg';

import classNames from 'classnames';

function FilterManufacturers(props) {
     const { location,values,filters,redirectFilters,filterManufacturers } = props;
    let clickManufacturerHandle;
    clickManufacturerHandle = (event, manufacturers,location) => {
        var clickedValue = encodeURIComponent(event.target.value);
        var index = manufacturers.indexOf(clickedValue);
        if (event.target.checked)
            manufacturers.push(clickedValue); // push in array checked value
        else
            manufacturers.splice(index, 1); // removed in array unchecked value
        var manufacturersFilterJoin = manufacturers.join('|');
        redirectFilters(manufacturersFilterJoin,'manufacturer',location);
        filterManufacturers(manufacturers);
    }

   const manufacturersList = values.map((item) => {
        let count;

        if (item.count) {
            count = <span className="filter-list__counter">{item.count}</span>;
        }
        // if (value.type === 'parent') {
        //     arrow = <ArrowRoundedLeft6x9Svg className="filter-categories__arrow" />;
        // }

        return (
           <label
                key={item.name}
                className={classNames('filter-list__item', {
                    'filter-list__item--disabled': item.disabled,
                })}
            >
                <span className="filter-list__input input-check">
                    <span className="input-check__body">
                        <input className="input-check__input" type="checkbox" onClick={(e) => clickManufacturerHandle(e,filters.manufacturers,location)}  value={item.name} defaultChecked={item.checked} disabled={item.disabled} />
                        <span className="input-check__box" />
                        <Check9x7Svg className="input-check__icon" />
                    </span>
                </span>
                <span className="filter-list__title">{item.name}</span>
                {count}
            </label>
        );
    });

    return (
        <div className="filter-categories">
            <ul className="filter-categories__list">
                {manufacturersList}
            </ul>
        </div>
    );
}

FilterManufacturers.propTypes = {
    values: PropTypes.array,
};


const mapStateToProps = (state) => {
// console.log(state);
    return {
        filters: state.filters
    }
}

export default connect(
    mapStateToProps,
    { filterManufacturers }
)(withRouter(FilterManufacturers));


