// react
import React, { Component } from 'react';

// application
import Pagination from '../shared/Pagination';
import Rating from '../shared/Rating';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';

import classNames from 'classnames';

// data stubs
import { getReviews, updateReview, addReview,reviewAvailable  } from '../../store/review';
import { accountDetail } from '../../store/user';


class ProductTabReviews extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reviews:[],
            currentTab: 'specification',
            customerId: '',
            date: '',
            comment: '',
            userName:'',
            fullName:'',
            emailAddress:'',
            productId: '',
            rating: 5,
            showReview:false
        };
        this.validator = new SimpleReactValidator();

        this.setStateFromInput = this.setStateFromInput.bind(this);
        this.checkReviewAvaiable = this.checkReviewAvaiable.bind(this);
    }

    componentWillMount(){
        this.props.getReviews(this.props.product.id,this.props.product.store).then(response => {
            console.log(response);
            this.setState(state => {
                console.log(response);
                state['reviews'] = response;
                return state;
             });
        })
        .catch(err => {

        });
        var productId = this.props.product.id;
        if(this.props.login_status == 'success'){
            let me = this;
            if(!this.props.account_detail){
                this.props.accountDetail()
                .then(response => {
                     var account = this.props.account_detail;
                     this.setState(state => {
                        state['productId'] = productId;
                        state['customerId'] = account.id;
                        state['fullName'] = account.custFirstName +' '+ account.custLastName;
                        state['emailAddress']= account.emailAddress;
                        return state;
                     });
                     me.checkReviewAvaiable(productId,account.id);
                })
                .catch(err => {

                });
            }
            else
            {
                 var account = this.props.account_detail;
                 this.setState(state => {
                    state['productId'] = productId;
                    state['customerId'] = account.id;
                    state['fullName'] = account.custFirstName +' '+ account.custLastName;
                    state['emailAddress']= account.emailAddress;
                    return state;
                 });
                 me.checkReviewAvaiable(productId,account.id);
            }
            
            
        }
    }

    checkReviewAvaiable = (productId,accountId) => {
        console.log(productId,accountId);
        this.props.reviewAvailable(productId,accountId).then(boolean => {
             this.setState(state => {
                state['showReview'] = !boolean;
                return state;
             });
        })
        .catch(err => {
            this.setState(state => {
                state['showReview'] = false;
                return state;
             });
        });
    }

    setStateFromInput = (event) => {
        var obj = {};
        if(event.target.dataset.object){
             var key = event.target.dataset.object;
             var name = event.target.name;
             var value =  event.target.value;
                this.setState(state => {
                      state[key][name] = value;
                      return state;
                })
           }
           else{
               obj[event.target.name] = event.target.value;
               this.setState(obj);
        }
    }

     handleSubmit = (event) => {
        event.preventDefault(); 
        if (this.validator.allValid()) {
            this.props.addReview({customerId: this.state.customerId,
                    date: new Date(),comment: this.state.comment, productId: this.state.productId,rating: this.state.rating})
            .then(response => {
                this.setState(state => {
                console.log(response,this.props.reviews);
                state['reviews'] = this.props.reviews.filter((review)=> {
                    return review.productId == state.productId;
                });
                return state;
             });
            })
              .catch(err => {
                toast.error("Unable to post a review");
              });
        } 
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }
     }


    render(){

    const {updating, login_status,reviews  } = this.props;
    const reviewsList = this.state.reviews.map((review, index) => (
        <li key={index} className="reviews-list__item">
            <div className="review">
                
                <div className=" review__content">
                    <div className=" review__author">{review.name}</div>
                    <div className=" review__rating">
                        <Rating value={review.rating} />
                    </div>
                    <div className=" review__text">{review.comment}</div>
                    <div className=" review__date">{review.date}</div>
                </div>
            </div>
        </li>
    ));

    return (
        <div className="reviews-view">
            <div className="reviews-view__list">
                <h3 className="reviews-view__header">Customer Reviews</h3>
                 <div className="reviews-list">
                    <ol className="reviews-list__content">
                        {reviewsList}
                    </ol>
                </div>
               
            </div>
            {login_status == 'success' && this.state.showReview ? 
            <form className="reviews-view__form" onSubmit={this.handleSubmit.bind(this)} noValidate>
                <h3 className="reviews-view__header">Write A Review</h3>
                <div className="row">
                    <div className="col-12 col-lg-12 col-xl-12">
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="review-stars">Review Stars</label>
                                <select id="review-stars" className="form-control" name="rating" value={this.state.rating} 
                                onChange={this.setStateFromInput}>
                                    <option value="5" defaultValue>5 Stars Rating</option>
                                    <option value="4">4 Stars Rating</option>
                                    <option value="3">3 Stars Rating</option>
                                    <option value="2">2 Stars Rating</option>
                                    <option value="1">1 Star Rating</option>
                                </select>
                                {this.validator.message('rating', this.state.rating, 'required')}
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="review-author">Your Name</label>
                                <input 
                                    id="review-author"
                                    type="text"
                                    name="fullName"
                                    className="form-control"
                                    placeholder=""
                                    readOnly={true}
                                    value={this.state.fullName} onChange={this.setStateFromInput} />
                                        {this.validator.message('fullName', this.state.fullName, 'required')}
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="review-email">Email Address</label>
                                <input 
                                type="text" 
                                className="form-control" 
                                id="review-email" 
                                placeholder="Email Address"
                                name="emailAddress"
                                placeholder=""
                                readOnly={true}
                                value={this.state.emailAddress} onChange={this.setStateFromInput} />
                                    {this.validator.message('emailAddress', this.state.emailAddress, 'required')}
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="review-text">Your Review</label>
                            <textarea className="form-control"
                             id="review-text"
                             rows="6" 
                             className="form-control"
                             name="comment" 
                             value={this.state.comment} onChange={this.setStateFromInput} />
                                                    {this.validator.message('comment', this.state.comment, 'required')}
                        </div>
                        <div className="form-group mb-0">
                        <button type="submit" className={classNames('btn btn-primary btn-lg', {
                            'btn-loading': updating})}>
                            Post Your Review
                        </button>
                        </div>
                    </div>
                </div>
            </form>
            :'' }
        </div>
    );
}
}

const mapStateToProps = (state) => {

    return { 
        currentState : state,
        updating:state.review.updating,
        login_status : state.user.login_status,
        account_detail : state.user.account_detail,
        reviews:state.review.reviews
        };
};


const mapDispatchToProps =  {
    getReviews,
    updateReview,
    addReview,
    accountDetail,
    reviewAvailable

};

export default connect(
   mapStateToProps,
    mapDispatchToProps,
)(ProductTabReviews);
