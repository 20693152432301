import * as types from './userActionTypes'
import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import queryString from 'query-string'
const userdata = localStorage.getItem('gouserdata') ? JSON.parse(localStorage.getItem('gouserdata')) : {};

var axios_instance = '';

if(userdata.token)
  axios_instance = axios.create({headers: {authToken: userdata.token}});
else 
  axios_instance = axios.create();


export const registerBegin = () => ({
    type: types.REGISTER_BEGIN,
});

export const registerEnd = () => ({
    type: types.REGISTER_END,
});

export const registerDone = (log) => ({
    type: types.REGISTER_DONE,
    log
});

export const registerStatus = (log) => ({
    type: types.REGISTER_STATUS,
    log
});


export const loginBegin = () => ({
    type: types.LOGIN_BEGIN,
});

export const loginEnd = () => ({
    type: types.LOGIN_END,
});

export const loginDone = (log) => ({
    type: types.LOGIN_DONE,
    log
});

export const commitOrderBegin = () => ({
    type: types.COMMIT_ORDER_BEGIN,
});

export const commitOrderEnd = () => ({
    type: types.COMMIT_ORDER_END,
});


export const loginStatus = (log) => ({
    type: types.LOGIN_STATUS,
    log
});


export const loginData = (data) => ({
    type: types.REGISTER_USER,
    data
});
export const registerData = (data) => ({
    type: types.LOGIN_USER,
    data
});

export const logoutuser = () => ({
    type: types.LOGOUT_USER
});

export const updateStart =() => ({
    type: types.UPDATE_START
});

export const updateEnd =() => ({
    type: types.UPDATE_END
});

export const fetchAccountDetail= (account_detail) => ({
    type: types.ACCOUNT_DETAIL,
    account_detail
});


export const updateCustomerBeforeCommitOrder= (data) => { 
  return  (dispatch) => {
      dispatch(updateStart());
      return new Promise((resolve, reject) => {
      axios_instance.put(process.env.PUBLIC_ENDPOINT + "/customerupdate?lang=en&store=DEFAULT",data ).then(response => response.data)
      .then((data) => {
          if(data){
          toast.success("Update done.");
          dispatch(fetchAccountDetail(data));
            dispatch(updateEnd());
          return resolve(data);
        }
        else
          return reject(data);
      })
      .catch(function (error) {
        return reject(error ? error.response.data : []);
      });  
      });
    }
  };

export const commitOrder = () => { 
  return  (dispatch) => {
    dispatch(commitOrderBegin());

    var cartCode = localStorage.getItem('myvmarketcartcode');
  return new Promise((resolve, reject) => {
     axios_instance.post(process.env.PUBLIC_ENDPOINT + "/order/commitOrder?cartCode="+cartCode+"&lang=en&store=DEFAULT" ).then(response => response.data)
      .then((data) => {
        dispatch(commitOrderEnd());
        console.log(data);
        if(data){
        
         return resolve(data);
        }
        else
          return reject(data);
    })
    .catch(function (error) {
     // toast.success("Login Failed. Please try again");
      return reject(error ? error : []);
    });
  });
};
}





export const getPrices = () => {
  return  (dispatch) => {
    return new Promise((resolve, reject) => {
     axios_instance.get(process.env.PUBLIC_ENDPOINT + "/order/prices?store=DEFAULT&lang=en&cartcode="+localStorage.getItem('myvmarketcartcode'))
     .then(response => response.data)
      .then((data) => {
        console.log(data);
        return resolve(data);
     })
      .catch(function (error) {
        return reject('failed');
      });
    });
  };
}


export const accountDetail = () => {
  return  (dispatch) => {
    dispatch(updateStart());
    return new Promise((resolve, reject) => {
     axios_instance.get(process.env.PUBLIC_ENDPOINT + "/customerdetail/accountSummary?store=DEFAULT&lang=en")
     .then(response => response.data)
      .then((data) => {
        dispatch(fetchAccountDetail(data));
        dispatch(updateEnd());
        return resolve('success');
     })
      .catch(function (error) {
        dispatch(updateEnd());
        return reject('failed');
      });
    });
  };
}

export const resetPassword = (userName) => { 
  return  (dispatch) => {
    dispatch(loginBegin());
    return new Promise((resolve, reject) => {
       axios_instance.post(process.env.PUBLIC_ENDPOINT + "/customer/password/reset?lang=en&store=DEFAULT",{ username : userName } ).then(response => response.data)
        .then((data) => {
           dispatch(loginEnd());
           return resolve('success');
      })
      .catch(function (error) {
        dispatch(loginEnd());
        return reject('failed');
      });
    });
  };
}

export const loginCustomer = (data) => { 
  return  (dispatch) => {
    dispatch(loginBegin());
    var cartCode = localStorage.getItem('myvmarketcartcode');
    cartCode = cartCode ? cartCode : '';
    return new Promise((resolve, reject) => {
       axios_instance.post(process.env.PUBLIC_ENDPOINT + "/customer/logon?lang=en&cartCode="+cartCode,data ).then(response => response.data)
        .then((data) => {
           axios_instance.defaults.headers.common['authToken'] = data.token;
           localStorage.setItem('gouserdata',JSON.stringify(data));
           if(data.cartCode)
             localStorage.setItem('myvmarketcartcode',data.cartCode);
           dispatch(accountDetail());
           dispatch(loginData(data));
           dispatch(loginEnd());
           dispatch(loginStatus('success'));
          // toast.success("Login Success.");
           return resolve('success');
      })
      .catch(function (error) {
        dispatch(loginEnd());
        dispatch(loginStatus('init'));
       // toast.success("Login Failed. Please try again");
        return reject('failed');
      });
    });
  };
}

export const logout = () => (dispatch) => {
  axios_instance = axios.create();
  dispatch(logoutuser());
  dispatch(loginStatus('init'));
}

export const uploadFile = (file,type,id) => {
        const formData = new FormData();
        formData.append('file',file);
        axios.post(process.env.PUBLIC_ENDPOINT +'/saveCustomerdigital/'+id+'?lang=en&type='+type,
               formData,{ headers: {'content-type': 'multipart/form-data'}}) .then((data) => {
        }).catch((error) => {
        });
      }

export const  changePassword = (data) => { 
  return  (dispatch) => {
  dispatch(updateStart());
  return new Promise((resolve, reject) => {
  axios_instance.post(process.env.PUBLIC_ENDPOINT + "/customerdetail/changePassword?lang=en&store=DEFAULT",data ).then(response => response.data)
  .then((data) => {
    dispatch(updateEnd());
      return resolve('success');
 
  })
  .catch(function (error) {
    dispatch(updateEnd());
      return reject('failed');
  });
});
}
}


export const updateCustomer = (data) => (dispatch) => {
  dispatch(updateStart());
   axios_instance.put(process.env.PUBLIC_ENDPOINT + "/customerupdate?lang=en&store=DEFAULT",data ).then(response => response.data)
    .then((data) => {
      toast.success("Update done.");
      dispatch(fetchAccountDetail(data));
      dispatch(updateEnd());
  })
  .catch(function (error) {
    toast.success("Failed to update.");
    dispatch(updateEnd());
    // console.log(error);
  });  
}

export const registerCustomer = (data) => { 
  return  (dispatch) => {
    var vat_certificate = data.vat_certificate;
    var cr_certificate = data.cr_certificate;
    dispatch(registerBegin());

    return new Promise((resolve, reject) => {
     axios_instance.post(process.env.PUBLIC_ENDPOINT + "/customer/register?lang=en&store=DEFAULT",data ).then(response => response.data)
      .then((data) => {
        dispatch(registerData(data));
         localStorage.setItem('gouserdata',JSON.stringify(data));
         dispatch(registerEnd());
         dispatch(registerStatus('success'));
         if(vat_certificate)
           uploadFile(vat_certificate,'vatdocument',data.id);
         if(cr_certificate)
         uploadFile(cr_certificate,'crdocument',data.id);
         return resolve(data);

    })
    .catch(function (error) {
       dispatch(registerEnd());
       console.log(error);
       return reject(error ? error.response.data : {});
    });
  });
};
};
