// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// application
import Footer from './footer';
import Header from './header';
import MobileHeader from './mobile/MobileHeader';
import SearchHeader from './mobile/SearchHeader';
import MobileMenu from './mobile/MobileMenu';
import CategoryMenu from './mobile/CategoryMenu';
import Quickview from './shared/Quickview';


// data stubs
import theme from '../data/theme';

// pages
import AccountLayout from './account/AccountLayout';
import AccountPageLogin from './account/AccountPageLogin';
import AccountPageRegister from './account/AccountPageRegister';
import BlogPageCategory from './blog/BlogPageCategory';
import SitePageAboutUs from './site/SitePageAboutUs';
import PageCart from './shop/ShopPageCart';
import OrderSuccess from './shared/OrderSuccess';
import PageCheckout from './shop/ShopPageCheckout';
import PageCompare from './shop/ShopPageCompare';
import CompareProducts from './shop/CompareProducts';
import PostRequirements from './site/SitePostRequirements';
import BecomeSeller from './site/SiteBecomeSeller';
import OurNeeds from './site/SiteOurNeeds';
import LotSales from './site/SiteLotSales';
import SiteLotCategories from './site/SiteLotCategories';

import SitePageContactUs from './site/SitePageContactUs';
import SiteVisionMission from './site/SiteVisionMission';
import SitePrivacyPolicy from './site/SitePrivacyPolicy';
import SiteDisclaimer from './site/SiteDisclaimer';
import SitePageFaq from './site/SitePageFaq';
import SitePageNotFound from './site/SitePageNotFound';
import BlogPagePost1 from './blog/BlogPagePost1';
import BlogPagePost2 from './blog/BlogPagePost2';
import BlogPagePost3 from './blog/BlogPagePost3';

import ShopPageProduct from './shop/ShopPageProduct';
import ShowProductPage from './shop/ShowProductPage';
import SitePageTerms from './site/SitePageTerms';
import ShopPageTrackOrder from './shop/ShopPageTrackOrder';
import SitePageTypography from './site/SitePageTypography';
import PageWishlist from './shop/ShopPageWishlist';
import PageSavelist from './shop/ShopPageSavelist';

import ShopPageCategory from './shop/ShopPageCategory';
import ClassifiedProducts from './shop/ClassifiedProducts';
 import { Container, Button, Link } from 'react-floating-action-button'


function Layout(props) {
    const { match, headerLayout, homeComponent } = props;

    return (
        <React.Fragment>
            <Helmet>
                <title>{theme.name}</title>
                <meta name="description" content={theme.fullName} />
                <meta name="google-site-verification" content="BXC4gma-aLO9yrJhZjyEEBTyxdOk2SFw4qj38-MrIhQ" />
            </Helmet>

            <ToastContainer autoClose={5000} hideProgressBar />

            <Quickview />

            <MobileMenu />
            <CategoryMenu />
            <Container className="fab-container-c">
          
            <Button
                icon="fab fa-whatsapp"
                rotate={true}>
                <a href={'https://api.whatsapp.com/send?phone=918939990568&amp;text=Hi, I would like to know the information about your services.'} 
                 target="_blank"></a>
            </Button>
        </Container>
            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader />
                    <SearchHeader />
                </header>

                <header className="site__header d-lg-block d-none">
                    <Header layout={headerLayout} />
                </header>

                <div className="site__body">
                  <Switch>
                        <Route exact path={`${match.path}`} component={homeComponent} />
           
                        <Redirect exact from="/shop" to="/" />
                          <Route
                             path={`${process.env.PUBLIC_URL}/products`}
                            render={(props) => (
                                <ClassifiedProducts {...props} columns={3} viewMode="list" sidebarPosition="start" />
                            )}
                        />
                         <Route
                            path="/products"
                            render={(props) => (
                                <ClassifiedProducts {...props} columns={3} viewMode="list" sidebarPosition="start" />
                            )}
                        />
                        <Route
                            path="/industry/:industryname"
                            render={(props) => (
                                <ClassifiedProducts {...props} columns={3} viewMode="list" sidebarPosition="start" />
                            )}
                        />
                        <Route
                            path="/lotitems/:lotmainid/:lsid/:lotmainname/:lsName"
                            render={(props) => (
                                <ClassifiedProducts {...props} columns={3} viewMode="list" sidebarPosition="start" />
                            )}
                        />
                        <Route path="/product/:productId/:productname" component={ShowProductPage} />
                        <Route exact path="/cart" component={PageCart} />
                        <Route exact path="/checkout" component={PageCheckout} />
                        <Route exact path="/order-success" component={OrderSuccess} />
                        <Route exact path="/wishlist" component={PageWishlist} />
                        <Route exact path="/shop/compare" component={PageCompare} />
                        <Route exact path="/compare" component={CompareProducts} />
                        <Route exact path="/savedlist" component={PageSavelist} />
                         <Route
                            exact
                            path="/blog/post-classic/1"
                            render={(props) => (
                                <BlogPagePost1 {...props} layout="classic" sidebarPosition="end" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/post-classic/2"
                            render={(props) => (
                                <BlogPagePost2 {...props} layout="classic" sidebarPosition="end" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/post-classic/3"
                            render={(props) => (
                                <BlogPagePost3 {...props} layout="classic" sidebarPosition="end" />
                            )}
                        />
                        <Route exact path="/shop/track-order" component={ShopPageTrackOrder} />

                        <Route exact path="/account/login" component={AccountPageLogin} />
                        <Route exact path="/account/register" component={AccountPageRegister} />

                        <Route path="/account" component={AccountLayout} />

                        <Redirect exact from="/site" to="/about-us" />
                        <Route exact path="/about-us" component={SitePageAboutUs} />
                        <Route exact path="/vision-mission" component={SiteVisionMission} />
                        <Route exact path="/privacy-policy" component={SitePrivacyPolicy} />
                        <Route exact path="/disclaimer" component={SiteDisclaimer} />
                        <Route exact path="/contact-us" component={SitePageContactUs} />
                        <Route exact path="/faq" component={SitePageFaq} />
                        <Route exact path="/terms-and-conditions" component={SitePageTerms} />
                        <Route exact path="/post-your-request" component={PostRequirements} />
                        <Route exact path="/become-a-seller" component={BecomeSeller} />
                        <Route exact path="/our-needs" component={OurNeeds} />
                        <Route exact path="/lot/:lotid/:lotname" component={LotSales} />
                        <Route exact path="/lotsales" component={SiteLotCategories} />
                          
                        <Route component={SitePageNotFound} />
                    </Switch>
                </div>

                <footer className="site__footer">
                    <Footer />
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(['default', 'compact']),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: 'default',
};

export default Layout;
