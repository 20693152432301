// react
import React, {Component} from 'react';

// third-party
import { Link } from 'react-router-dom';
import Img from 'react-image';

// application
import Megamenu from './Megamenu';
import Menu from './Menu';
import { ArrowRoundedRight6x9Svg } from '../../svg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
// data stubs
import departments from '../../data/headerDepartments';


class CategoriesLinks extends Component {
        constructor(props){
        super(props);
        this.state = {
            subMenuSize:'xl',
            options:{ 
              suppressScrollX : false,
              suppressScrollY : false
          }
        }

    }

    componentDidMount(){
       
    }

    

    render(){
      const setHeight = {
        height: this.props.megaMenuHeight
    };
    return (
        <PerfectScrollbar options={this.state.options}>
        <ul className="departments__links" ref="scrollWrapper">
           { this.props.categories.map((category, index) => 
                        <li key={index} className="departments__item">
                            <Link to={`${process.env.PUBLIC_URL}${category.lineage2}${category.value2}`} category={category}>
                                {category.value}
                                <Img className="departments__link-icon" src={`${process.env.PUBLIC_URL}/images/categories-thumb/${category.value.replace(/[^A-Z0-9]+/ig, "").toLowerCase()}.png`}
                                        unloader={ (<img className="departments__link-icon"
                                    src={`${process.env.PUBLIC_URL}/images/categories-thumb/default.png`}/>)}/>
                                
                                { category.children && category.children.length ?
                                    <ArrowRoundedRight6x9Svg className="departments__link-arrow" />
                                : ''}
                            </Link>
                            { category.children && category.children.length ? 
                                 <div className={`departments__megamenu departments__megamenu--${this.state.subMenuSize}`} style={setHeight}>
                                <Megamenu menu={category} image={category.imageUrl} size="4" location="department" />
                            </div>
                            : ''}
                        </li>
           )}
        </ul>
        </PerfectScrollbar>
    );
}

}


export default CategoriesLinks;
