// react
import React, {Component}  from 'react';

// third-party
import { Helmet } from 'react-helmet';

// application
import PageHeader from '../shared/PageHeader';
import ReactGA from 'react-ga';

// blocks
import BlockMap from '../blocks/BlockMap';

// data stubs
import theme from '../../data/theme';

import { trackPage } from '../../services';

import { submitContactUsRequest } from '../../store/forms';
import SimpleReactValidator from 'simple-react-validator';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import classNames from 'classnames';


class SitePageContactUs extends Component {

     constructor(props) {
    super(props);
    this.state = {
          "comment": "",
          "emailId": "",
          "name": "",
          "subject": ""
    };
    this.validator = new SimpleReactValidator();
    this.setStateFromInput = this.setStateFromInput.bind(this);
  }

  componentWillMount(){
      this.props.trackPage(this.props.location.pathname + this.props.location.search);
    }

  setStateFromInput = (event) => {
        var obj = {};
        if(event.target.dataset.object){
             var key = event.target.dataset.object;
             var name = event.target.name;
             var value =  event.target.value;
                this.setState(state => {
                      state[key][name] = value;
                      return state;
                })
           }
           else{
               obj[event.target.name] = event.target.value;
               this.setState(obj);
            }
    }

    handleSubmit = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'Requests',
      action: 'Contact Enquiry',
      label: 'Form Submit'
    });
    if (this.validator.allValid()) {
             this.props.dispatch(submitContactUsRequest(this.state))
              .then(response => {

                })
                  .catch(err => {
                    console.log(err); 
                  });
       
    } else {
              this.validator.showMessages();
              this.forceUpdate();
    }
  }

    
    render() {
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Contact Us', url: '' },
    ];

    const { loading } = this.props;


    return (
        <React.Fragment>
            <Helmet>
                <title>{`Contact Goospares | Online Marketplace for MRO Supply and Buy`}</title>
                <meta name="Description" content={`Looking to buy or sell MRO Spares -  Unused, Slow-moving and  Non-moving industrial spares? Contact us @ +91-8939990568`}/>
            </Helmet>

           

            <PageHeader header="Contact Us" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="card mb-0">
                        <div className="card-body contact-us">
                            <div className="contact-us__container">
                                <div className="row">
                                    <div className="col-12 col-lg-5 pb-4 pb-lg-0">
                                        <h4 className="contact-us__header card-title">Our Address</h4>

                                        <div className="contact-us__address">
                                             <h6>Head Office :</h6>
                                             <p>
                                                Module No.F12 Agaram Unit, No 27 & 28 Phase-I SDF-I, MEPZ SEZ Tambaram, Chennai-600045, Tamil Nadu, India. 
                                                <br />
                                                Email: info@goospares.com
                                                <br />
                                                T: +91-44-4555 2411 M: +91 89399 90568
                                            </p>
                                            <h6>Branch Office :</h6>
                                            <p>
                                                <strong>CODA Technologies PTE. LTD</strong>
                                                <br />
                                                1 North Bridge Road, #30-39 High Street Centre, Singapore - 179094
                                                <br />
                                                Email: info@goospares.com
                                                <br />
                                                M: +65 9863 3925
                                            </p>

                                            
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-7">
                                        <h4 className="contact-us__header card-title">Leave us a Message</h4>

                                        <form onSubmit={this.handleSubmit.bind(this)} noValidate>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-name">Your Name</label>
                                                   <input
                                                id="name"
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                placeholder="Name"
                                                value={this.state.name} onChange={this.setStateFromInput} />
                                                    {this.validator.message('name', this.state.name, 'required')}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-email">Email</label>
                                                    <input
                                                id="emailId"
                                                type="email"
                                                name="emailId"
                                                className="form-control"
                                                placeholder="Email"
                                                value={this.state.emailId} onChange={this.setStateFromInput} />
                                                    {this.validator.message('emailId', this.state.emailId, 'required|email')}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-subject">Subject</label>
                                                <input
                                                id="subject"
                                                type="text"
                                                name="subject"
                                                className="form-control"
                                                placeholder="Subject"
                                                value={this.state.subject} onChange={this.setStateFromInput} />
                                                    {this.validator.message('subject', this.state.subject, 'required')}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-message">What’s on your mind?</label>
                                               <textarea name="comment" rows="5" placeholder="Comments" 
                                                className="form-control"  value={this.state.comment} onChange={this.setStateFromInput}>
                                                </textarea>
                                                    {this.validator.message('comment', this.state.comment, 'required')}
                                            </div>
                                            <button disabled={this.props.loading} type="submit" className={classNames('btn btn-primary', {
                                            'btn-loading': this.props.loading,
                                        })}>Send Message {this.props.loading}</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <BlockMap />
        </React.Fragment>
    );
}
}



function mapStateToProps(state) {
    return {
        loading   : state.forms.cloading,
        trackPage : trackPage
    }
}

export default connect(mapStateToProps)(withRouter(SitePageContactUs));