// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// application
import { ArrowRoundedLeft6x9Svg } from '../../svg';
import { Check9x7Svg } from '../../svg';
import { connect } from 'react-redux'

import { withRouter } from "react-router";
import { filterAttrs } from '../../store/filters'

 import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

import 'react-perfect-scrollbar/dist/css/styles.css';

 
function FilterAttributes(props) {
      const { location,values,filters,redirectFilters,filterAttrs } = props;

      let clickAttrHandle;
    clickAttrHandle = (event, attrs,location) => {
        var clickedValue = encodeURIComponent(event.target.value);
        var index = attrs.indexOf(clickedValue);
        if (event.target.checked)
            attrs.push(clickedValue); // push in array checked value
        else
            attrs.splice(index, 1); // removed in array unchecked value
        var attrFilterJoin = attrs.join('|');
        redirectFilters(attrFilterJoin,'attrsValues',location);
        filterAttrs(attrs);
    }

    let options = { 
          suppressScrollX : false,
          suppressScrollY : false
      };
   const attributesList = values.map((item) => {
        let count;
        let subAttributes;

        if (item.count) {
            count = <span className="filter-list__counter">{item.count}</span>;
        }
        // if (value.type === 'parent') {
        //     arrow = <ArrowRoundedLeft6x9Svg className="filter-categories__arrow" />;
        // }
        if(item.subAttributes && item.subAttributes.length){
            subAttributes = item.subAttributes.map((item) => {
                 return (
           <label
                key={item.name}
                className={classNames('filter-list__item', {
                    'filter-list__item--disabled': item.disabled,
                })}
            >
                <span className="filter-list__input input-check">
                    <span className="input-check__body">
                        <input className="input-check__input" type="checkbox" onClick={(e) => clickAttrHandle(e,filters.attrs,location)}  value={item.name} defaultChecked={item.checked} disabled={item.disabled} />
                        <span className="input-check__box" />
                        <Check9x7Svg className="input-check__icon" />
                    </span>
                </span>
                <span className="filter-list__title">{item.name}</span>
                {count}
            </label>
        );
            });
        }

          return (
            <li key={item.name} className={`filter-categories__item filter-categories__item--${item.type}`}>
                <div className="filter-attr__name"><Link to="/">{item.name}</Link>
                <div className="filter-categories__counter">{item.count}</div>
                </div>
                  <PerfectScrollbar options={options}>
                <div className="filter-list__list">
                {subAttributes}
                </div>
                </PerfectScrollbar>
            </li>
        );
       
    });

    return (
        <div className="filter-categories">
            <ul className="filter-categories__list">
                {attributesList}
            </ul>
        </div>
    );
}

FilterAttributes.propTypes = {
    values: PropTypes.array,
};


const mapStateToProps = (state) => {
// console.log(state);
    return {
        filters: state.filters
    }
}

export default connect(
    mapStateToProps,
    { filterAttrs }
)(withRouter(FilterAttributes));



