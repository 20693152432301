// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import Collapse from '../shared/Collapse';
import FilterCategoriesOptions from '../filters/FilterCategoriesOptions';
import FilterManufacturers from '../filters/FilterManufacturers';
import FilterAttributes from '../filters/FilterAttributes';
import { ArrowRoundedDown12x7Svg } from '../../svg';


function ProductsFilters(props) {
    const { title, filters, offcanvas,redirectFilters } = props;

    const filtersList = filters.map((filter) => {
        let filterView;

        if (filter.type === 'categories') {
            filterView = <FilterCategoriesOptions values={filter.options.items} redirectFilters= {redirectFilters} />;
        }
        else if (filter.type === 'manufacturers') {
            filterView = <FilterManufacturers values={filter.options.items} redirectFilters= {redirectFilters}/>;
        } 
        else if (filter.type === 'attributes') {
            filterView = <FilterAttributes values={filter.options.items} redirectFilters= {redirectFilters}/>;
        } 

        return (
            <div key={filter.id} className="widget-filters__item">
                <Collapse
                    toggleClass="filter--opened"
                    render={({ toggle, setItemRef, setContentRef }) => (
                        <div className="filter filter--opened" ref={setItemRef}>
                            <button type="button" className="filter__title" onClick={toggle}>
                                {filter.name}
                                <ArrowRoundedDown12x7Svg className="filter__arrow" />
                            </button>
                            <div className="filter__body" ref={setContentRef}>
                                <div className="filter__container">
                                    {filterView}
                                </div>
                            </div>
                        </div>
                    )}
                />
            </div>
        );
    });

    const classes = classNames('widget-filters widget', {
        'widget-filters--offcanvas--always': offcanvas === 'always',
        'widget-filters--offcanvas--mobile': offcanvas === 'mobile',
    });

    return (
        <div className={classes}>
            <h4 className="widget-filters__title widget__title">{title}</h4>

            <div className="widget-filters__list">
                {filtersList}
            </div>

           
        </div>
    );
}

ProductsFilters.propTypes = {
    /**
     * widget title
     */
    title: PropTypes.node,
    /**
     * array of filters
     */
    filters: PropTypes.array,
    /**
     * indicates when sidebar bar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
};

ProductsFilters.defaultProps = {
    filters: [],
    offcanvas: 'mobile',
};

export default ProductsFilters;
