import { toast } from 'react-toastify';
import * as types from "./productsActionTypes";
import axios from 'axios';
import queryString from 'query-string';

const userdata = localStorage.getItem('gouserdata') ? JSON.parse(localStorage.getItem('gouserdata')) : {};

var axios_instance = '';

if(userdata.token)
  axios_instance = axios.create({headers: {authToken: userdata.token}});
else 
  axios_instance = axios.create();

export const fetchProductsBegin = () => ({
    type: types.FETCH_PRODUCTS_BEGIN
});

export const fetchProductsDone = () => ({
    type: types.FETCH_PRODUCTS_DONE
});

export const receiveProducts = products => ({
    type: types.RECEIVE_PRODUCTS,
    products
})

export const receiveKeyBasedProducts = (products) => ({
    type: types.RECEIVE_KEY_BASED_PRODUCTS,
    products
})

export const clearProducts = () => ({
    type: types.CLEAR_PRODUCTS
})

export const productTotal = (total) => ({
    type: types.TOTAL_PRODUCTS_COUNT,
    total
});

export const loadedProductCount = (count) => ({
    type: types.LOADED_PRODUCTS_COUNT,
    count
});

export const currentInfiniteLimit= (count) => ({
    type: types.INFINITE_LIMIT,
    count
});

export const startCount = (count) => ({
    type: types.START_PRODUCTS_COUNT,
    count
});

export const endCount = (count) => ({
    type: types.END_PRODUCTS_COUNT,
    count
});

export const receiveProductFilters = filters => ({
    type: types.RECEIVE_PRODUCTS_FILTERS,
    filters
});

export const resetFilters = () => ({
    type: types.RESET_FILTERS
});

export const resetProductFilters = filters => ({
    type: types.RESET_PRODUCTS_FILTERS,
    filters
});

export const setSearchQuery = searchQuery => ({
    type: types.SET_SEARCH_QUERY,
    searchQuery
});

export const fetchSingleProductBegin = () => ({
    type: types.FETCH_SINGLE_PRODUCT_BEGIN
});

export const fetchSingleProductDone = () => ({
    type: types.FETCH_SINGLE_PRODUCT_DONE
});

export const fetchSingleProduct = product => ({
    type: types.FETCH_SINGLE_PRODUCT,
    product
});

export const resumeFilters = (manufacturer,categories,attrs) => ({
    type: types.RESUME_FILTERS,
    manufacturer,
    categories,
    attrs
});


export const getSingleProduct = (productid) => dispatch => {
    dispatch(fetchSingleProductBegin());
     axios_instance.post(process.env.PUBLIC_ENDPOINT + "/search?lang=en&store=DEFAULT", {
              "count": 50,
              "query": productid.toString(),
              "searchBy": 'productId',
              "start": 0
          }).then(response => response.data)
    .then((data) => {
    // console.log(data);
           dispatch(fetchSingleProductDone());
        dispatch(fetchSingleProduct(data.products[0]));
        return data.products;
  })
  .catch(function (error) {
    // console.log(error);
  });
}



export const getSearchBasedProducts = (query,filter,count, category_lineage) => { 
  console.log(query,filter,count);
  return  (dispatch) => {
    dispatch(fetchProductsBegin());
    var queryValue = '';
    var type = '';
    var lineage = '';
    var requestObj = {
        "start": count.start,
        "count": count.limit
    };
     var filters = filter ? (queryString.parse(filter).filter ? queryString.parse(filter).filter : '' ) :
                          (queryString.parse(query).filter ? queryString.parse(query).filter : '');
    if(filters){
      var splitSearch = filters.split('::');
      splitSearch.map((val,index) => {
        var key = val.split(':')[0];
        var keyVal =  val.split(':')[1] ? val.split(':')[1].split('|') : '';
        if(keyVal)
          requestObj[key] = keyVal;
      });
    }
    console.log(queryString.parse(query));
    if(queryString.parse(query)){
      if(queryString.parse(query).query){
       type = 'autosuggest';
       queryValue =  decodeURIComponent(queryString.parse(query).query);
      }
       else if(queryString.parse(query).category){
        type = 'category';
       queryValue = decodeURIComponent(queryString.parse(query).category);
       lineage = category_lineage ? category_lineage : ''
      }
      else if(queryString.parse(query).manufacturer){
        type = 'manufacturer';
       queryValue =  decodeURIComponent(queryString.parse(query).manufacturer);
      }
      else if(queryString.parse(query).brand){
        type = 'brand';
       queryValue =  decodeURIComponent(queryString.parse(query).brand);
      }
      else if(queryString.parse(query).industry){
        type = 'industry';
       queryValue =  decodeURIComponent(queryString.parse(query).industry);
      }
      else if(queryString.parse(query).lot){
        type = 'lotsale';
       queryValue =  decodeURIComponent(queryString.parse(query).lot);
      }
    }
    else
       queryValue = query.value;
     requestObj.query = queryValue;
     requestObj.searchBy = type;
     requestObj.lineage = lineage;
     
    return new Promise((resolve, reject) => {
    axios_instance.post(process.env.PUBLIC_ENDPOINT + "/search?lang=en&store=DEFAULT", requestObj).then(response => response.data)
    .then((data) => {
            dispatch(fetchProductsDone());
            dispatch(loadedProductCount(data.products.length));
            dispatch(productTotal(data.totalProductCount));
            dispatch(receiveProductFilters(data.productFilters));
            dispatch(receiveProducts(data.products));
            dispatch(startCount(count.start));

            dispatch(endCount(count.end > data.products.length ? count.end : (count.start + data.products.length)));
            return resolve(data.products);
    })
    .catch(function (error) {
      dispatch(fetchProductsDone());
      return reject('failed');
      // console.log(error);
    }); 
  });
};
}





export const getProductsKeyBased = (searchBy,keyparam) => { 
  return  (dispatch) => {
    dispatch(fetchProductsBegin());
      return new Promise((resolve, reject) => {
        axios_instance.post(process.env.PUBLIC_ENDPOINT + "/search?lang=en&store=DEFAULT", {
                  "count": 15,
                  "query": keyparam,
                  "searchBy": searchBy,
                  "start": 0
              }).then(response => response.data)
        .then((data) => {
                dispatch(fetchProductsDone());
                let products = {};
                products[keyparam] = data.products;
                dispatch(receiveKeyBasedProducts(products));
                return resolve(data.products);
        })
        .catch(function (error) {
          dispatch(fetchProductsDone());
          return reject('failed');
          // console.log(error);
        }); 
      });
  };
}
