import { combineReducers } from 'redux';

// reducers
import cartReducer from './cart';
import categoriesReducer from './categories';
import productsReducer from './products';
import filtersReducer from './filters';
import industriesReducer from './industries';
import compareReducer from './compare';
import currencyReducer from './currency';
import localeReducer from './locale';
import mobileMenuReducer from './mobile-menu';
import quickviewReducer from './quickview';
import sidebarReducer from './sidebar';
import wishlistReducer from './wishlist';
import userReducer from './user';
import searchReducer from './search';
import orderReducer from './order';
import reviewReducer from './review';
import formsReducer from './forms';
import lotsReducer from './lots';

export default combineReducers({
    user:userReducer,
    search:searchReducer,
    cart: cartReducer,
    order:orderReducer,
    categories: categoriesReducer,
    products:productsReducer,
    filters:filtersReducer,
    industries: industriesReducer,
    compare: compareReducer,
    currency: currencyReducer,
    locale: localeReducer,
    mobileMenu: mobileMenuReducer,
    quickview: quickviewReducer,
    sidebar: sidebarReducer,
    wishlist: wishlistReducer,
    review:reviewReducer,
    forms:formsReducer,
    lots: lotsReducer
});
