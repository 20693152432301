// react
import {connect} from 'react-redux';
import React, {Component} from 'react';
import { logout } from '../../store/search';

import Autosuggest from 'react-autosuggest';

// third-party
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
 // <div className="site-header__phone-title">
 //                        <FormattedMessage id="header.phoneLabel" defaultMessage="Customer Service" />
 //                    </div>
 //                    <div className="site-header__phone-number">
 //                       <FormattedMessage id="header.phone" defaultMessage="(800) 060-0730" />
 //                    </div>
import { Link } from 'react-router-dom';

// application
import NavPanel from './NavPanel';
import Search from './Search';
import Topbar from './Topbar';
import { User24Svg } from '../../svg';
import CartIndicator from './IndicatorCart';
import Indicator from './Indicator';



class Header extends Component {
     constructor() {
    super();
    this.state = {
      value:  ''
    };
    this.logoutClicked = this.logoutClicked.bind(this);
  }

   logoutClicked(){
    console.log('logout clicked');
    this.props.dispatch(logout());
    this.props.history.push("/");
  }


  render(){
    const { layout,login_status } = this.props;
    let bannerSection;

    if (layout === 'default') {
        bannerSection = (
            <div className="site-header__middle container">
                <div className="site-header__logo">
                    <Link to="/"><img src={`images/logos/gglogo.jpg`} alt="not found" className="img-fluid" /></Link>
                </div>
                <div className="site-header__search">
                    <Search />
                </div>
                 <div className="site-header__indicators">
                        { login_status == 'init' ? 
                        <Indicator url="/account/login" icon={<User24Svg />} label={'Login'} />
                        : <Indicator url="/account/profile" icon={<User24Svg />} label={'My Account'} /> }
                        <CartIndicator label={'My Cart'}/>
                    </div>
            </div>
        );
    }

    return (
        <div className="site-header">
            <Topbar />
            {bannerSection}
            <div className="site-header__nav-panel">
                <NavPanel layout={layout} />
            </div>
        </div>
    );
}
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'compact']),
};

Header.defaultProps = {
    layout: 'default',
};

const mapStateToProps = (state) => ({
    login_status : state.user.login_status
})

export default connect(mapStateToProps
)(Header);



