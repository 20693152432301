// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import CartIndicator from './IndicatorCart';
import Categories from './Categories';
import Indicator from './Indicator';
import IndicatorSearch from './IndicatorSearch';
import { Heart20Svg, LogoSmallSvg } from '../../svg';
    

function NavPanel(props) {
    const { layout, wishlist } = props;

    let logo = null;
    let categories = null;
    let searchIndicator;

    if (layout === 'compact') {
        logo = (
            <div className="nav-panel__logo">
                <Link to="/"><LogoSmallSvg /></Link>
            </div>
        );

        searchIndicator = <IndicatorSearch />;
    }

    if (layout === 'default') {
        categories = (
            <div className="nav-panel__departments">
                <Categories />
            </div>
        );
    }

    return (
        <div className="nav-panel">
            <div className="nav-panel__container container">
                <div className="nav-panel__row">
                    {logo}
                    {categories}

                    <div className="nav-panel__indicators">
                        <Indicator url="/post-your-request"  label={'Post Your Requirements'} />
                        <Indicator url="/our-needs"  label={'Our Needs'} />
                        <Indicator url="/lotsales"  label={'Lot Sale'} />
                        <Indicator external="true" url="https://web.whatsapp.com/send?l=en&amp;phone=918939990568&amp;text=Hi,%20I%20would%20like%20to%20know%20the%20information%20about%20your%20services."  image={'whatsapp.png'} label={'Chat'}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

NavPanel.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'compact']),
};

NavPanel.defaultProps = {
    layout: 'default',
};

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavPanel);
