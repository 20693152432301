// react
import React, {Component} from 'react';

// third-party
import { Helmet } from 'react-helmet';
import { toast  } from 'react-toastify';

// data stubs
import theme from '../../data/theme';
import { changePassword } from '../../store/user';
import SimpleReactValidator from 'simple-react-validator';

import classNames from 'classnames';
import {connect} from "react-redux";


class AccountPagePassword extends Component {
      constructor(props) {
    super(props);
    this.state = {
      type:'password',
      newtype : 'password',
      confirmtype : 'password',
      password : '',
      currentPassword : '',
      checkPassword : ''
     };
    this.validator = new SimpleReactValidator();
    this.setStateFromInput = this.setStateFromInput.bind(this);
    this.showHide = this.showHide.bind(this);
    this.showNewHide = this.showNewHide.bind(this);
    this.showConfirmHide = this.showConfirmHide.bind(this);


  }

   showHide(e){
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input'
    });  
  }

   showNewHide(e){
    this.setState({
      newtype: this.state.newtype === 'input' ? 'password' : 'input'
    });  
  }
   showConfirmHide(e){
    this.setState({
      confirmtype: this.state.confirmtype === 'input' ? 'password' : 'input'
    });  
  }

  handleSubmit = (event) => {
         event.preventDefault();
        if (this.validator.allValid()) {
             this.props.dispatch(changePassword(this.state))
             .then(response => {
                 toast.success('Password changed successfully.');
                 this.setState({
                    password : '',
                    currentPassword : '',
                    checkPassword : ''
                 });
            })
            .catch(err => {
                 toast.error('Failed to change password. Please try with correct password.');

            });
        } else {
              this.validator.showMessages();
              this.forceUpdate();
        }
    }

    componentWillMount(){
      
    }

    setStateFromInput = (event) => {
        var obj = {};
        if(event.target.dataset.object){
             var key = event.target.dataset.object;
             var name = event.target.name;
             var value =  event.target.value;
                this.setState(state => {
                      state[key][name] = value;
                      return state;
                })
           }
           else{
               obj[event.target.name] = event.target.value;
               this.setState(obj);
           }
    }

    render(){
          const { updating } = this.props;
        return (
            <div className="card">
                <Helmet>
                    <title>{`Change Your Password — ${theme.name}`}</title>
                    <meta name="Description" content={`Changes Your Password,Update new password at any time.`}/>
                </Helmet>

                <div className="card-header">
                    <h5>Change Password</h5>
                </div>
                <div className="card-divider" />
                <div className="card-body">
                      <form onSubmit={this.handleSubmit.bind(this)} noValidate>

                                 <div className="form-group">
                            <label htmlFor="password-current">Current Password</label>
                            <input type={this.state.type} name="currentPassword"  placeholder="Enter current password" 
                                                className="form-control"  value={this.state.currentPassword} onChange={this.setStateFromInput} />
                                                     

                                                    {this.validator.message('currentPassword', this.state.currentPassword, 'required')}
                        </div>
                        <div className="form-group">
                            <label htmlFor="password-new">New Password</label>
                           <input type={this.state.newtype} name="password"  placeholder="Enter new password" 
                                                className="form-control"  value={this.state.password} onChange={this.setStateFromInput} />
                                                 

                                                    {this.validator.message('password', this.state.password, 'required')}
                        </div>
                        <div className="form-group">
                            <label htmlFor="password-confirm">Reenter New Password</label>
                             <input type={this.state.confirmtype}  name="checkPassword"  placeholder="Enter confirm password" 
                                                className="form-control"  value={this.state.checkPassword} onChange={this.setStateFromInput} />
                                                

                                                    {this.validator.message('checkPassword', this.state.checkPassword, 'required')}
                        </div>

                        <div className="form-group mt-5 mb-0">
                           <button type="submit" className={classNames('btn btn-primary', {
                                            'btn-loading': updating,
                                        })}>
                                            Change
                                        </button>
                        </div> 
                     </form>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        updating : state.user.updating
    }
}



export default connect(mapStateToProps)(AccountPagePassword);



