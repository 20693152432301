// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Img from 'react-image';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import Rating from './Rating';
import { cartAddItem,addToCart } from '../../store/cart';
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from '../../svg';
import { compareAddItem } from '../../store/compare';
import { quickviewOpen } from '../../store/quickview';
import { wishlistAddItem,addToWishlist } from '../../store/wishlist';


function StaticProductCard(props) {
    const {
        product,
        layout,
        handleOpenModal

    } = props;
    const containerClasses = classNames('product-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });

    let badges = [];
    let image;
    let price;
    let features;

  
    if (product.image) {
        console.log(process.env.PUBLIC_URL);
        console.log(product.image);

        image = (

            <div className="product-card__static_image">
                <Img className="img-fluid-custom" src={`${process.env.PUBLIC_URL}${product.image?
                                        product.image : product.image}`} unloader={ (<img
                                    src={`${process.env.PUBLIC_URL}/images/default.png`}
                                    className="img-fluid-custom"/>)}/>
            </div>
        );
    }
    else
    {
        image = (
         <div className="product-card__image">
             <div className="card-image__default">
                <img src={`${process.env.PUBLIC_URL}/images/default.png`}/>
                </div>
            </div>
        )
    }

  

    return (
        <div className={containerClasses}>
        
            {badges}
            {image}
            <div className="product-card__info">
                <div className="product-card__name">
                    {product.displayText}
                </div>
                 <div className="product-card__description" dangerouslySetInnerHTML={{ __html: product.composition }} />
                 <div className="product-card__other-description">
                     <ul>
                         <li>MATERIAL:&nbsp;{product.material}</li>
                         <li>MATERIAL SPEC:&nbsp;{product.materialSpec}</li>
                         <li>SIZE:&nbsp;{product.size}</li>
                     </ul>
                 </div>
            </div>
            <div className="product-card__actions">
                
                <div className="product-card__buttons">
                                <button onClick={(e) => handleOpenModal(product)}
                                    type="button"
                                    className='btn btn-secondary product-card__addtocart product-card__addtocart--list'
                                >
                                    Send Response
                                </button>
                </div>
            </div>
        </div>
    );
}

StaticProductCard.propTypes = {
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = (state) => {
    return { 
         login_status :  state.user.login_status,
        currentState : state,currency: state.currency };
};

const mapDispatchToProps = {
    addToCart,
    cartAddItem,
    wishlistAddItem,
    addToWishlist,
    compareAddItem,
    quickviewOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StaticProductCard);
