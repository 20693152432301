export default [
    {
        title: 'SAR Saudi Riyal',
        currency: {
            code: 'SAR',
            symbol: 'SAR ',
            name: 'Saudi Riyal',
            productListPriceLabel : 'sarprice',
            discountPriceLabel : 'discountsarprice',
            cartProductPriceLabel:'sarPrice',
            cartProductSubTotalLabel:'subSarTotal',
            cartAllSubtotalLabel:'cartSARSubTotal',
            cartAllTotalLabel:'cartSARTotal',
            wishListProductPriceLabel:'sarPrice',
            checkoutSubtotalLabel:'subSarTotal',
            checkoutTotalLabel:'sarTotal',
            taxValueLabel:'sarValue',
            orderTotalLabel:'sarValue',
            lotDiscountPriceLabel: 'lsSarDisPrice',
            lotPriceLabel: 'lsSarPrice'
        },
    },
    {
        title: 'USD Dollar',
        currency: {
            code: 'USD',
            symbol: '$',
            name: 'Dollar',
            productListPriceLabel : 'usdprice',
            discountPriceLabel: 'discountusdprice',
            cartProductPriceLabel:'usdPrice',
            cartProductSubTotalLabel:'subUsdTotal',
            cartAllSubtotalLabel:'cartUSDSubTotal',
            cartAllTotalLabel:'cartUSDTotal',
            wishListProductPriceLabel:'usdPrice',
            checkoutSubtotalLabel:'subUsdTotal',
            checkoutTotalLabel:'usdTotal',
            taxValueLabel:'usdValue',
            orderTotalLabel:'usdValue',
            lotDiscountPriceLabel: 'lsUsDDisPrice',
            lotPriceLabel: 'lsUsdPrice', 

        },
    },
    {
        title: 'INR Indian Rupee',
        currency: {
            code: 'INR',
            symbol: '₹',
            name: 'Indian Rupee',
            productListPriceLabel : 'price',
            discountPriceLabel: 'discountprice',
            cartProductPriceLabel:'price',
            cartProductSubTotalLabel:'subTotal',
            cartAllSubtotalLabel:'cartSubTotal',
            cartAllTotalLabel:'cartTotal',
            wishListProductPriceLabel:'price',
            checkoutSubtotalLabel:'subTotal',
            checkoutTotalLabel:'total',
            taxValueLabel:'value',
            orderTotalLabel:'price',
            lotDiscountPriceLabel: 'lsDisPrice',
            lotPriceLabel: 'lsPrice',
        },
    },
];
