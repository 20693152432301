// react
import React, {Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';

import { updateCustomer } from '../../store/user';
import {connect} from "react-redux";
import classNames from 'classnames';

// third-party
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// data stubs
import dataAddresses from '../../data/accountAddresses';
import theme from '../../data/theme';


class AccountPageAddresses extends Component {
      constructor(props) {
    super(props);
    this.state = {
        editBillingAddress : false,
        editDeliveryAddress: false
     };
    this.validator = new SimpleReactValidator();
    this.setStateFromInput = this.setStateFromInput.bind(this);
    this.showHide = this.showHide.bind(this);
  }

   showHide(e){
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input'
    })  
  }

  handleSubmit = (event) => {
    event.preventDefault();
        if (this.validator.allValid()) {
             this.props.dispatch(updateCustomer(this.state));
        } else {
              this.validator.showMessages();
              this.forceUpdate();
        }
    }

componentWillMount(){
  var addressObj = {
       "address":"",
         "billingAddress": true,
         "city": "",
         "country": "SA",
         "phoneNumber": "",
         "postalCode": "",
         "contactPerson":"",
         "contactDesignation":"",
         "emailAddress":""
    }
   if(this.props.account_data)
   {
     var account = this.props.account_data;
     if(!account.billing){
       account.billing = { ...addressObj};
     }
     if(!account.delivery){
       account.delivery = { ...addressObj};
     }
     this.setState(account);
     console.log(this.state);
   }
   this.addEditBillingAddressManage = this.addEditBillingAddressManage.bind(this);
   this.addEditDeliveryAddressManage = this.addEditDeliveryAddressManage.bind(this);
   this.cancelEditAddress = this.cancelEditAddress.bind(this);
}
    

  addEditBillingAddressManage = () => {
     this.validator = new SimpleReactValidator();
     this.setState(state => {
            state['editBillingAddress'] = !state.editBillingAddress;
            state['editDeliveryAddress'] = false;
            return state;
      });
  }

  addEditDeliveryAddressManage = () => {
     this.validator = new SimpleReactValidator();
     this.setState(state => {
            state['editDeliveryAddress'] = !state.editDeliveryAddress;
            state['editBillingAddress'] = false;
            return state;
      });
  }

  cancelEditAddress = () => {
     this.validator = new SimpleReactValidator();
     this.setState(state => {
             state['editBillingAddress'] = false;
            state['editDeliveryAddress'] = false;
            return state;
      });
  }

setStateFromInput = (event) => {
  console.log(this.state);
    var obj = {};
    if(event.target.dataset.object){
        console.log(event.target.dataset.object);
         var key = event.target.dataset.object;
         var name = event.target.name.replace('delivery_','').replace('billing_','');
         var value =  event.target.value;
            this.setState(state => {
                  if(!state[key])
                    state[key] = {};
                  state[key][name] = value;
                  return state;
            })
       }
       else{
            var name = event.target.name.replace('delivery_','').replace('billing_','');
           obj[name] = event.target.value;
           this.setState(obj);
       }
}


    render(){

            const { updating } = this.props;

            return (
                <React.Fragment>
                {!(this.state.editBillingAddress || this.state.editDeliveryAddress) ? 
                <div className="addresses-list">
                    <Helmet>
                        <title>{`Address List — ${theme.name}`}</title>
                    </Helmet>
                    <div className="addresses-list__divider" />
                     <React.Fragment>
                    <div className="addresses-list__item card address-card"><div className="address-card__badge">Billing</div>
                        { this.state.billing.city ? 
                        <div className="address-card__body">
                            <div className="address-card__name">{`${this.state.custFirstName} ${this.state.custLastName}`}</div>
                            <div className="address-card__row">
                                {this.state.billing.country}
                                <br />
                                {this.state.billing.postalCode}
                                ,
                                {this.state.billing.city}
                                <br />
                                {this.state.billing.address}
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Phone Number</div>
                                <div className="address-card__row-content">{this.state.billing.phoneNumber}</div>
                            </div>
                            
                            <div className="address-card__footer">
                                <div className="btn btn-secondary btn-sm" onClick={this.addEditBillingAddressManage}>Edit</div>
                            </div>
                        </div>
                        : 
                        <div className="address-card__body">
                            <div className="address-card__footer">
                                <div className="btn btn-secondary btn-sm" onClick={this.addEditBillingAddressManage}>Add Billing Address</div>
                            </div>
                        </div>
                        }
                    </div>
                    <div className="addresses-list__divider" />
                </React.Fragment>
                 <React.Fragment>
                    <div className="addresses-list__item card address-card"><div className="address-card__badge">Delivery</div>
                         { this.state.delivery.city ? 
                        <div className="address-card__body">
                            <div className="address-card__name">{`${this.state.custFirstName} ${this.state.custLastName}`}</div>
                            <div className="address-card__row">
                                {this.state.delivery.country}
                                <br />
                                {this.state.delivery.postalCode}
                                ,
                                {this.state.delivery.city}
                                <br />
                                {this.state.delivery.address}
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Phone Number</div>
                                <div className="address-card__row-content">{this.state.delivery.phoneNumber}</div>
                            </div>
                           
                            <div className="address-card__footer">
                                <div className="btn btn-secondary btn-sm" onClick={this.addEditDeliveryAddressManage}>Edit</div>
                            </div>
                        </div>
                        : 
                        <div className="address-card__body">
                             <div className="address-card__footer">
                                <div className="btn btn-secondary btn-sm" onClick={this.addEditDeliveryAddressManage}>Add Delivery Address</div>
                            </div>
                        </div>
                        }
                    </div>

                    <div className="addresses-list__divider" />
                </React.Fragment>
                </div>
                : 
                     <form className="theme-form" onSubmit={this.handleSubmit.bind(this)} noValidate>
                      {this.state.editBillingAddress ?   <h6>Update Billing Address</h6> :  
                                <h6>Update Delivery Address</h6>  }
                                <hr/>
                       {this.state.editBillingAddress ? 
                           <React.Fragment>
                                     <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-first-name">First Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-first-name"
                                                    placeholder="First Name"
                                                    name="custFirstName"
                                                    disabled={true}
                                                    value={this.state.custFirstName} onChange={this.setStateFromInput} />
                                                        {this.validator.message('custFirstName', this.state.custFirstName, 'required')}
                                                
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-last-name">Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-last-name"
                                                    placeholder="Last Name"
                                                    name="custLastName"
                                                    disabled={true}
                                                    value={this.state.custLastName} onChange={this.setStateFromInput} />
                                                        {this.validator.message('custLastName', this.state.custLastName, 'required')}
                                               
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="checkout-country">Country</label>
                                            <select id="checkout-country" data-object="billing" className="form-control" name="country" value={this.state.billing.country} onChange={this.setStateFromInput}>
                                                <option value="IN" >India</option>
                                                <option value="SA">Saudi Arabia</option>
                                                <option  value="US">United States</option>
                                            </select>
                                            {this.validator.message('country', this.state.billing.country, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-street-address">Street Address</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="checkout-street-address"
                                                placeholder="Street Address"
                                                name="address"
                                                data-object="billing"
                                             value={this.state.billing.address} onChange={this.setStateFromInput} />
                                                    {this.validator.message('address', this.state.billing.address, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-city">Town / City</label>
                                            <input type="text" className="form-control" data-object="billing" id="checkout-city" name="city" value={this.state.billing.city} onChange={this.setStateFromInput} />
                                                    {this.validator.message('city', this.state.billing.city, 'required')}
                                        </div>
                                       
                                        <div className="form-group">
                                            <label htmlFor="checkout-postcode">Postcode / ZIP</label>
                                            <input type="text" className="form-control" data-object="billing" id="checkout-postcode" name="postalCode"  value={this.state.billing.postalCode} onChange={this.setStateFromInput} />
                                                    {this.validator.message('postalCode', this.state.billing.postalCode,'required|numeric')}
                                        </div>

                                        <div className="form-row">
                                            
                                            <div className="form-group col-md-12">
                                                <label htmlFor="checkout-phone">Phone</label>
                                                <input type="text" className="form-control" data-object="billing" name="phoneNumber" id="checkout-phone" placeholder="Phone" 
                                                 value={this.state.billing.phoneNumber} onChange={this.setStateFromInput} />
                                                    {this.validator.message('phoneNumber', this.state.billing.phoneNumber,  'required|phone')}
                                            </div>
                                        </div>
                                         <button type="submit" className={classNames('btn btn-primary', {
                                            'btn-loading': this.props.updating,
                                        })}>
                                            Update
                                        </button>&nbsp;&nbsp;
                                        <button type="cancel" onClick={this.cancelEditAddress} className='btn btn-primary'>
                                            Close
                                        </button>
                 </React.Fragment>
                 : <React.Fragment>
                                     <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-first-name">First Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-first-name"
                                                    placeholder="First Name"
                                                    name="custFirstName"
                                                    disabled={true}
                                                    value={this.state.custFirstName} onChange={this.setStateFromInput} />
                                                        {this.validator.message('custFirstName', this.state.custFirstName, 'required')}
                                                
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-last-name">Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-last-name"
                                                    placeholder="Last Name"
                                                    name="custLastName"
                                                    disabled={true}
                                                    value={this.state.custLastName} onChange={this.setStateFromInput} />
                                                        {this.validator.message('custLastName', this.state.custLastName, 'required')}
                                               
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-country">Country</label>
                                            <select id="checkout-country" className="form-control" data-object="delivery" name="delivery_country"  value={this.state.delivery.country} onChange={this.setStateFromInput}>
                                                <option value="IN" >India</option>
                                                <option value="SA">Saudi Arabia</option>
                                                <option  value="US">United States</option>
                                            </select>
                                            {this.validator.message('delivery_country', this.state.delivery.country, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-street-address">Street Address</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="checkout-street-address"
                                                placeholder="Street Address"
                                                name="delivery_address"
                                                data-object="delivery" 
                                             value={this.state.delivery.address} onChange={this.setStateFromInput} />
                                                    {this.validator.message('delivery_address', this.state.delivery.address, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-city">Town / City</label>
                                            <input type="text" className="form-control" data-object="delivery"  id="checkout-city" name="delivery_city" value={this.state.delivery.city} onChange={this.setStateFromInput} />
                                                    {this.validator.message('delivery_city', this.state.delivery.city, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-postcode">Postcode / ZIP</label>
                                            <input type="text" className="form-control" data-object="delivery"  id="checkout-postcode" name="delivery_postalCode"  value={this.state.delivery.postalCode} onChange={this.setStateFromInput} />
                                                    {this.validator.message('delivery_postalCode', this.state.delivery.postalCode, 'required|numeric')}
                                        </div>

                                        <div className="form-row">
                                           
                                            <div className="form-group col-md-12">
                                                <label htmlFor="checkout-phone">Phone</label>
                                                <input type="text" name="delivery_phoneNumber"  data-object="delivery"  className="form-control" id="checkout-phone" placeholder="Phone" 
                                                 value={this.state.delivery.phoneNumber} onChange={this.setStateFromInput} />
                                                    {this.validator.message('delivery_phoneNumber', this.state.delivery.phoneNumber,  'required|phone')}
                                            </div>
                                        </div>
                                         <button type="submit" className={classNames('btn btn-primary', {
                                            'btn-loading': this.props.updating,
                                        })}>
                                            Update
                                        </button> &nbsp;&nbsp;
                                        <button type="cancel" onClick={this.cancelEditAddress} className='btn btn-primary'>
                                            Close
                                        </button>

                 </React.Fragment> }
                    </form>
                 }
                </React.Fragment>
            );
    }
}

function mapStateToProps(state) {
    return {
        account_data : state.user.account_detail,
        updating : state.user.updating
    }
}



export default connect(mapStateToProps)(AccountPageAddresses);



