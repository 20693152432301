export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_UPDATE_QUANTITIES = 'CART_UPDATE_QUANTITIES';
export const ADD_TO_CART = 'ADD_TO_CART'
export const UPDATE_TO_CART = 'UPDATE_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const INCREMENT_QTY = 'INCREMENT_QTY'
export const DECREMENT_QTY = 'DECREMENT_QTY'
export const LIST_CART = 'LIST_CART'
export const CART_TOTAL = 'CART_TOTAL'
export const CART_SUBTOTAL = 'CART_SUBTOTAL'
export const CART_INR_TOTAL = 'CART_INR_TOTAL'
export const CART_INR_SUBTOTAL = 'CART_INR_SUBTOTAL'
export const CART_USD_TOTAL = 'CART_USD_TOTAL'
export const CART_USD_SUBTOTAL = 'CART_USD_SUBTOTAL'
export const CART_SAR_TOTAL = 'CART_SAR_TOTAL'
export const CART_SAR_SUBTOTAL = 'CART_SAR_SUBTOTAL'
export const RESET_CART = 'RESET_CART'
export const CART_UPDATE_BEGIN = 'CART_UPDATE_BEGIN'
export const CART_UPDATE_END = 'CART_UPDATE_END'
export const CART_MINIMUM_VALUE = 'CART_MINIMUM_VALUE'
export const READY_TO_ORDER = 'READY_TO_ORDER'
