// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import {
    HashRouter,
    BrowserRouter,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';
import { ScrollContext } from 'react-router-scroll-4';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';

// application
import languages from '../i18n';
import { localeChange } from '../store/locale';

// pages
import Layout from './Layout';
import HomePage from './home/Home';

import HomePageOne from './home/HomePageOne';
import HomePageTwo from './home/HomePageTwo';

const DEFAULT_CONFIG = {
  trackingId: 'UA-98458111-1',
  debug: true,
  gaOptions: {
    cookieDomain: 'none'
  }
};

const tagManagerArgs = {
    gtmId: 'GTM-PX52S58'
}

class Root extends Component {
    constructor(props, context) {
    super(props, context);
     this.state = {
      configs: [DEFAULT_CONFIG]
    };
    this.initReactGA();
    this.initReactGTM();
    this.initFacebookPixel();
    };

    initReactGA = () => {
    if (this.filteredConfigs().length === 0) {
      return;
    }
    ReactGA.initialize(this.state.configs);
  };
  initReactGTM = () => {
    TagManager.initialize(tagManagerArgs)
  };

  initFacebookPixel = () => {
    const advancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
    autoConfig: true,     // set pixel's autoConfig
    debug: false,         // enable logs
};
ReactPixel.init('450975418590450', advancedMatching, options);
 
  };



    filteredConfigs = () => {
        return this.state.configs.filter(({ trackingId: id }) => id);
    };

    componentDidMount() {
        // preloader
        // setTimeout(() => {
        //     const preloader = document.querySelector('.site-preloader');

        //     preloader.addEventListener('transitionend', (event) => {
        //         if (event.propertyName === 'opacity') {
        //             preloader.parentNode.removeChild(preloader);
        //         }
        //     });
        //     preloader.classList.add('site-preloader__fade');
        // }, 500);

        // this is for demo only, you can delete it
        const { localeChange: changeLocale } = this.props;
        const direction = new URLSearchParams(window.location.search).get('dir');

        if (direction !== null) {
            changeLocale(direction === 'rtl' ? 'ar' : 'en');
        }
    }

    render() {
        const { locale } = this.props;
        const { messages, direction } = languages[locale];
        const { configs } = this.state;
        
        return (
            <IntlProvider locale={locale} messages={messages}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <Helmet htmlAttributes={{ lang: locale, dir: direction }} />
                    <ScrollContext>
                        <Switch>
                            <Route
                                path="/home-two"
                                render={(props) => (
                                    <Layout {...props} headerLayout="compact" homeComponent={HomePageTwo} />
                                )}
                            />
                            <Route
                                path="/"
                                render={(props) => (
                                    <Layout {...props} headerLayout="default" homeComponent={HomePage} />
                                )}
                            />
                            <Redirect to="/" />
                        </Switch>
                    </ScrollContext>
                </BrowserRouter>
            </IntlProvider>
        );
    }
}

Root.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
