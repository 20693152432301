// react
import React, {Component} from 'react';

// third-party
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ReactGA from 'react-ga';

// application
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';

// data stubs
import theme from '../../data/theme';
import SimpleReactValidator from 'simple-react-validator';
import {connect} from "react-redux";

import { toast  } from 'react-toastify';
import { sendRequestNow , sendRequestStatus } from '../../store/forms';
import {withRouter} from 'react-router-dom';
import { trackPage } from '../../services';


class PostRequirements extends Component  {
      constructor(props) {
    super(props);
    this.state = {
     "firstName":"",
  	 "companyName":"",
  	 "emailId":"",
  	 "phoneNumber":"",
  	 "description":"",
  	 "document":""
    };
    this.validator = new SimpleReactValidator();
    this.setStateFromInput = this.setStateFromInput.bind(this);
  }
   setStateFromInput = (event) => {
        var obj = {};
        if(event.target.dataset.object){
             var key = event.target.dataset.object;
             var name = event.target.name;
             var value =  event.target.value;
                this.setState(state => {
                      state[key][name] = value;
                      return state;
                })
           }
           else{
               obj[event.target.name] = event.target.value;
               this.setState(obj);
        }
    }
    componentWillMount(){
      console.log('event');
      this.props.trackPage(this.props.location.pathname + this.props.location.search);
    	this.props.dispatch(sendRequestStatus(false));
    }

    componentWillUnmount(){
			this.props.dispatch(sendRequestStatus(false));
    }


   handleSubmit = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'Requests',
      action: 'Post Requirement',
      label: 'Form Submit'
    });
    if (this.validator.allValid()) {
             this.props.dispatch(sendRequestNow(this.state))
              .then(response => {

                })
                  .catch(err => {
                    console.log(err); 
                      toast.error(err.message);
                  });
       
    } else {
              this.validator.showMessages();
              this.forceUpdate();
    }
  }

  handleFileDocument = (event) => {
  this.setState({document:event.target.files[0]})
  }

  render(){
    const breadcrumb = [
        { value: 'Home', lineage: '' },
        { value: 'Post Your Requirements', lineage: '' },
    ];
    const { loading, request_status } = this.props;
    let content;
    let loadSection;
    loadSection = () => {
      
         content = (<React.Fragment>
                                       <div className="form-group">
                                            <input
                                                id="register-firstname"
                                                type="text"
                                                name="firstName"
                                                className="form-control"
                                                placeholder="Enter first name"
                                                value={this.state.firstName} onChange={this.setStateFromInput} />
                                                    {this.validator.message('firstName', this.state.firstName, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <input
                                                id="register-lastname"
                                                type="text"
                                                name="companyName"
                                                className="form-control"
                                                placeholder="Enter company name"
                                                value={this.state.companyName} onChange={this.setStateFromInput} />
                                                    {this.validator.message('companyName', this.state.companyName, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <input
                                                id="register-emailId"
                                                type="email"
                                                name="emailId"
                                                className="form-control"
                                                placeholder="Enter email"
                                                value={this.state.emailId} onChange={this.setStateFromInput} />
                                                    {this.validator.message('emailId', this.state.emailId, 'required|email')}
                                        </div>
                                         <div className="form-group">
                                            <input
                                                id="register-mobile"
                                                type="text"
                                                name="phoneNumber"
                                                className="form-control"
                                                placeholder="Enter contact number"
                                                value={this.state.phoneNumber} onChange={this.setStateFromInput} />
                                                    {this.validator.message('phoneNumber', this.state.phoneNumber, 'required|phone')}
                                        </div>
                                         <div className="form-group">
                                            <textarea name="description" rows="5" placeholder="Enter description" 
                                                className="form-control"  value={this.state.description} onChange={this.setStateFromInput}>
                                                </textarea>
                                                    {this.validator.message('description', this.state.description, 'required')}
                                        </div>
                                        <div className="form-group">
                                        		<input type="file" name="document" className="form-control border-none" 
                                                   onChange={this.handleFileDocument} />
                                        </div>
                                        <div className="form-group d--btn-block">
                                        <button type="submit" className={classNames('btn btn-primary d-flex-btn', {
                                            'btn-loading': loading})}>
                                            Send Your Request
                                        </button>
                                        </div>
                                        </React.Fragment>)
     
   
    return content;
  }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Post your Requirement — ${theme.name}`}</title>
                <meta name="Description" content={`Get all kind of industrial supplies, MRO supplies from one complete online platform. Just fill in the details and submit the button to post the requirement.`}/>

            </Helmet>

            <PageHeader header="" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                { !request_status ? 
                                <div className="card-body">

                                <h3 className="card-title">Post Your Requirements</h3> 
                                    <form onSubmit={this.handleSubmit.bind(this)} noValidate>
                                        {loadSection()}
                                    </form>

                                </div>
                                :
                                <div className="card-body">

                                    <h3 className="card-title">Request sent successfully</h3> 
                                    <p>Thank you for your request. Our Goospares admin will contact you soon.</p>
                                    <Link to="/" className="btn btn-primary btn-sm">Go to Home</Link>
                                </div>
                              }
                            </div>
                        </div>
                        <div className="col-md-5 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0 card-fit-height">
                                <div className="card-body">
                                    <h3 className="card-title">Why Post here?</h3>
                                    <ul>
                                    <li>Global Platform for buyers and sellers</li>
                                    <li>Fast and Easy Access</li>
                                    <li>Quick Quote</li>
                                    <li>Best Discount on Top Brands</li>
                                    <li>1 Million Product categories</li>
                                    <li>24/7 Support</li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
}


function mapStateToProps(state) {
    return {
        loading 		: state.forms.loading,
        request_status  : state.forms.requestStatus,
        trackPage:trackPage
    }
}

export default connect(mapStateToProps)(withRouter(PostRequirements))




