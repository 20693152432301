// react
import React, {Component} from 'react';

// application
import { Search20Svg } from '../../svg';
import Select from 'react-select'
import Autosuggest from 'react-autosuggest';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import AutosuggestHighlightMatch from "autosuggest-highlight/umd/match";
import AutosuggestHighlightParse from "autosuggest-highlight/umd/parse";
import { getSuggestions,clearSuggestions,setSearchQuery,logout } from '../../store/search';

const shouldRenderSuggestions = value  => value.trim().length > 2;

const getSuggestionValue = suggestion => suggestion;

const renderSuggestion = (suggestion,{ query, isHighlighted }) => {
   const matches = AutosuggestHighlightMatch(suggestion, query);
  const parts = AutosuggestHighlightParse(suggestion, matches);
  return (
    parts.map((part, index) => {
        const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;
        return (
          <Link key={index} to={`${process.env.PUBLIC_URL}/products?query=${suggestion}`}>
          <span className={className} >
            {part.text}
          </span>
        </Link>
          
        );
      })
  )
}


class Search extends Component {
   
      constructor(props){
        super(props);
        this.state = {
          placeholderText : 'all',
           value:  ''
        };
       
    this.searchOnClick = this.searchOnClick.bind(this);
    this.handleSearchState = this.handleSearchState.bind(this);

      }

      onChange = (event, { newValue }) => {
    this.handleSearchState(newValue);
 };

  onKeyDown= (event) => {
    if(event.key == 'Enter' && event.target.value){
        this.props.history.push("/products?query="+event.target.value);
    }
  };

   



  searchOnClick = (inputProps) => {
    if(inputProps.value){
      this.props.history.push("/products?query="+inputProps.value);
    }
  };

   componentDidUpdate(prevProps){
     if(prevProps.searchQuery && !this.props.searchQuery){
       this.handleSearchState('');
     }
  }


  componentWillMount(){
  var queries = queryString.parse(this.props.location.search);
  if(queries.query){
    this.handleSearchState(queries.query);
  }
  else
  {
   this.handleSearchState('');
  }
   
  }

  handleSearchState(value){
    this.setState(state => {
      state['value'] = value;
      this.props.dispatch(setSearchQuery(value));
      return state;
    });
  }
  
  onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
      this.props.history.push("/products?query="+suggestionValue);
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.dispatch(getSuggestions(value));
  };

  onSuggestionsClearRequested = () => {
    this.props.dispatch(clearSuggestions([]));
  };



      handleChange = (newValue, actionMeta) => {
    console.group('Value Changed');
    console.log(newValue);
     this.setState((state) => (
                {
                    placeholderText : newValue.label
                }
            ));
    console.groupEnd();
  };
  handleInputChange = (inputValue, actionMeta) => {
      
    console.group('Input Changed');
    console.log(inputValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  placeholderText  = 'all';


 render() {
        const options = [
        { value: 'autosuggest', label: 'All' },
      { value: 'category', label: 'Category' },
      { value: 'manufacturer', label: 'Manufacturer' },
      { value: 'sku', label: 'SKU' }
    ];
    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5
          }),
        clearIndicator: (provided, state) => {
          return {
            ...provided,
            display:'none'
          }
        },
        menuList: (provided, state) => {
          return {
            ...provided,
            fontSize:'12px'
          }
        },
        option: (provided, state) => {
          return {
            ...provided,
            padding:'5px 12px'
          }
        },
        indicatorsContainer: (provided, state) => {
          return {
            ...provided
          }
        },
        valueContainer: (provided, state) => {
          return {
            ...provided,
            display:'none'
          }
        },
        indicatorSeparator: (provided, state) => {
          return {
            ...provided,
            width: '0px'
          }
        },
         menu: (provided, state) => ({
    ...provided,
    width:'150px'
  }),
           singleValue: (provided, state) => ({
    ...provided,
    color:'#fff'
  }),
         placeholder: (provided, state) => ({
    ...provided,
    fontSize:13,
    color:'#fff'
  }),
  control: (provided,{ selectProps: { width }}) => ({
      ...provided,
    minHeight:54,
    color: '#fff',
    background:'#c33', 
  })
}

const { value, placeholderText } = this.state;

        const inputProps = {
          
          placeholder: `Search ${placeholderText} products`,
          value,
          onKeyDown : this.onKeyDown.bind(this),
          onChange: this.onChange
        };
                const {suggestions} = this.props;

  return (
        <div className="search">
            <div className="search__form">
              <div className="input-field first-wrap">
                <div className="input-select">
                 <Select className='ctg-react' classNamePrefix="ctg-react" styles={customStyles} placeholder="Search by" menuColor="red" onChange={this.handleChange}
        onInputChange={this.handleInputChange} isClearable options={options} />
                </div>
              </div>
              
                 <div className="search__input">
                <Autosuggest className="search__input"
                  suggestions={suggestions} 
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  onSuggestionSelected = {this.onSuggestionSelected.bind(this)}
                  shouldRenderSuggestions={shouldRenderSuggestions}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                />
                </div>
                <button className="search__button" onClick={() => this.searchOnClick(inputProps)}>
                    <Search20Svg />
                </button>
                <div className="search__border" />
            </div>
        </div>
    );
}
}

function mapStateToProps(state,ownProps) {
    return {
        suggestions : state.search.suggestions
    }
}

export default connect(mapStateToProps)(withRouter(Search));




