
export const FETCH_CATEGORIES_BEGIN = 'FETCH_CATEGORIES_BEGIN'
export const FETCH_CATEGORIES_DONE = 'FETCH_CATEGORIES_DONE'
export const FETCH_FEATUREDCATEGORIES_BEGIN = 'FETCH_FEATUREDCATEGORIES_BEGIN'
export const FETCH_FEATUREDCATEGORIES_DONE = 'FETCH_FEATUREDCATEGORIES_DONE'
export const FETCH_TOPCATEGORIES_BEGIN = 'FETCH_TOPCATEGORIES_BEGIN'
export const FETCH_TOPCATEGORIES_DONE = 'FETCH_TOPCATEGORIES_DONE'
export const FETCH_SINGLE_CATEGORY = 'FETCH_SINGLE_CATEGORY'
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES'
export const ROOT_CATEGORY = 'ROOT_CATEGORY'
export const RECEIVE_FEATURED_CATEGORIES = 'RECEIVE_FEATURED_CATEGORIES'
export const RECEIVE_TOP_CATEGORIES = 'RECEIVE_TOP_CATEGORIES'

