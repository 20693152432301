// react
import React, { Component } from 'react';

// third-party
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {connect} from "react-redux";
import Currency from '../shared/Currency';

// application
import Pagination from '../shared/Pagination';

// data stubs
import theme from '../../data/theme';
import { getOrders } from '../../store/order';


class AccountPageOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            pageLimit:25,
            offsetStart:0,
            selected : null,
        };
    }

     componentWillMount(){
           console.log(this.props);
       if(this.props.account_data)
       {
          this.props.dispatch(getOrders(this.props.account_data.id,this.state.offsetStart,this.state.pageLimit));
       }
      }

       handlePageChange = (page) => {
        console.log(page);
        this.setState(() => ({ page }));
      let selected = page;
      this.setState({ selected : selected});
      let pageLimit = Math.floor(this.state.pageLimit);
      let offsetStart = Math.ceil((selected - 1) * pageLimit);
      let offsetEnd = Math.ceil(offsetStart + pageLimit);
      this.setState({ selected : selected, offsetStart: offsetStart, pageLimit: pageLimit }, () => {
             this.props.dispatch(getOrders(this.props.account_data.id, offsetStart, pageLimit));
      });
    };


   

    render() {
        const { page } = this.state;
        const { orders,totalCount,infiniteLimit,currency } = this.props;
        console.log(this.props);
        const validOrders = this.props.orders.filter((order) => {
                return order.products && order.products.length;
            });
        const ordersList = validOrders.map((order) => (
            <tr key={order.id}>
                <td>{order.orderId}</td>
                <td>{order.datePurchased}</td>
                <td>{order.orderStatus}</td>
                <td><Currency value={Number(order.total[this.props.currency.orderTotalLabel])} /></td>
                <td>
                <Link to={`/account/order/${order.id}`}>View Order</Link>
                </td>
            </tr>
        ));

        return (
            <div className="card">
                <Helmet>
                    <title>{`My Orders|Track Orders — ${theme.name}`}</title>
                    <meta name="Description" content={`Goospares Dashboard. Sign in to see the order History, latest searches ,etc. Monitoring and oversighting the recent orders. Efficient way to get recent orders.  `}/>
                </Helmet>

                <div className="card-header">
                    <h5>Order History</h5>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Total</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ordersList}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-footer">
                    <Pagination current={page} total={Math.ceil(totalCount/infiniteLimit)} onPageChange={this.handlePageChange} />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        account_data : state.user.account_detail,
        orders : state.order.orders,
         totalCount: state.order.totalCount,
        infiniteLimit : state.order.infiniteLimit,
        loading :   state.order.loading,
        currency: state.currency
    }
}

export default connect(mapStateToProps)(AccountPageOrders);


