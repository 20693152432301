// react
import React, { Component }  from 'react';
import {connect} from "react-redux";

// third-party
import { Helmet } from 'react-helmet';

// blocks
import BlockBanner from '../blocks/BlockBanner';
import BlockBrands from '../blocks/BlockBrands';
import BlockIndustries from '../blocks/BlockIndustries';
import BlockFeatures from '../blocks/BlockFeatures';
import BlockPosts from '../blocks/BlockPosts';
import BlockProductColumns from '../blocks/BlockProductColumns';
import BlockProducts from '../blocks/BlockProducts';
import BlockSlideShow from '../blocks/BlockSlideShow';
import BlockTabbedProducts from '../blocks/BlockTabbedProducts';

// data stubs
import categories from '../../data/shopBlockCategories';
import posts from '../../data/blogPosts';
import products from '../../data/shopProducts';
import theme from '../../data/theme';
import { setFeaturedIndustries } from '../../store/industries';

import { getFeaturedIndustries } from '../../services';

class HomePage extends Component {

  
    componentWillMount(){
        this.props.dispatch(setFeaturedIndustries());
    }
    render() {
    const columns = [
        {
            title: 'Top Rated Products',
            products: products.slice(0, 3),
        },
        {
            title: 'Special Offers',
            products: products.slice(3, 6),
        },
        {
            title: 'Bestsellers',
            products: products.slice(6, 9),
        },
    ];
    const {featuredindustries } = this.props;
 
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Goospares - India's  1st Online Marketplace to Buy & Sell MRO Products`}</title>
                <meta name="Description" content={`India's No 1 MRO Industrial spare parts  Online - Goospares for sellers and buyers of unused, slow moving and non-moving inventory that are held as stock for long time by the business.`}/>
            </Helmet>

            <BlockSlideShow withDepartments/>

                        <BlockIndustries title="Industries" layout="compact" industries={featuredindustries} />

            <BlockTabbedProducts title="Featured Products" searchBy="featured" keyparam="" layout="grid-5" />

             <BlockBanner />
            
            <BlockTabbedProducts title="Automobile Products" searchBy="industry" keyparam="Automobile" layout="grid-5" />

            <BlockTabbedProducts title="Oil & Gas Products" searchBy="industry" keyparam="Oil & Gas" layout="grid-5" />

            <BlockPosts title="Case Studies" layout="list-sm" posts={posts} />

             
        	 <BlockFeatures/>

             <BlockBrands title="Our Clients"/>


        </React.Fragment>
    );
}
}

const mapStateToProps = (state) => ({
    featuredindustries: getFeaturedIndustries(state.industries),
})

export default connect(
    mapStateToProps
)(HomePage)
