// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';

// application
import CategoryLinks from './CategoryLinks';
import { Cross20Svg } from '../../svg';
import { currencyChange } from '../../store/currency';
import { localeChange } from '../../store/locale';
import { categoryMenuClose } from '../../store/mobile-menu';
import { getCategoriesMenu } from '../../services';
import { getRootCategory } from '../../store/categories';

// data stubs
import currencies from '../../data/shopCurrencies';


class CategoryMenu extends Component {

	constructor(props) {
	  super(props);
	  this.state = {};
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleOutsideClick);
		console.log(this.props.categories);
        if(!this.props.categories.length){
             this.props.getRootCategory();
        }
	}

	render() {
    const {
        categoryMenuState,
        closeCategoryMenu,
        changeLocale,
        changeCurrency,
        categories
    } = this.props;

    const classes = classNames('mobilemenu', {
        'mobilemenu--open': categoryMenuState.copen,
    });

    const handleItemClick = (item) => {
          closeCategoryMenu();
    };

    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeCategoryMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">Categories</div>
                    <button type="button" className="mobilemenu__close" onClick={closeCategoryMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                { categories && categories.length ? 
                <div className="mobilemenu__content">
                    <CategoryLinks categories={categories} onItemClick={handleItemClick} />
                </div>
                : ''}
            </div>
        </div>
    );
	}
}

const mapStateToProps = (state) => ({
    categories: getCategoriesMenu(state.categories),
    categoryMenuState: state.mobileMenu,
});

const mapDispatchToProps = {
    closeCategoryMenu: categoryMenuClose,
    changeLocale: localeChange,
    changeCurrency: currencyChange,
    getRootCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryMenu);
