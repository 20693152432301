import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM,
ADD_TO_WISHLIST,REMOVE_FROM_WISHLIST,RECEIVE_WISHLIST,WISHLIST_BEGIN,WISHLIST_END } from './wishlistActionTypes';


function addItem(state, product) {
    const itemIndex = state.findIndex((x) => x.id === product.id);

    if (itemIndex === -1) {
        return [
            ...state,
            JSON.parse(JSON.stringify(product)),
        ];
    }

    return state;
}

const initialState = [];

export default function wishlistReducer(state = initialState, action) {
    switch (action.type) {
    case WISHLIST_ADD_ITEM:
        return addItem(state, action.product);

    case WISHLIST_REMOVE_ITEM:
        return state.filter((x) => x.id !== action.productId);
    case RECEIVE_WISHLIST:
            return action.products;
    case ADD_TO_WISHLIST:
            const productId = parseInt(action.product.id)
            if (state.findIndex(row => parseInt(row.productId) === parseInt(productId)) !== -1) {
                const list = state.reduce((cartAcc, row) => {
                    if (parseInt(row.productId) === parseInt(productId)) {
                        cartAcc.push({ ...row });
                    } else {
                        cartAcc.push(row);
                    }

                    return cartAcc;
                }, [])
                console.log(state,list);
                return [ ...state, ...list ];
            }
            console.log(state,action.product);
            return [ ...state,  action.product ]

    case REMOVE_FROM_WISHLIST:
            return {
                list: state.list.filter(row => row.id !== action.row_id)
            }
    case WISHLIST_BEGIN:
             return { ...state,
                wishlist_loading: true };
    case WISHLIST_END:
             return { ...state,
                wishlist_loading: false };
    default:
        return state;
    }
}
