// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import Indicator from '../header/Indicator';
import {
    Menu18x14Svg,
    LogoSmallSvg,
    Search20Svg,
    Cross20Svg,
    Heart20Svg,
    Cart20Svg,
} from '../../svg';
import { mobileMenuOpen, categoryMenuOpen } from '../../store/mobile-menu';
import Search  from '../header/Search';

class SearchHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchOpen: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchOpen } = this.state;

        if (searchOpen && searchOpen !== prevState.searchOpen && this.searchInputRef) {
            this.searchInputRef.focus();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    setSearchWrapperRef = (node) => {
        this.searchWrapperRef = node;
    };

    setSearchInputRef = (node) => {
        this.searchInputRef = node;
    };

    handleOutsideClick = (event) => {
        if (this.searchWrapperRef && !this.searchWrapperRef.contains(event.target)) {
            this.setState(() => ({ searchOpen: false }));
        }
    };

    handleOpenSearch = () => {
        this.setState(() => ({ searchOpen: true }));
    };

    handleCloseSearch = () => {
        this.setState(() => ({ searchOpen: false }));
    };

    handleSearchKeyDown = (event) => {
        if (event.which === 27) {
            this.setState(() => ({ searchOpen: false }));
        }
    };

    render() {
        const { openMobileMenu, openCategoryMenu, wishlist, cart } = this.props;
        const { searchOpen } = this.state;
        const searchClasses = classNames('mobile-header__search', {
            'mobile-header__search--opened': searchOpen,
        });

        return (
            <div className="mobile-header">
                <div className="mobile-header__panel">
                    <Search/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
// console.log(state);
 return {
    cart: state.cart,
    wishlist: state.wishlist,
}
};

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
    openCategoryMenu: categoryMenuOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SearchHeader);
