// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Img from 'react-image';
import { NextSvg } from '../../svg';


// application
import BlockHeader from '../shared/BlockHeader';


export default function BlockIndustries(props) {
    const { title, layout, industries } = props;

    const industriesList = industries.map((industry, index) => {
        const classes = `block-categories__item category-card category-card--layout--${layout}`;

       

        return (
            <div key={index} className={classes}>
                <div className="row category-card__body">
                    <div className="category-card__image">
                         <Img className="img-fluid-custom" src={`${process.env.PUBLIC_URL}/images/industries-thumb/${industry.value.replace(/[^A-Z0-9]+/ig, "").toLowerCase()}.png`}
                                        unloader={ (<img className="img-fluid-custom"
                                    src={`${process.env.PUBLIC_URL}/images/industries-thumb/default.png`}/>)}/>
                    </div>
                    <div className="category-card__content">
                        <div className="category-card__name">
                            <Link to={`/industry/${encodeURIComponent(industry.value)}`}>{industry.value}</Link>
                        </div>
                       
                        <div className="category-card__products">
                            <Link to={`/industry/${encodeURIComponent(industry.value)}`}>Products <span className="count-products">{industry.count}</span></Link>
                           
                        </div>
                    </div>
                    <NextSvg className="col-lg-2 category-card__arrow" />
                </div>
            </div>
        );
    });

    return (
        <div className={`block block-categories block-categories--layout--${layout}`}>
            <div className="container">
                <BlockHeader title={title} />

                <div className="block-categories__list">
                    {industriesList}
                </div>
            </div>
        </div>
    );
}

BlockIndustries.propTypes = {
    title: PropTypes.string.isRequired,
    industries: PropTypes.array,
    layout: PropTypes.oneOf(['classic', 'compact']),
};

BlockIndustries.defaultProps = {
    industries: [],
    layout: 'classic',
};
