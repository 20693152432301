import {
    FETCH_PRODUCTS_BEGIN,
FETCH_PRODUCTS_DONE,
RECEIVE_PRODUCTS,
RECEIVE_KEY_BASED_PRODUCTS,
CLEAR_PRODUCTS,
TOTAL_PRODUCTS_COUNT,
LOADED_PRODUCTS_COUNT,
INFINITE_LIMIT,
START_PRODUCTS_COUNT,
RECEIVE_PRODUCTS_FILTERS,
RESET_PRODUCTS_FILTERS,
FETCH_SINGLE_PRODUCT_BEGIN,
FETCH_SINGLE_PRODUCT_DONE,
FETCH_SINGLE_PRODUCT
    } from "./productsActionTypes";

const initialState = {
     all_products: [],
    featuredproducts:[],
    keybasedproducts:{},
    category_details: [],
      product_filters:{},
    ploading : true,
       product_details: {},
       currentStart:1,
    currentEnd:20,
    infiniteLimit:20,
};

export default function productsReducer(state = initialState, action) {
     switch (action.type) {
         case FETCH_PRODUCTS_BEGIN:
            return { ...state,
                ploading: true };
                case FETCH_PRODUCTS_DONE:
            return { ...state,
                ploading: false };
                case TOTAL_PRODUCTS_COUNT:
            return { ...state,
                totalCount: action.total };
                case LOADED_PRODUCTS_COUNT:
            return { ...state,
                loadedCount: action.count };
                case RECEIVE_PRODUCTS:
            return { ...state,
                all_products: action.products };
                case RECEIVE_KEY_BASED_PRODUCTS:
            return { ...state,
                keybasedproducts: { ...action.keyproducts} };
                 case RECEIVE_PRODUCTS_FILTERS:
            return { ...state,
                product_filters: action.filters };
            case RESET_PRODUCTS_FILTERS:
            return { ...state,
                product_filters: {} };
            case FETCH_SINGLE_PRODUCT_BEGIN:
            return { ...state,
                sloading: true };
        case FETCH_SINGLE_PRODUCT_DONE:
            return { ...state,
                sloading: false };
        case FETCH_SINGLE_PRODUCT:
            return { ...state,
                product_details: action.product };
        default:
            return state;
           
    }
}
