// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import { toast  } from 'react-toastify';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import InputNumber from './InputNumber';
import ProductImagesGallery from './ProductImagesGallery';
import Rating from './Rating';
import { cartAddItem,addToCart } from '../../store/cart';
import { submitNegotiateRequest } from '../../store/forms';

import SimpleReactValidator from 'simple-react-validator';
import { sendNotifyRequestNow } from '../../store/forms';

import { compareAddItem } from '../../store/compare';
import { Wishlist16Svg, Compare16Svg } from '../../svg';
import { wishlistAddItem,addToWishlist } from '../../store/wishlist';
import Modal from 'react-modal';
import { Cross12Svg } from '../../svg';

class Product extends Component {
    constructor(props) {
        super(props);
        console.log(this.props.product.quantity);
        this.state = {
            quantity: this.props.product.quantity,
            emailId:"",
            phoneNumber:"",
            showModal: false
        };

         this.validator = new SimpleReactValidator();
        this.setStateFromInput = this.setStateFromInput.bind(this);
        this.handleChangeQuantity = this.handleChangeQuantity.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

      setStateFromInput = (event) => {
        var obj = {};
        if(event.target.dataset.object){
             var key = event.target.dataset.object;
             var name = event.target.name;
             var value =  event.target.value;
                this.setState(state => {
                      state[key][name] = value;
                      return state;
                })
           }
           else{
               obj[event.target.name] = event.target.value;
               this.setState(obj);
        }
    }

   handleSubmit = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'NotifyStock',
      action: 'Notify product',
      label: 'Form Submit'
    });
    if (this.validator.allValid()) {
             this.props.dispatch(sendNotifyRequestNow(this.state))
              .then(response => {
                    this.setState(state => {
                      state['emailId']  = "";
                      state['phoneNumber']  = "";
                      return state;
                    });
                  toast.success('Notified successfully');
                })
                  .catch(err => {
                    console.log(err); 
                      toast.error(err.message);
                  });
       
    } else {
              this.validator.showMessages();
              this.forceUpdate();
    }
  }

    getQuantity() {
        const { quantity } = this.state;
        return quantity;
    }
    

    handleChangeQuantity = (quantity) => {
        let toastIdMin = 'mincart';
        if(quantity && quantity != 'min'  && quantity != 'max'){
            let newquantity = quantity;
            let productId = this.props.product.id;
            let cartExist = this.props.cart.items.findIndex((cartItem)=>{
                return cartItem.productId == productId;
            });
            console.log(cartExist);
            if(cartExist >= 0){
                console.log(this.props.cart.items[cartExist].quantity);
                newquantity = Number(this.props.cart.items[cartExist].quantity) + newquantity;
            }
             if(this.props.product && newquantity > this.props.product.quantityOrderMaximum){
                if (! toast.isActive(toastIdMin))
                toast.warn(<div><span>Cart quantity should be maximum {this.props.product.quantityOrderMaximum}. Item can be removed from cart using&nbsp;<button className="in-button"><i className="fa fa-times"></i></button>&nbsp;action</span></div>,{toastId: toastIdMin});
            } else if(this.props.product && newquantity < this.props.product.quantityOrderMinimum){
                if (! toast.isActive(toastIdMin))
                toast.warn(<div><span>Cart quantity should be minimum {this.props.product.quantityOrderMinimum}. Item can be removed from cart using&nbsp;<button className="in-button"><i className="fa fa-times"></i></button>&nbsp;action</span></div>,{toastId: toastIdMin});
            } else {
               this.setState({ quantity });
            }
        }
        else if(quantity == 'min'){
            toast.warn(<div><span>Cart quantity should be minimum {this.props.product.quantityOrderMinimum}. Item can be removed from cart using&nbsp;<button className="in-button"><i className="fa fa-times"></i></button>&nbsp;action</span></div>,{toastId: toastIdMin});
        }
        else if(quantity == 'max'){
            toast.warn(<div><span>Cart quantity should be maximum {this.props.product.quantityOrderMaximum}. Item can be removed from cart using&nbsp;<button className="in-button"><i className="fa fa-times"></i></button>&nbsp;action</span></div>,{toastId: toastIdMin});
        }
        
    };

    handleOpenModal(product) {
        this.setState(state => {
            state['showModal'] = true;
            state['subject'] = product.name;
            state['phone'] = '';
            state['price'] = '';
            return state;
        });
    }

    handleCloseModal() {
        this.setState(state => {
            state['showModal'] = false;
            state['subject'] = '';
            state['phone'] = '';
            state['price'] = '';
            return state;
        });
    }

    handleNegotiateSubmit= (event) => {
        event.preventDefault();
        ReactGA.event({
            category: 'Requests',
            action: 'Lot sale Negotiate',
            label: 'Form Submit'
        });
        if (this.validator.allValid()) {
            var payload = {
                lotName: this.state.subject,
                phone: this.state.phone,
                price: this.state.price
            }
            this.props.dispatch(submitNegotiateRequest(payload))
                .then(response => {
                    this.handleCloseModal();
                })
                .catch(err => {
                    console.log(err);
                });

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const {
            product,
            layout,
            wishlistAddItem,
            addToWishlist,
            compareAddItem,
            cartAddItem,
            addToCart,
            currentState,
            currency,
            login_status,
            loading,
            cart
        } = this.props;
        const { quantity } = this.state;
        let prices;
        if (product.discount && product[currency.discountPriceLabel]) {
            prices = (
                <React.Fragment>
                      <span className="product-card__new-price"><Currency value={Number(product[currency.discountPriceLabel])} uom={product.productuom}/></span>
                        {' '}
                       <span className="product-card__old-price-detail"><Currency value={Number(product[currency['productListPriceLabel']])}  /></span>
                </React.Fragment>
            );
        } else {
            
            prices = <Currency value={Number(product[currency['productListPriceLabel']])} uom={product.productuom}/>;
        }

        return (
            <React.Fragment>
                <Modal 
           isOpen={this.state.showModal}
           contentLabel="onRequestClose Example"
           shouldCloseOnOverlayClick={false}
           className="react-go-modal"
           overlayClassName="react-go-modal-Overlay"
        > <div className="static-products-view container">
                                <div className="row">
                                    <div className="col-12 col-lg-12">
                                    {this.state.type == 'enquiry' ?
                                        <h4 className="contact-us__header card-title float-section-left">Send Enquiry</h4>
                                        : 
                                        <h4 className="contact-us__header card-title float-section-left">Submit Negotiate Form</h4>
                                        }
                                        <button type="button"
                                             className="btn btn-light btn-sm btn-svg-icon float-section-right"
                                             onClick={this.handleCloseModal}>
                                            <Cross12Svg />
                                        </button>
                                        <form noValidate className="float-section-right">
                                            <div className="form-row">
                                            <div className="form-group col-md-12">
                                                   <input
                                                id="price"
                                                type="text"
                                                name="price"
                                                className="form-control "
                                                placeholder="Price in INR"
                                                value={this.state.price} onChange={this.setStateFromInput} />
                                                    {this.validator.message('price', this.state.price, 'required|numeric')}
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <input
                                                id="phone"
                                                type="text"
                                                name="phone"
                                                className="form-control"
                                                placeholder="Phone"
                                                value={this.state.phone} onChange={this.setStateFromInput} />
                                                    {this.validator.message('phone', this.state.phone, 'required|phone')}
                                                </div>
                                                 <div className="form-group col-md-12">
                                                <input
                                                id="lotname"
                                                type="text"
                                                name="lotname"
                                                className="form-control"
                                                placeholder="Lot Name"
                                                readOnly
                                                value={this.state.subject} onChange={this.setStateFromInput} />
                                                    {this.validator.message('subject', this.state.subject, 'required')}
                                            </div>
                                             <button disabled={this.props.loading} onClick={this.handleNegotiateSubmit.bind(this)} className={classNames('btn btn-secondary', {
                                            'btn-loading': this.props.loading,
                                        })}>Submit {this.props.loading}</button>&nbsp;&nbsp;&nbsp;
                                             <button disabled={this.props.loading} onClick={this.handleCloseModal} className={classNames('btn btn-secondary')}>Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
        </Modal>
            <div className={`product product--layout--${layout}`}>
                <div className="product__content">
                    <ProductImagesGallery layout={layout} defaultImage={product.productdefaultimage} title={product.productimagetitle} images={product.productimages} videos={product.productvideos} />
                  
                    <div className="product__info" id="product__info">
                        <div className="product__wishlist-compare">
                            <AsyncAction
                                action={() => addToWishlist(product,currentState)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Wishlist"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-light btn-svg-icon', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <Wishlist16Svg />
                                    </button>
                                )}
                            />
                            <AsyncAction
                                action={() => compareAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Compare"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-light btn-svg-icon', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <Compare16Svg />
                                    </button>
                                )}
                            />
                        </div>
                        <h1 className="product__name">{product.name}</h1>
                        <div className="product__attr">
                            <div className=" product__attr-legend">Age: {product.productAge}</div>
                        </div>
                        <div className="product__rating">
                            <div className="product__rating-stars">
                                <Rating value={product.avgrating} />
                            </div>
                            <div className="product__rating-legend">
                                <Link to="/">{`${product.avgratingcount ? product.avgratingcount : 'No'} Reviews`}</Link>
                                <span>/</span>
                                <label>Write A Review</label>
                            </div>
                        </div>
                        <div className="product__attr">
                    <div className=" product__attr-legend">UNSPSC:&nbsp;{product.unspsc}</div>
                  <div className=" product__attr-legend">HSN:&nbsp;{product.hsncode}</div>
                </div>
                        <div className="product__description">
                            {product.highlight}
                        </div>
                        <ul className="product__features">
                            <li>Speed: 750 RPM</li>
                            <li>Power Source: Cordless-Electric</li>
                            <li>Battery Cell Type: Lithium</li>
                            <li>Voltage: 20 Volts</li>
                            <li>Battery Capacity: 2 Ah</li>
                        </ul>
                        <ul className="product__meta">
                            <li className="product__meta-availability">
                                Availability:&nbsp;<span className="text-success">{product.productquantity}</span>
                                
                            </li>
                            <li>
                                Mfg:&nbsp;{product.manufacturer}
                            </li>
                            <li>SKU: {product.splSku}</li>
                             <li>Location: {product.source},&nbsp;{product.origincountry}</li>
                        </ul>
                    </div>

                    <div className="product__sidebar">
                        <div className="product__availability">
                            Availability:
                            {' '}
                            <span className="text-success">In Stock</span>
                        </div>
                         <div className="d-flex product-flex">
                        <div className="product__prices">
                            {prices}
                        </div>
                        {product.discountpercentage ? 
                        <div className="product-card__percentage product-card__percentage-detail">
                            <label>{product.discountpercentage}% OFF</label>
                        </div>
                              :
                            ''
                        }
                        {product.productquantity == 0 ? 
                        <div className="product-card__outstock product-card__outstock-detail">
                            <label>Out of stock</label>
                        </div>
                              :
                            ''
                        }
                        </div>

                        <div className="product__options">
                            <div className="form-group product__option">
                                <label htmlFor="product-quantity" className="product__option-label">Quantity</label>
                                <div className="product__actions">
                                    <div className="product__actions-item">
                                        <InputNumber
                                            id="product-quantity"
                                            aria-label="Quantity"
                                            className="product__quantity"
                                            size="lg"
                                            min={product.quantityOrderMinimum}
                                            max={product.quantityOrderMaximum}
                                            value={this.getQuantity()}
                                            onChange={this.handleChangeQuantity}
                                        />
                                    </div>
                                    <div className="product__actions-item product__actions-item--addtocart">
                                        <AsyncAction
                                            action={() => this.props.dispatch(addToCart(product,quantity,currentState))}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    onClick={run}
                                                    disabled={product.productquantity == 0}
                                                    className={classNames('btn btn-primary btn-lg', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    Add to cart
                                                </button>
                                            )}
                                        />
                                    </div>
                                    { login_status == 'success' ? 
                                    <div className="product__actions-item product__actions-item--wishlist">
                                        <AsyncAction
                                            action={() => this.props.dispatch(addToWishlist(product,currentState))}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    title="Wishlist"
                                                    onClick={run}
                                                    className={classNames('btn btn-secondary btn-svg-icon btn-lg', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    <Wishlist16Svg />
                                                </button>
                                            )}
                                        />
                                    </div>
                                    : '' }
                                    <div className="product__actions-item product__actions-item--compare">
                                        <AsyncAction
                                            action={() => this.props.dispatch(compareAddItem(product))}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    title="Compare"
                                                    onClick={run}
                                                    className={classNames('btn btn-secondary btn-svg-icon btn-lg', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    <Compare16Svg />
                                                </button>
                                            )}
                                        />
                                    </div>
                                    <div className="product__actions-item product__actions-item--addtocart">
                                                <button
                                                    type="button"
                                                    onClick={() => this.handleOpenModal(product)}
                                                    className={classNames('btn btn-primary btn-lg', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    Negotiate
                                                </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {product.productquantity == 0 ? 
                        <div className="product__notify">
                            <div className="form-group product__option">
                                <label htmlFor="product-quantity" className="product__option-label product__notify-label">Enter your email id below and we will let you know when its back in stock</label>
                                <div className="product__actions">
                                     <form onSubmit={this.handleSubmit.bind(this)} noValidate>
                                        <div className="form-group">
                                            <input
                                                id="notify-emailId"
                                                type="email"
                                                name="emailId"
                                                className="form-control"
                                                placeholder="Enter email"
                                                value={this.state.emailId} onChange={this.setStateFromInput} />
                                                    {this.validator.message('emailId', this.state.emailId, 'required|email')}
                                        </div>
                                         <div className="form-group">
                                            <input
                                                id="notify-mobile"
                                                type="text"
                                                name="phoneNumber"
                                                className="form-control"
                                                placeholder="Enter phone number"
                                                value={this.state.phoneNumber} onChange={this.setStateFromInput} />
                                                    {this.validator.message('phoneNumber', this.state.phoneNumber, 'required|phone')}
                                        </div>
                                        <div className="form-group d--btn-block">
                                        <button type="submit" className={classNames('btn btn-primary d-flex-btn', {
                                            'btn-loading': loading})}>
                                            Notify Me
                                        </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        : ''}
                    </div>

                  
                </div>
            </div>
            </React.Fragment>
        );
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
};

Product.defaultProps = {
    layout: 'standard',
};

const mapStateToProps = (state) => {
    return {
        login_status :  state.user.login_status,
        loading         : state.forms.nloading,
        currentState : state,
        currency: state.currency,
        cart: state.cart
    };
};


const mapDispatchToProps = dispatch => ({
    addToCart,
    cartAddItem,
    wishlistAddItem,
    addToWishlist,
    compareAddItem,
    sendNotifyRequestNow,
    dispatch
});

export default connect(
   mapStateToProps,
    mapDispatchToProps,
)(Product);
