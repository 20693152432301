import { toast } from 'react-toastify';
import * as types from "./industriesActionTypes";
import axios from 'axios';

const userdata = localStorage.getItem('gouserdata') ? JSON.parse(localStorage.getItem('gouserdata')) : {};

var axios_instance = '';

if(userdata.token)
  axios_instance = axios.create({headers: {authToken: userdata.token}});
else 
  axios_instance = axios.create();

export const fetchIndustriesBegin = () => ({
    type: types.FETCH_INDUSTRIES_BEGIN
});


export const fetchIndustriesDone = () => ({
    type: types.FETCH_INDUSTRIES_DONE
});

export const fetchFeaturedIndustriesBegin= () => ({
    type: types.FETCH_FEATUREDINDUSTRIES_BEGIN
});

export const fetchFeaturedIndustriesDone= () => ({
    type: types.FETCH_FEATUREDINDUSTRIES_DONE
});


export const receiveFeaturedIndustries = industries => ({
    type: types.RECEIVE_FEATURED_INDUSTRIES,
    industries
})

export const receiveIndustries = industries => ({
    type: types.RECEIVE_INDUSTRIES,
    industries
});

export const getIndustries = () => dispatch => {
   axios_instance.get(process.env.PUBLIC_ENDPOINT + "/industries?store=DEFAULT").then(response => response.data)
    .then((data) => {
      dispatch(receiveIndustries(data));
      return data;
     })
}

export const setFeaturedIndustries = () => dispatch => {
    dispatch(fetchFeaturedIndustriesBegin());
     axios_instance.get(process.env.PUBLIC_ENDPOINT + "/featuredindustries?store=DEFAULT").then(response => response.data)
    .then((data) => {
      // console.log(data);
      dispatch(fetchFeaturedIndustriesDone());
      dispatch(receiveFeaturedIndustries(data));
      return data;
     })
}
