import * as types from './filtersActionTypes'


const filtersReducerDefaultState = {
    manufacturers: [],  // "nike", "caprese", "lifestyle"
    categories:[],
    attrs:[],
    value: {min: 0, max: 1000}, // 
     sortBy: ""
};

const filtersReducer = (state = filtersReducerDefaultState, action) => {
    // // // console.log('Action Result');
    // // // console.log(action);
    switch (action.type) {
        case types.RESET_FILTERS : 
            return { ...state,
                manufacturers: [], 
                categories:[],
                attrs:[]
            };
            case types.RESUME_FILTERS : 
            return { ...state,
                manufacturer: action.manufacturer, 
                categories:action.categories,
                attrs:action.attrs
            };
        case types.FILTER_MANUFACTURERS:
            return {
                ...state,
                manufacturers: action.manufacturers
            };
        case types.FILTER_CATEGORIES:
            return {
                ...state,
                categories: action.categories
            };
             case types.FILTER_ATTRS:
            return {
                ...state,
                attrs: action.attrs
            };
            
        case types.FILTER_PRICE:
            return {
                ...state,
                value: {min: action.value.value.min, max: action.value.value.max }
            };
        case types.SORT_BY:
            return {
                ...state,
                sortBy: action.sort_by
            };
        default:
            return state;
    }
}

export default filtersReducer;