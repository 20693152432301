// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

import { trackPage } from '../../services';

function SitePrivacyPolicy(props) {

    trackPage(props.location.pathname+props.location.search);
    
    const breadcrumb = [
        { value: 'Home', lineage: '' },
        { value: 'Privacy Policy', lineage: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Privacy Policy - Goospares.com`}</title>
                <meta name="Description" content={`Privacy policy here describes how Goospares can take care of their customer's information when accessing the portal for the products.`}/>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title">Privacy Policy</h1>
                        </div>
                        <div className="document__content typography">
                            <p>
                                This privacy policy is an electronic record in the form of an electronic contract formed under the information technology act, 2000 and the rules made thereunder and the amended provisions pertaining to electronic documents / records in various statutes as amended by the information technology act, 2000. This privacy policy does not require any physical, electronic or digital signature.
                            </p>
                            <p>
                            This privacy policy is a legally binding document between you and goospares (both terms defined below). The terms of this privacy policy will be effective upon your account creation and will govern the relationship between you and goospares for your use of the website (defined below) This document is published and shall be construed in accordance with the provisions of the information technology (reasonable security practices and procedures and sensitive personal data of information) rules, 2011 under information technology act, 2000; that require publishing of the privacy policy for collection, use, storage and transfer of sensitive personal data or information.
                            </p>
                            <p>
                            Please read this privacy policy carefully. By using the website, you indicate that you understand, agree and consent to this privacy policy. If you do not agree with the terms of this privacy policy, please do not use this website. You hereby provide your unconditional consent or agreements to goospares as provided under section 43a and section 72a of information technology act, 2000.
                            </p>
                            <p>
                            By providing us your Information or by making use of the facilities provided by the Website, You hereby consent to the collection, storage, processing and transfer of any or all of Your Personal Information and Non-Personal Information by Goospares as specified under this Privacy Policy. You further agree that such collection, use, storage and transfer of Your Information shall not cause any loss or wrongful gain to you or any other person.
                            </p>
                            <p>
                            CODA Technology Solutions Private Limited and its subsidiaries and affiliates and Associate Companies (individually and/ or collectively, “Goospares”) is/are concerned about the privacy of the data and information of users (including sellers and buyers whether registered or non-registered) accessing, offering, selling or purchasing materials on Goospares websites, mobile sites or mobile applications (“Website”) on the Website and otherwise doing business with Goospares. The terms “We” / “Us” / “Our” individually and collectively refer to each entity being part of the definition of Goospares and the terms “You” /“Your” / “Yourself” refer to the users.
                            </p>
                            <p>
                            This Privacy Policy is a contract between you and Goospares whose Website You use or access or you otherwise deal with. This Privacy Policy shall be read together with the respective terms of use or other terms and condition of Goospares and its respective Website or nature of business of the Website.
                            </p>


                            <hr />

                          

                            <h6>Goospares has provided this Privacy Policy to familiarise You with:</h6>
                            <ul>
                                <li>
                                    The type of data or information that you share with or provide to Goospares and that Goospares collects from you;
                                </li>
                                <li>
                                   The type of data or information that you share with or provide to Goospares and that Goospares collects from you;
                                </li>
                                <li>
							Information collected and storage of such Information:
                                </li>
                                <li>
                                    The “Information” (which shall also include data) provided by you to Goospares or collected from You by Goospares may consist of “Personal Information” and “Non-Personal Information”.
                                </li>
                                <li>
                                    Personal Information is Information collected that can be used to uniquely identify or contact you. Personal Information for the purposes of this Privacy Policy shall include, but not be limited to: Goospares requires following information from you which either name in below from which you entered. Goospares requires below Legal proof for validating you. To avoid fraud activities in portal.
                                </li>
                                 <li>
                                User Name
                                </li>
                                <li>
								Company/Dealer Name
								</li>
                                <li>
								Contact Person Name
								</li>
                                <li>
								Address
								</li>
                                <li>
								Email ID
								</li>
                                <li>
								Mobile Number
								</li>
                                <li>
								Address/Contact Person Proof: Passport, Voter ID, Driving License, PAN Card, Adhaar/Citizenship Card, Legal documents
								</li>
                                <li>
								Companies ID Proof: Which may be either your companies PAN Card, TIN Number, Property Tax Receipt, Insurance Card, Income Tax Assessment Order, Legal Documents Goospares password access is been automated computerised per program where your email ID will validate and allowed to access. In case of password change/ Password creation. All your information you enter will computerised in form others cannot access it (including Goospares)
								</li>
                                <li>
								Password Creation/Change:
								</li>
                                <li>
								Information regarding your transactions on the Website, (including sales or purchase history)
								</li>
                                <li>
								Any other items of ‘sensitive personal data or information’ as such term is defined under the Information Technology (Reasonable Security Practices And Procedures And Sensitive Personal Data Of Information) Rules, 2011 enacted under the Information Technology Act, 2000;
								</li>
                                <li>
								Identification code of your communication device which You use to access the Goospares Website,
								</li>
                                <li>
								Any other Information that you provide during your registration process, if any, on the Website. Such Personal Information may be collected in various ways including during the course of You:
								<ul>
								<li>registering as a buyer on the Website,</li>
								<li>registering as a seller on the Website,</li>
								</ul>
								</li>
                                <li>
								Availing certain services offered on the Website. Such instances include but are not limited to making an offer for sale, online purchase, participating in any online survey or contest, communicating with Goospares’s customer service by phone, email or otherwise or posting user reviews on the items available on the Website,
								</li>
                                <li>
								Non-Personal Information: Goospares may also collect information other than Personal Information from you through the Website when you visit and / or use the Website. Such information may be stored in server logs. This Non-Personal Information would not assist Goospares to identify you personally.
								</li>
                                <li>
								Your geographic location,
								</li>
                                <li>
								Details of Your telecom service provider or internet service provider,
								</li>
                                <li>
								The type of browser (Internet Explorer, Firefox, Opera, Google Chrome etc.),
								</li>
                                <li>
								The operating system of Your system, device and the Website You last visited before visiting the Website,
								</li>
                                <li>
								The duration of your stay on the Website is also stored in the session along with the date and time of your access, Non-Personal Information is collected through various ways such through the use of cookies. Goospares may store temporary or permanent ‘cookies’ on your computer. You can erase or choose to block these cookies from your computer. You can configure your computer’s browser to alert you when we attempt to send you a cookie with an option to accept or refuse the cookie. If you have turned cookies off, you may be prevented from using certain features of the Website.
								</li>
								 </ul>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePrivacyPolicy;
