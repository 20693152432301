// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'

// application
import { ArrowRoundedLeft6x9Svg } from '../../svg';
import { Check9x7Svg } from '../../svg';
import { withRouter } from "react-router";
import {filterCategory} from '../../store/filters'

import classNames from 'classnames';


function FilterCategoriesOptions(props) {
    const { location,values,filters,redirectFilters,filterCategory } = props;
    let clickCategoryHandle;
    clickCategoryHandle = (event, categories,location) => {
        var clickedValue = encodeURIComponent(event.target.value);
        var index = categories.indexOf(clickedValue);
        if (event.target.checked)
            categories.push(clickedValue); // push in array checked value
        else
            categories.splice(index, 1); // removed in array unchecked value
        var categoryFilterJoin = categories.join('|');
        redirectFilters(categoryFilterJoin,'categories',location);
        filterCategory(categories);
    }

    const categoriesList = values.map((item) => {
        let count;
        
        if (item.count) {
            count = <span className="filter-list__counter">{item.count}</span>;
        }
        // if (value.type === 'parent') {
        //     arrow = <ArrowRoundedLeft6x9Svg className="filter-categories__arrow" />;
        // }

        return (
           <label
                key={item.name}
                className={classNames('filter-list__item', {
                    'filter-list__item--disabled': item.disabled,
                })}
            >
                <span className="filter-list__input input-check">
                    <span className="input-check__body">
                        <input className="input-check__input" onClick={(e) => clickCategoryHandle(e,filters.categories,location)}  value={item.name} type="checkbox" defaultChecked={item.checked} disabled={item.disabled} />
                        <span className="input-check__box" />
                        <Check9x7Svg className="input-check__icon" />
                    </span>
                </span>
                <span className="filter-list__title">{item.name}</span>
                {count}
            </label>
        );
    });

    return (
        <div className="filter-categories">
            <ul className="filter-categories__list">
                {categoriesList}
            </ul>
        </div>
    );
}

FilterCategoriesOptions.propTypes = {
    values: PropTypes.array,
};

const mapStateToProps = (state) => {
// console.log(state);
    return {
        filters: state.filters
    }
}

export default connect(
    mapStateToProps,
    { filterCategory }
)(withRouter(FilterCategoriesOptions));














