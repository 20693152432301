import { MOBILE_MENU_CLOSE, MOBILE_MENU_OPEN, MOBILE_CATEGORY_MENU_CLOSE, MOBILE_CATEGORY_MENU_OPEN } from './mobileMenuActionTypes';


export function mobileMenuOpen() {
    return { type: MOBILE_MENU_OPEN };
}

export function mobileMenuClose() {
    return { type: MOBILE_MENU_CLOSE };
}

export function categoryMenuOpen() {
    return { type: MOBILE_CATEGORY_MENU_OPEN };
}

export function categoryMenuClose() {
    return { type: MOBILE_CATEGORY_MENU_CLOSE };
}
