import React, {Component} from 'react';

import Img from 'react-image';
import {Link} from 'react-router-dom'
import { withRouter } from "react-router";
import { Redirect } from 'react-router-dom'
import Currency from '../shared/Currency';


class OrderSuccess extends Component {

    componentWillMount() {
        if(!this.props.location.state) {
           // this.props.history.push({ pathname: '/dashboard?active=orders'});
        }
    }


     renderItems(items) {
        return items.map((item) => {
            let image;
            let options;

           
                image = <Link to={`/product/${item.productId}`}><Img className="img-fluid-custom" src={`${process.env.PUBLIC_ENDPOINT}${process.env.PRODUCT_IMG_PATH}${item.image?
                                        item.image : item.image}`} unloader={ (<img
                                    src={`${process.env.PUBLIC_URL}/images/default.png`}
                                    className="img-fluid-custom"/>)}/></Link>;
           

            if (item.options && item.options.length > 0) {
                options = (
                    <ul className="cart-table__options">
                        {item.options.map((option, index) => (
                            <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                        ))}
                    </ul>
                );
            }

         

            return (
                <tr key={item.id} className="cart-table__row">
                    <td className="cart-table__column cart-table__column--image">
                        {image}
                    </td>
                    <td className="cart-table__column cart-table__column--product">
                        <Link to={`/product/${item.productId}`} className="cart-table__product-name">
                            {item.productName}
                        </Link>
                        {options}
                    </td>
                    <td className="cart-table__column cart-table__column--price" data-title="Price">
                        <Currency value={Number(item.price)} />
                    </td>
                    <td className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                         {item.quantity}
                    </td>
                    <td className="cart-table__column cart-table__column--total" data-title="Total">
                        <Currency value={Number(item.subTotal)} />
                    </td>
                   
                </tr>
            );
        });
    }

    render (){
        console.log(this.props.location.state);
        const {payment,orderId, items, orderTotal,billing,delivery,companyName} = this.props.location.state ? this.props.location.state : '' ;
        
        return (
            <div>
               { payment ?
                 <div className="block">
                <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="success-text">
                                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                                    <h3>Thank you</h3>
                                    <p>Your Order has been received. We will contact you within the next business day.</p>
                                    <p className="order-id-line">Order ID: {orderId}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :  '' }
                { payment ?
                 <div className="block">
                <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="product-order-success">
                                    <h5>your order detail</h5>
                                      <table className="cart__table cart-table">
                                        <thead className="cart-table__head">
                                            <tr className="cart-table__row">
                                                <th className="cart-table__column cart-table__column--image">Image</th>
                                                <th className="cart-table__column cart-table__column--product">Product</th>
                                                <th className="cart-table__column cart-table__column--price">Price</th>
                                                <th className="cart-table__column cart-table__column--quantity">Quantity</th>
                                                <th className="cart-table__column cart-table__column--total">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody className="cart-table__body">
                                            {this.renderItems(items)}
                                        </tbody>
                                    </table>
                                    
                                    <div className="final-total">
                                         <div className="cart-buttons">
                                        <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-primary">continue shopping</Link>
                                </div>
                                        <h5>total <span><Currency value={Number(orderTotal)} /></span></h5>
                                    </div>
                                </div>
                               
                            </div>
                            <div className="col-lg-5">
                                <div className="order-success-address">
                                  
                                     <h5>billing address</h5>
                                        <ul className="order-detail">
                                            <li>{companyName}</li>
                                            <li>{billing.address}</li>
                                            <li>{billing.city}, {billing.country}</li>
                                            <li>{billing.postalCode}</li>
                                            <li>Contact No. {billing.phoneNumber}</li>
                                        </ul>
                                        <h5>shipping address</h5>
                                        <ul className="order-detail">
                                            <li>{companyName}</li>
                                            <li>{delivery.address}</li>
                                            <li>{delivery.city}, {delivery.country}</li>
                                            <li>{delivery.postalCode}</li>
                                            <li>Contact No. {delivery.phoneNumber}</li>
                                        </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : <Redirect to="/account/orders"/> }
            </div>
        )
    }
}

export default withRouter(OrderSuccess)




