// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Img from 'react-image';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import Indicator from './Indicator';
import { ShopCart24Svg, Cross10Svg } from '../../svg';
import { cartRemoveItem,removeFromCartState  } from '../../store/cart';


function IndicatorCart(props) {
    const { cart, extraLines, cartRemoveItem,label, removeFromCartState,currency} = props;
    let dropdown;
    let totals;

    if (extraLines.length > 0) {
        const extraLines = cart.extraLines.map((extraLine, index) => (
            <tr key={index}>
                <th>{extraLine.title}</th>
                <td><Currency value={Number(extraLine.price)} /></td>
            </tr>
        ));

        totals = (
            <React.Fragment>
                <tr>
                    <th>Subtotal</th>
                    <td><Currency value={Number(cart.subtotal)} /></td>
                </tr>
                {extraLines}
            </React.Fragment>
        );
    }

    const items = cart.items.map((item) => {
        let options;
        let image;

        if (item.options) {
            options = (
                <ul className="dropcart__product-options">
                    {item.options.map((option, index) => (
                        <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                    ))}
                </ul>
            );
        }

       
            image = (
                <div className="dropcart__product-image">
                    <Link to={`/product/${item.productId}/${item.name}`}>
                     <Img src={process.env.PUBLIC_ENDPOINT+process.env.PRODUCT_IMG_PATH+(item.image ? item.image : 'empty')}
                                unloader={ (<img
                                    src={`${process.env.PUBLIC_URL}/images/default.png`}
                                    className="img-fluid-custom"/>)}/>
                       
                    </Link>
                </div>
            );
        

        const removeButton = (
            <AsyncAction
                action={() => removeFromCartState(item)}
                render={({ run, loading }) => {
                    const classes = classNames('dropcart__product-remove btn btn-light btn-sm btn-svg-icon', {
                        'btn-loading': loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />
                        </button>
                    );
                }}
            />
        );

        return (
            <div key={item.id} className="dropcart__product">
                {image}
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        <Link to={`/product/${item.productId}/${item.name}`}>{item.name}</Link>
                    </div>
                    {options}
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{item.quantity}</span>
                        {' × '}
                        <span className="dropcart__product-price"><Currency value={Number(item[currency.cartProductSubTotalLabel])} uom={item.productuom}/></span>
                    </div>
                </div>
                {removeButton}
            </div>
        );
    });

    if (cart.quantity) {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__products-list">
                    {items}
                </div>

                <div className="dropcart__totals">
                    <table>
                        <tbody>
                            {totals}
                            <tr>
                                <th>SubTotal</th>
                                <td><Currency value={Number(cart[currency.cartAllSubtotalLabel])} /></td>
                            </tr>
                            <tr>
                                <th>Total Amount</th>
                                <td><Currency value={Number(cart[currency.cartAllTotalLabel])} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="dropcart__buttons">
                    <Link className="btn btn-secondary" to="/cart">View Cart</Link>
                    <Link className="btn btn-primary" to="/checkout">Checkout</Link>
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">
                    Your shopping cart is empty!
                </div>
            </div>
        );
    }

    return (
        <Indicator url="/cart" dropdown={dropdown} value={cart.quantity} icon={<ShopCart24Svg />} label={label} />
    );
}
const mapStateToProps = (state) => ({
    cart: state.cart,
    extraLines : state.cart.extraLines,
    wishlist: state.wishlist,
    currency: state.currency
});


const mapDispatchToProps = {
    cartRemoveItem,removeFromCartState
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
