// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

import { trackPage } from '../../services';

function SiteDisclaimer(props) {

    trackPage(props.location.pathname+props.location.search);
    
    const breadcrumb = [
        { value: 'Home', lineage: '' },
        { value: 'Disclaimer', lineage: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Disclaimer - ${theme.name}`}</title>
                <meta name="Description" content={`Goospares Disclaimer here covers all liabilities. More information about website disclaimer. Want to contact us? Email or Contact Or Visit us via www.goospares.com`}/>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title">Disclaimer</h1>
                        </div>
                        <div className="document__content typography">
                            <p>
                                Goospares will not be held liable for any unethical actions done by the users. The information is provided by goospares.com and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability,suitability or availability with respect to the website or the information, Items, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.GOOSPARES, will never e-mail or call you to disclose or verify any of your password s (Credit card/ Debit card/Netbanking etc). If you are contacted or receive an unsolicited e-mail which asks you any such information, disregard the request and report it to us at info@goospares.com. If you receive an e-mail asking you for personal information or directing you to a site other than goospares.com, it could be a “spoof” or “phishing” e-mail and should be considered as fraudulent.
                            </p>
                            <p>
                            Some phishing e-mails contain links to websites that contain the word “Goospares” somewhere in the URL but will take you to a completely different website. If you hover over the link you often can see the underlying URL which will be in a different format to those linked to within the GOOSPARES site. If you click through on a phishing e-mail and are taken to a page looking like “Your Account” or anything that asks you to verify or change your personal details, you should consider it as fraudulent.
                            </p>
                            <hr />
                            <h6>Following is an example from a real phishing scheme:</h6>
                            <p>
                               Dear valued customer, it has come to our attention that your account information needs to be updated due to inactive member, frauds, and spoof reports. Failure to update your records will result in account deletion. Please follow the link below to confirm you data. Such type of e-mails should be immediately discarded and make sure you do not click on the links given by them.
                            </p>
                            
                            <h6>Further,</h6>
                            <ul>
                                <li>
                                Goospares is a platforms to connect sellers and buyers with OEM materials, MRO Spares with high quality
								</li>
								<li>
								Goospares strives to represent the product online as accurately as possible
								</li>
								<li>
								The image of the items may deviate from actual items as they appear in online presentation
								</li>
								<li>
								When it comes to customer satisfaction we never compromise. We provide 100% customer satisfaction
								</li>
								<li>
								Goospares offer a wide range of genuine items to valued customers
								</li>
								<li>
								Goospares can provide logistics support based on value of goods purchased
								</li>
								<li>
								Security is a top priority at Goospares and we make every effort to ensure that our transaction process is safe and that your personal information is secure
								</li>
								<li>
								Goospares reserves the right to make additions, deletions or modification to the contents or items on the service at any time without prior notice
								</li>
								<li>
								Every effort is made to keep the website up and running smoothly. However, goospares.com takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control
								</li>
                            </ul>
                            <p>
                            Certain state laws do not allow limitations on implied warranties or the exclusion or limitation of certain damages. If these laws apply to you, some or all the above disclaimers, exclusions, or limitations may not apply to you, and you might have additional rights.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SiteDisclaimer;
