import { toast } from 'react-toastify';
import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM,ADD_TO_WISHLIST,REMOVE_FROM_WISHLIST,RECEIVE_WISHLIST } from './wishlistActionTypes';

import axios from 'axios';

import queryString from 'query-string'



const userdata = localStorage.getItem('gouserdata') ? JSON.parse(localStorage.getItem('gouserdata')) : {};

var axios_instance = '';

if(userdata.token)
  axios_instance = axios.create({headers: {authToken: userdata.token}});
else 
  axios_instance = axios.create();



export function wishlistAddItemSuccess(product) {
    toast.success(`Product "${product.name}" added to wish list!`);
}

export function wishlistRemoveItemSuccess(productId) {
    return {
        type: WISHLIST_REMOVE_ITEM,
        productId,
    };
}

export function wishlistAddItem(product) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(wishlistAddItemSuccess(product));
                resolve();
            }, 2000);
        })
    );
}

export const wishlistRemoveItem = (row) => {
    return (dispatch) => (
        new Promise((resolve) => {
            axios_instance.delete(process.env.PUBLIC_ENDPOINT + '/wishList/'+row.id)
             .then(response => response.data)
            .then((data) => {
                dispatch(wishlistRemoveItemSuccess(row.id));
                resolve();
            });
        })
    );
}



//it seems that I should probably use this as the basis for "Wishlist"
export const addToWishlist = (product,state) => {
     return (dispatch) => (
        new Promise((resolve) => {
   if (state.wishlist.findIndex(row => parseInt(row.productId) === parseInt(product.id)) === -1) {
    axios_instance.post(process.env.PUBLIC_ENDPOINT + '/addtowishlist?productId='+product.id).then(response => response.data)
    .then((data) => {
        dispatch(addToWishlistUnsafe(data));
        resolve();
    });
  }
  else
  {
     toast.warn("product already available in your save list");
     resolve();
  }
    })
   );

}

export const addToWishlistUnsafe = (product) => {
     toast.success(`Product "${product.name}" added to wish list!`);
return {
    type: ADD_TO_WISHLIST,
    product
};
}

export const receiveWishlist = (products) => ({
    type: RECEIVE_WISHLIST,
    products
});



export const loadWishlist = (data) => (dispatch) => {
     axios_instance.get(process.env.PUBLIC_ENDPOINT + "/wishlist")
     .then(response => response.data)
      .then((data) => {
        dispatch(receiveWishlist(data));
        return data;
     });
};


