// react
import React from 'react';


function ProductTabDescription(props) {
    const { product } = props;
    return (

        <div className="typography">
            <h3>Product Full Description</h3>
            <div className="product-card__description" dangerouslySetInnerHTML={{ __html: product.description }} />
        </div>
    );
}

export default ProductTabDescription;
