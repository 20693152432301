// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

import { trackPage } from '../../services';


const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 379,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function SitePageAboutUs(props) {

    trackPage(props.location.pathname+props.location.search);
    


     const breadcrumb = [
        { value: 'Home', lineage: '' },
        { value: 'About Us', lineage: '' },
    ];

    return (
        <div className="block about-us">
            <Helmet>
                <title>{`About Goospares - Online Shopping Platform for Industrial Spares in India`}</title>
                <meta name="Description" content={`Goospares - India's 1st  Online Store for Industrial Spares, providing solution to businesses having excess, unused, slow moving and non-moving maintenance spares.`}/>
            </Helmet>
             <PageHeader breadcrumb={breadcrumb} />
            <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title">About Us</h1>
                        </div>
                        <div className="document__content typography">
                            <p>
                                Goospares is a new business which will use the web to bring the sellers and buyers of unused, slow moving and non-moving inventory (Inventory in the form of finished goods, idle plant and equipment, raw materials & work in progress and MRO spare parts) that are held as stock for long time by the business.
                            </p>
                            <p>
                                If a certain technology is outdated in one part of the world, the same needn’t be outdated in every part of the world. Similarly, if one product doesn’t have demand in one part of the world, the same needn’t be the case all over the world. Both technology and demand are geography specific. Goospares uses the above concept and attempts to provide a solution to the business having excess, unused, slow moving and non-moving inventory.
                            </p>
                            <p>
                                Goospares would ensure that slow moving and non-moving inventory find an avenue to move. The global presence of Goospares along with the subject matter expertise which it has got across multiple industrial verticals would ensure that it gives life to dead inventory.
                            </p>

                               <hr />
                            <h3>Identification of Business need</h3>
                            <p>
                            CODA Technology Solutions Pvt Ltd(CODASOL) headquartered in Chennai-INDIA, is a provider of IT and Engineering services to small, medium and large enterprises. CODASOL Industrial Solutions, one of the premier service applications of CODASOL is designed to enable factories attain excellence in plant maintenance. Plant maintenance refers to the methods, strategies, and practices used to achieve warehouse optimization.
                            </p>
                            <p>
                            It was during the provision of this service that CODASOL noticed the problem of excess, unused inventory (finished goods, under-utilized/unused/idle plant and equipment, surplus materials, non-moving products, raw materials and MRO spare parts) at majority of the Business Units. These Business Units were selling the inventories each year as scrap in an effort to clear their shelf space. Also, owing to rapid changes in technology, certain industrial parts used in the initial device configuration do not find an application in the newer versions of the devices. In some cases, even the Industrial device becomes obsolete due to adoption of another device. However, the old device or the spares used in them still find an application (in the same business or in another business) in other parts of the country as well as the globe.
                            </p>
                            <p>
                            CODASOL aimed at providing a solution to this problem through a platform called GOOSPARES.
                            </p>
                             <hr />
                              <h3>Benefits from Goospares</h3>
                            <ul>
                                <li>
                                    Sellers realizing a fair value for the inventory
                                </li>
                                <li>
                                    Buyers procuring quality goods at competitive prices
                                </li>
                                <li>
                                    Value addition in scarce resources.
                                </li>
                                <li>
                                    Environmental protection.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default SitePageAboutUs;
