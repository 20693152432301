// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

import { trackPage } from '../../services';

function SitePageFaq(props) {

    trackPage(props.location.pathname+props.location.search);
    

    const breadcrumb = [
        { value: 'Home', lineage: '' },
        { value: 'Frequently Asked Questions', lineage: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Goospares - FAQs`}</title>
                <meta name="Description" content={`Check out FAQs to get answers to your most commonly asked questions about buying and selling of industrial spares.`}/>

            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block faq">
                <div className="container">
                    <div className="faq__section">

                        <div className="faq__section-body">
                        <div className="document__header">
                            <h1 className="document__title">Frequently Asked Questions</h1>
                        </div>
                            <div className="row">
                                <div className="faq__section-column col-12 col-lg-6">
                                    <div className="typography">
                                    <ul>
                                    <li>
                                        <h6>How does Goospares help me manage my surplus inventory more effectively?</h6>

                                        <p>
                                            Goospares has expertise team for market analyses about each and every product posted in Goospares portal
                                        </p>
                                        </li>
                                        <li>
                                        <h6>How do I contact Goospares?</h6>

                                        <p>
                                            Email: <strong> info@goospares.com </strong>
                                        </p>
                                         </li>
                                        <li>
                                        <h6>Will GooSpares store my product in its warehouse?</h6>

                                        <p>
                                            No GooSpares won’t buy any products it’s a portal for connecting buyer and seller
                                        </p>
                                         </li>
                                        <li>
                                        <h6>Will Goospares support Logistics?</h6>

                                        <p>
                                            Yes, Goospares provide Logistics support
                                        </p>
                                         </li>
                                        <li>
                                        <h6>What is my cost to list & market my product?</h6>

                                        <p>
                                            Free cost for listing, marketing and identifying buyer/seller
                                        </p>
                                         </li>
                                        <li>
                                        <h6>What does Goospares receive for selling my products?</h6>

                                        <p>
                                            Goospares receive a commission for each product sold
                                        </p>
                                        </li>
                                        <li>
                                        <h6>What kind of product can I put for selling?</h6>

                                        <p>
                                            Goospares is a e-commerce platform for buying/selling engineering goods under following categories of Mechanical, Electrical, Instrumentation, Equipment's & Machinery, Material Handling Equipment's, Consumable items, etc.
                                        </p>
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="faq__section-column col-12 col-lg-6">
                                    <div className="typography">
                                        <ul>
                                    <li>
                                        <h6>Do I have to register in GooSpares to sell/buy?</h6>

                                        <p>
                                            Yes every seller/buyer are requested to register
                                        </p>
                                          </li>
                                        <li>
                                        <h6>Why does GooSpares need registration information?</h6>

                                        <p>
                                            Registration is an important step for us to get to know the buyers on the system. Only verified buyers are allowed on the system. We need just basic information of their business and company, with supporting documents, to authenticate them on our portal. Once approved, you will have full access to the platform.
                                        </p>
                                          </li>
                                        <li>
                                        <h6>How do I post a product on Goospares?</h6>

                                        <p>
                                            You need to register on Goospares in order to post any product.
                                        </p>
                                          </li>
                                        <li>
                                        <h6>Will Goospares Work on my product details if needed?</h6>

                                        <p>
                                            Yes, Goospares has its cataloguing team
                                        </p>
                                          </li>
                                        <li>
                                        <h6>Is there any limit to post my product on Goospares once after am registering?</h6>

                                        <p>
                                            No
                                        </p>
                                          </li>
                                        <li>
                                        <h6>How to register With Goospares?</h6>

                                        <p>
                                           Basic information can be filled in our New user tab, For further assistance contact. Email: <strong>info@goospares.com</strong>
                                        </p>
                                         </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageFaq;
