import * as types from "./lotsActionTypes";
import axios from 'axios';

const userdata = localStorage.getItem('gouserdata') ? JSON.parse(localStorage.getItem('gouserdata')) : {};

var axios_instance = '';

if(userdata.token)
  axios_instance = axios.create({headers: {authToken: userdata.token}});
else 
  axios_instance = axios.create();

export const fetchCategoriesBegin = () => ({
    type: types.FETCH_LOT_CATEGORIES_BEGIN
});


export const fetchCategoriesDone = () => ({
    type: types.FETCH_LOT_CATEGORIES_DONE
});

export const receiveLots = lots => ({
  type: types.RECEIVE_LOTS,
  lots
})

export const receiveLotCategories = categories => ({
  type: types.RECEIVE_LOT_CATEGORIES,
  categories
})

export const retrieveLotSaleCategories = () => { 
    return  (dispatch) => {
      dispatch(fetchCategoriesBegin());
        return new Promise((resolve, reject) => {
          axios_instance.get(process.env.PUBLIC_ENDPOINT + "/lotsale/lotsalecategorylist?store=DEFAULT").then(response => response.data)
          .then((data) => {
                  dispatch(fetchCategoriesDone());
                  dispatch(receiveLotCategories(data));
                  return resolve(data);
          })
          .catch(function (error) {
            dispatch(fetchCategoriesDone());
            return reject('failed');
            // console.log(error);
          }); 
        });
    };
  }

  export const emptyLots = () => ({
    type: types.EMPTY_LOTS
  })

  export const retrieveLots = (parentid) => {
    return  (dispatch) => {
      dispatch(fetchCategoriesBegin());
        return new Promise((resolve, reject) => {
          axios_instance.get(process.env.PUBLIC_ENDPOINT + "/lotsale/lotsalesubcategorylist/"+parentid+"?store=DEFAULT").then(response => response.data)
          .then((data) => {
                  dispatch(fetchCategoriesDone());
                  dispatch(receiveLots(data));
                  return resolve(data);
          })
          .catch(function (error) {
            dispatch(fetchCategoriesDone());
            return reject('failed');
            // console.log(error);
          }); 
        });
    };
  }
