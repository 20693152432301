import { toast } from 'react-toastify';
import * as types from "./categoriesActionTypes";
import axios from 'axios';

const userdata = localStorage.getItem('gouserdata') ? JSON.parse(localStorage.getItem('gouserdata')) : {};

var axios_instance = '';

if(userdata.token)
  axios_instance = axios.create({headers: {authToken: userdata.token}});
else 
  axios_instance = axios.create();

export const fetchCategoriesBegin = () => ({
    type: types.FETCH_CATEGORIES_BEGIN
});


export const fetchCategoriesDone = () => ({
    type: types.FETCH_CATEGORIES_DONE
});



export const receiveCategories = categories => ({
    type: types.RECEIVE_CATEGORIES,
    categories
})

export const receiveFeaturedCategories = categories => ({
    type: types.RECEIVE_FEATURED_CATEGORIES,
    categories
})

export const receiveTopCategories = categories => ({
    type: types.RECEIVE_TOP_CATEGORIES,
    categories
})

export const receiveRootCategory = rootCategoryMenu => ({
    type: types.ROOT_CATEGORY,
    rootCategoryMenu
});

export const getRootCategory = () => dispatch => {
    dispatch(fetchCategoriesBegin());
     axios_instance.get(process.env.PUBLIC_ENDPOINT + "/categories?store=DEFAULT").then(response => response.data)
    .then((data) => {
      // console.log(data);
      dispatch(fetchCategoriesDone());
      dispatch(receiveRootCategory(data));
      return data;
     })
}
