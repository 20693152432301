// react
import React, {Component} from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// application
import FiltersSidebar from './FiltersSidebar';
import PageHeader from '../shared/PageHeader';
import ProductsView from './ProductsView';
import { sidebarClose } from '../../store/sidebar';
import { Link } from 'react-router-dom';
import {
    Quickview16Svg
} from '../../svg';
import classNames from 'classnames';
// data stubs
import products from '../../data/shopProducts';

import {getSubCategoryFromParent,getBreadcrumbItems,checkIsIndustry, checkIsLot } from '../../services';
import queryString from 'query-string';
import { getVisibleProducts, getLotBreadcrumb } from '../../services';

import theme from '../../data/theme';

class ClassifiedProducts extends Component {
	constructor (props) {
        super (props);
        this.redirectFilters = this.redirectFilters.bind(this);
    }

      redirectFilters(filtervalues,filtertitle,location){
        var searchInspect = location.search.indexOf(filtertitle) >= 0;
        var queries = queryString.parse(location.search);
        var modFilter = '';
        if(searchInspect){
            var modSplitSearch = [];
            var splitSearch = queries.filter ? queries.filter.split('::') : [];
             splitSearch.map(
                (val,index) => {
                    if(val.indexOf(filtertitle) >= 0) {
                        if(filtervalues)
                            modSplitSearch.push(filtertitle+':'+filtervalues);
                    }
                    else
                        modSplitSearch.push(val);
                    if(index == splitSearch.length - 1)
                        modFilter = modSplitSearch.join('::');
                });
        }
        else
        {
            var splitSearch = queries.filter ? queries.filter.split('::') : [];
            splitSearch.push(filtertitle+':'+filtervalues);
            modFilter = splitSearch.join('::'); 
        }
        let mainQuery = '';
        let mainQueryValue = '';
        if(queries.query){
            mainQuery = '?query=';
            mainQueryValue = encodeURIComponent(queries.query);
        }
        if(queries.manufacturer){
            mainQuery = '?manufacturer=';
            mainQueryValue = encodeURIComponent(queries.manufacturer);
        }
        if(queries.industry){
            mainQuery = '?industry=';
            mainQueryValue = encodeURIComponent(queries.industry);
        }
        this.props.history.push({
              pathname: location.search.pathname,
              search: (mainQueryValue ? mainQuery+mainQueryValue+(modFilter ? '&' : '' ) : (modFilter ? '?' : '' )) + (modFilter ? 'filter='+modFilter : '')
        });
    }

	 render (){
    const {
        columns,
        viewMode,
        sidebarPosition,
        item,
        isIndustry,
        isLot,
        loading,
        products,
		breadcrumbItems,
        lotBreadcrumbs,
    } = this.props;
    const breadcrumb = [{ value: 'Home', lineage: '' }, ...breadcrumbItems];
    const breadcrumbForLOts = [{ value: 'Home', lineage: '' }, ...lotBreadcrumbs];
    let content;
    const offcanvas = columns === 3 ? 'mobile' : 'always';
    if (columns > 3) {
        content = (
            <div className="container">
                <div className="block">
                 { this.props.item || this.props.isIndustry || this.props.isLot ?
                    <ProductsView
                        item={this.props.item}
                        industry={this.props.match.params.industryname}
                        lotname={this.props.match.params.lsName}
                        isLot={this.props.isLot}
                        layout={viewMode}
                        grid={`grid-${columns}-full`}
                        limit={15}
                        offcanvas={offcanvas}
                    />
                    : 
                    <div className="container">
                        <div className="not-found">
                            <div className="not-found__content">
                            <h1 className="not-found__title">Category Not Found</h1>

                            <p className="not-found__text">
                                We can&apos;t seem to find the category you&apos;re looking for.
                            </p>
                            <Link to="/post-your-request" className="btn btn-primary btn-sm">Post Your Requirements</Link>
                            </div>
                        </div>
                </div>  
                }
                </div>
                {<FiltersSidebar offcanvas={offcanvas} redirectFilters={this.redirectFilters} />}
            </div>
        );
    } else {
        let sidebar = '';
        if(products && products.length){
         sidebar = (
            <div className="shop-layout__sidebar">{<FiltersSidebar offcanvas={offcanvas} redirectFilters={this.redirectFilters} />}</div>
        );
        }

        if((item && Object.keys(item).length) || isIndustry || isLot){
        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === 'start' && sidebar}
                    <div className={classNames('shop-layout__content', {
                                            'shop-layout__empty': !products.length,
                                        })}>
                        <div className="block">
                            { this.props.item || this.props.isIndustry || this.props.isLot ?
                            <ProductsView 
                              item={this.props.item}
                              industry={this.props.match.params.industryname}
                              lotname={this.props.match.params.lsName}
                              isLot={this.props.isLot}
                                layout={viewMode}
                                grid="grid-3-sidebar"
                                limit={15}
                                offcanvas={offcanvas}
                            />
                            : 
                                <div className="container">
                                    <div className="not-found">
                                        <div className="not-found__content">
                                        <h1 className="not-found__title">Category Not Found</h1>

                                        <p className="not-found__text">
                                            We can&apos;t seem to find the category you&apos;re looking for.
                                        </p>

                                        <Link to="/post-your-request" className="btn btn-primary btn-sm">Post Your Requirements</Link>
                                        </div>
                                    </div>
                            </div>  
                            }
                        </div>
                    </div>
                    {sidebarPosition === 'end' && sidebar}
                </div>
            </div>
        );
        }
        else if(item && item == 'queryMyProducts'){
         content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === 'start' && sidebar}
                    <div className={classNames('shop-layout__content', {
                                            'shop-layout__empty': !products.length,
                                        })}>
                        <div className="block">
                        { this.props.item ?
                            <ProductsView
                                layout={viewMode}
                                grid="grid-3-sidebar"
                                limit={15}
                                offcanvas={offcanvas}
                            />
                            : 
                                <div className="container">
                                    <div className="not-found">
                                        <div className="not-found__content">
                                        <h1 className="not-found__title">Category Not Found</h1>

                                        <p className="not-found__text">
                                            We can&apos;t seem to find the category you&apos;re looking for.
                                        </p>

                                        <Link to="/post-your-request" className="btn btn-primary btn-sm">Post Your Requirements</Link>
                                        </div>
                                    </div>
                            </div>  
                            }
                        </div>
                    </div>
                    {sidebarPosition === 'end' && sidebar}
                </div>
            </div>
        ); 
        }
        else
        {
          content = (
             <React.Fragment>
                  {  this.props.loading ? 
                   <div className="form-group btn-loading-center">
                        <button type="button" className={`btn btn-light btn-loading btn-xl btn-svg-icon`}>
                            <Quickview16Svg />
                        </button>
                    </div>
                    :
                    <div className="container">
                    <div className="not-found">
                            <div className="not-found__content">
                            <h1 className="not-found__title">Category Not Found</h1>

                            <p className="not-found__text">
                                We can&apos;t seem to find the category you&apos;re looking for.
                            </p>

                            <Link to="/post-your-request" className="btn btn-primary btn-sm">Post Your Requirements</Link>
                            </div>
                        </div>
                </div> 
                }
                 </React.Fragment>
             );

        }

    }

    return (
        <React.Fragment>
           
            {item ? 
            <PageHeader header={item.value} breadcrumb={breadcrumb} />
            : 
            <React.Fragment>
               { this.props.isLot ?
                <PageHeader header={this.props.match.params.lsName ? this.props.match.params.lsName : ''} breadcrumb={breadcrumbForLOts} />
                : 
                <PageHeader header={'Products'} breadcrumb={breadcrumb} />
               }
            </React.Fragment>
             }
             {content}
        </React.Fragment>
    );
}
}

ClassifiedProducts.propTypes = {
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ClassifiedProducts.defaultProps = {
    columns: 3,
    viewMode: 'grid',
    sidebarPosition: 'start',
};

const mapStateToProps = (state, ownProps) => {
    console.log(ownProps);
	let pathname = ownProps.location.pathname;
    let pathsearch = ownProps.location.search;
    let routeparams = ownProps.match.params;
	return {
    sidebarState: state.sidebar,
    products:getVisibleProducts(state.products),
    loading : state.categories.rloading,
    item:  getSubCategoryFromParent(state.categories,pathname,pathsearch),
    isIndustry:  checkIsIndustry(pathname,pathsearch),
    isLot:  checkIsLot(pathname,pathsearch),
    lotBreadcrumbs: getLotBreadcrumb(routeparams),
    breadcrumbItems : getBreadcrumbItems(state.categories,pathname)
	};
};

const mapDispatchToProps  = dispatch => ({
    sidebarClose,dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassifiedProducts);
