import { toast } from 'react-toastify';
import * as types from "./orderActionTypes";
import axios from 'axios';
import queryString from 'query-string';

const userdata = localStorage.getItem('gouserdata') ? JSON.parse(localStorage.getItem('gouserdata')) : {};

var axios_instance = '';

if(userdata.token)
  axios_instance = axios.create({headers: {authToken: userdata.token}});
else 
  axios_instance = axios.create();

export const fetchOrders= (orders) => ({
    type: types.FETCH_ORDERS,
    orders
});

export const totalOrders= (count) => ({
    type: types.TOTAL_ORDER_COUNT,
    count
});

export const fetchOrdersBegin = () => ({
    type: types.FETCH_ORDERS_BEGIN
});


export const fetchOrdersDone = () => ({
    type: types.FETCH_ORDERS_END
});


export const fetchSingleOrderBegin = () => ({
    type: types.FETCH_SINGLE_ORDER_BEGIN
});


export const fetchSingleOrderDone = () => ({
    type: types.FETCH_SINGLE_ORDER_DONE
});


export const fetchSingleOrder = order => ({
    type: types.FETCH_SINGLE_ORDER,
    order
});

export const getOrders = (id,start,count) => (dispatch) => {
  dispatch(fetchOrdersBegin());
  axios_instance.get(process.env.PUBLIC_ENDPOINT + "/orders/customers/"+id+"?store=DEFAULT&lang=en&count="+count+"&start="+start)
     .then(response => response.data)
      .then((data) => {
        if(data.orders && data.orders.length){
          console.log(data.total);
        dispatch(totalOrders(data.total));
        dispatch(fetchOrders(data.orders));
        dispatch(fetchOrdersDone());
        }
        return data;
    }).catch(function (error) {
        dispatch(fetchOrdersDone());
    });
}

export const getSingleOrder = (orderid) => dispatch => {
    dispatch(fetchSingleOrderBegin());
     axios_instance.get(process.env.PUBLIC_ENDPOINT + "/auth/orders/"+orderid+"?lang=en&store=DEFAULT").then(response => response.data)
    .then((data) => {
    // console.log(data);
           dispatch(fetchSingleOrderDone());
        dispatch(fetchSingleOrder(data));
        return data.products;
  })
  .catch(function (error) {
    // console.log(error);
  });
}
