// react
import React from 'react';

// data stubs
import specification from '../../data/shopProductSpec';


function ProductTabSpecification(props) {
    const { product } = props;
   
        const features = product.prodattributes.map((feature, index) => (
            <div key={index} className="spec__row">
                <div className="spec__name">{feature.option}</div>
                <div className="spec__value">{feature.optionValue}</div>
            </div>
        ));

    return (
        <div className="spec">
            <h3 className="spec__header">Specification</h3>
              <div className="spec__section">
                  {features}
              </div>
            
        </div>
    );
}

export default ProductTabSpecification;
