// react
import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// application
import Dropdown from './Dropdown';
import DropdownCurrency from './DropdownCurrency';
import DropdownLanguage from './DropdownLanguage';


function Topbar(props) {
        const {  compare_items_length,login_status } = props;
    const links = [
        { title: <FormattedMessage id="topbar.aboutUs" defaultMessage="About Us" />, url: '/about-us' },
        { title: <FormattedMessage id="topbar.contactus" defaultMessage="Contact Us" />, url: '/contact-us' },
        { title: <FormattedMessage id="topbar.becomeseller" defaultMessage="Become a Seller" />, url: '/become-a-seller' }
    ];

    const linksList = links.map((item, index) => (
        <div key={index} className="topbar__item-left topbar__item--link-left">
            <Link className="topbar-link" to={item.url}>{item.title}</Link>
        </div>
    ));

    const outsideLinks = [
        { title: <FormattedMessage id="topbar.blog" defaultMessage="Blog" />, url: 'https://blog.goospares.com/' }
    ];

    const outsideLinksList = outsideLinks.map((item, index) => (
        <div key={index} className="topbar__item-left topbar__item--link-left">
            <a className="topbar-link" href={item.url}  target="_blank">{item.title}</a>
        </div>
    ));



    return (
        <div className="site-header__topbar topbar">
            <div className="topbar__container container">
                <div className="topbar__row">
                   {linksList}
                   {outsideLinksList}
                    <div className="topbar__spring"></div>
                     <div className="topbar__item topbar__item--link">
                        <Link className="topbar-link" to='/compare'>Compare Products&nbsp;{compare_items_length ? <span>({compare_items_length})</span> : '' }</Link>
                    </div>
                    <div className="topbar__item topbar__item--link">
                        { login_status == 'success' ? 
                        <Link className="topbar-link" to="/savedlist">Save List</Link>
                        :
                        <Link className="topbar-link" to='/account/login'>Save List</Link>
                        }
                    </div>
                    <div className="topbar__item">
                        <DropdownCurrency />
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    compare_items_length: state.compare.length,
    login_status :  state.user.login_status
});

const mapDispatchToProps = {
    
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Topbar);

