
import { toast } from 'react-toastify';
import * as types from "./searchActionTypes";
import axios from 'axios';
import queryString from 'query-string';

import resetCart from '../cart'

import loginStatus from '../user'

const userdata = localStorage.getItem('gouserdata') ? JSON.parse(localStorage.getItem('gouserdata')) : {};

var axios_instance = '';

if(userdata.token)
  axios_instance = axios.create({headers: {authToken: userdata.token}});
else 
  axios_instance = axios.create();




export const fetchSuggestions = suggestions => ({
    type: types.FETCH_SUGGESTIONS,
    suggestions
})

export const clearSuggestions = suggestions => ({
    type: types.FETCH_SUGGESTIONS,
    suggestions
})

export const setSearchQuery = searchQuery => ({
    type: types.SET_SEARCH_QUERY,
    searchQuery
})

export const logout = () => (dispatch) => {
  dispatch(logoutuser());
  dispatch(resetCart());
  dispatch(loginStatus('init'));
}

export const logoutuser = () => ({
    type: types.LOGOUT_USER
});

export const getSuggestions = (query) => dispatch => {
     axios_instance.post(process.env.PUBLIC_ENDPOINT + "/search/autocomplete?lang=en&store=DEFAULT", {
              "count": 10,
              "query": query,
              "start": 0
              }).then(response => response.data)
        .then((data) => {
            dispatch(fetchSuggestions(data.values ? (data.values.length ? data.values : []) : []));
            return data.values ? data.values : [];
      })
      .catch(function (error) {
        
      });
    
}
