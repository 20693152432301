import {
SEND_REQUEST_BEGIN,
SEND_REQUEST_END,
SEND_REQUEST_SUCCESS,
CONACTUS_REQUEST_BEGIN,
CONACTUS_REQUEST_END,
NOTIFY_REQUEST_BEGIN,
NOTIFY_REQUEST_END
 } from "./formsActionTypes";

const userdata = localStorage.getItem('gouserdata') ? JSON.parse(localStorage.getItem('gouserdata')) : {};

const initialState = {
    loading : false,
    requestStatus : false,
    cloading : false,
    nloading: false
};

const formsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_REQUEST_BEGIN:
            return { ...state,
                loading: true };
        case SEND_REQUEST_END:
            return { ...state,
                loading: false };
        case SEND_REQUEST_SUCCESS:
            return { ...state,
                requestStatus: action.status };
        case CONACTUS_REQUEST_BEGIN:
            return { ...state,
                cloading: true };
        case CONACTUS_REQUEST_END:
            return { ...state,
                cloading: false };
        case NOTIFY_REQUEST_BEGIN:
            return { ...state,
                nloading: true };
        case NOTIFY_REQUEST_END:
            return { ...state,
                nloading: false };
        default:
            return state;     
    }
};
export default formsReducer;