// react
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import theme from '../../data/theme';

// application
import Pagination from '../shared/Pagination';
import ProductCard from '../shared/ProductCard';
import {
    Filters16Svg,
    LayoutGrid16x16Svg,
    LayoutGridWithDetails16x16Svg,
    LayoutList16x16Svg,
    Quickview16Svg
} from '../../svg';

import { sidebarOpen } from '../../store/sidebar';
import { clearProducts,getSearchBasedProducts,currentInfiniteLimit,setSearchQuery} from '../../store/products';
import { resetFilters,resumeFilters,resetProductFilters } from '../../store/filters';
import { getVisibleProducts,trackPage } from '../../services';

class ProductsView extends Component {
    constructor(props) {
        super(props);
        this.checkboxResume = this.checkboxResume.bind(this);
        this.checkboxReset = this.checkboxReset.bind(this);
        this.state = { 
             page: 1,
            selected : null ,offsetStart: 0,
         offsetLimit : 25, limit: 25, 
         hasMoreItems: true, searchType: '',
          filterIncluded: false,
          helmetTitle: ''
           };
    }

    checkboxReset(){
         var y = document.getElementsByClassName('widget-filters__list');
         var node = y[0];
         if(node){
         node.querySelectorAll('input[type=checkbox]').forEach(function(item) {
            item.checked = false;
         });
         }
    }

    checkboxResume(search){
        var y = document.getElementsByClassName('widget-filters__list');
        var node = y[0];
        if(node){
        var filters = queryString.parse(search) ? (queryString.parse(search).filter ? queryString.parse(search).filter : '') : '';
        console.log(filters);
        if(node){
         node.querySelectorAll('input[type=checkbox]').forEach(function(item) {
            if(filters && filters.indexOf(item.value) >= 0)
              item.checked = true;
            else
              item.checked = false;
         });
         }
         if(filters){
        var manufacturer = [];
        var categories = [];
        var attrs = [];
         var splitFilters = filters.split('::');
         splitFilters.map((val,index) => {
              if(val.indexOf('manufacturer')>=0)
                manufacturer = val.split(':')[1] ? val.split(':')[1].split('|').map((val,index) => { return encodeURIComponent(val); }) : [];
              if(val.indexOf('attrsValues')>=0)
                attrs = val.split(':')[1] ? val.split(':')[1].split('|').map((val,index) => { return encodeURIComponent(val); }) : [];
              if(val.indexOf('categories')>=0)
                categories = val.split(':')[1] ? val.split(':')[1].split('|').map((val,index) => { return encodeURIComponent(val); }) : [];
          });
         this.props.dispatch(resumeFilters(manufacturer,categories,attrs));
       }
     }
    }
     loadCategoryBasedProducts(count){
        var self = this;
        this.props.dispatch(getSearchBasedProducts('?category='+encodeURIComponent(this.props.item.value),this.props.location.search,count,this.props.item.lineage))
                .then(response => {
                 self.props.trackPage(self.props.location.pathname + self.props.location.search);
                 if(self.props.location.search.indexOf('filter=') == -1){
                     self.checkboxReset();
                 }
                 else
                 {
                     self.checkboxResume(self.props.location.search);
                 }
            })
              .catch(err => {
                 
              });
    }  

    loadIndustryBasedProducts(count){
        var self = this;
        this.props.dispatch(getSearchBasedProducts('?industry='+encodeURIComponent(this.props.industry),this.props.location.search,count,this.props.item.lineage))
                .then(response => {
                 self.props.trackPage(self.props.location.pathname + self.props.location.search);
                 if(self.props.location.search.indexOf('filter=') == -1){
                     self.checkboxReset();
                 }
                 else
                 {
                     self.checkboxResume(self.props.location.search);
                 }
            })
              .catch(err => {
                 
              });
    }

    loadLotBasedProducts(count){
        var self = this;
        this.props.dispatch(getSearchBasedProducts('?lot='+encodeURIComponent(this.props.lotname),this.props.location.search,count,this.props.item.lineage))
                .then(response => {
                 self.props.trackPage(self.props.location.pathname + self.props.location.search);
                 if(self.props.location.search.indexOf('filter=') == -1){
                     self.checkboxReset();
                 }
                 else
                 {
                     self.checkboxResume(self.props.location.search);
                 }
            })
              .catch(err => {
                 
              });
    }

    loadQueryBasedProducts(query,count){
        var self = this;
        this.props.dispatch(getSearchBasedProducts(query,'',count))
          .then(response => {
              self.props.trackPage(self.props.location.pathname + self.props.location.search);
                 if(self.props.location.search.indexOf('filter=') == -1){
                     self.checkboxReset();
                 }
                 else
                 {
                     self.checkboxResume(self.props.location.search);
                 }
          })
          .catch(err => {
          
          });
    }


    componentWillUnmount(){
      this.props.dispatch(setSearchQuery(''));
      this.props.dispatch(resetProductFilters());
      this.props.dispatch(resetFilters());
      this.props.dispatch(clearProducts());
    }

    componentDidUpdate(prevProps){
       if (this.props.item && this.props.item != 'queryMyProducts' 
                && ((this.props.location.pathname !== prevProps.location.pathname) 
                    || (decodeURIComponent(this.props.location.search) !== decodeURIComponent(prevProps.location.search)))) {
            this.setState({ searchType : 'category-based' , filterIncluded : true , selected : null, helmetTitle: this.props.item.value.toUpperCase() });
             this.props.dispatch(clearProducts());
             this.loadCategoryBasedProducts({start : 0, limit : 20, end : 20});
        }
        else if(this.props.industry && decodeURIComponent(this.props.location.search) !== decodeURIComponent(prevProps.location.search)) {
            let sType = 'industry-based';
                this.setState({ searchType : sType,
                selected : null, 
                helmetTitle: this.props.industry.toUpperCase()  });
            this.props.dispatch(clearProducts());
            this.loadIndustryBasedProducts({start : 0, limit : 20, end : 20});
        }
        else if(this.props.lotname && decodeURIComponent(this.props.location.search) !== decodeURIComponent(prevProps.location.search)) {
            let sType = 'lot-based';
                this.setState({ searchType : sType,
                selected : null, 
                helmetTitle: this.props.lotname.toUpperCase()  });
            this.props.dispatch(clearProducts());
            this.loadLotBasedProducts({start : 0, limit : 20, end : 20});
        }
        else if(decodeURIComponent(this.props.location.search) !== decodeURIComponent(prevProps.location.search)) {
            let sType = 'query-based';
            this.setState({ searchType : sType , filterIncluded : true, selected : null, helmetTitle: (queryString.parse(this.props.location.search).industry ? queryString.parse(this.props.location.search).industry.toUpperCase() : queryString.parse(this.props.location.search).query.toUpperCase())});
            this.props.dispatch(clearProducts());
            this.loadQueryBasedProducts(this.props.location.search,{start : 0, limit : 20, end : 20});
        }
    }

   

    componentWillMount(){
        var self = this;
            if (this.props.item && this.props.item != 'queryMyProducts'){
                this.setState({ searchType : 'category-based' , selected : null, helmetTitle : this.props.item.value.toUpperCase() });
               this.props.dispatch(resetProductFilters());
               this.props.dispatch(resetFilters());
               this.props.dispatch(clearProducts());
               this.loadCategoryBasedProducts({start : 0, limit : 20, end : 20});
            }
            else if(this.props.industry){
                let sType = 'industry-based';
                this.setState({ searchType : sType,
                selected : null, 
                helmetTitle: this.props.industry.toUpperCase()  });
              this.props.dispatch(resetProductFilters());
              this.props.dispatch(clearProducts());
              this.props.dispatch(resetFilters());
              this.loadIndustryBasedProducts({start : 0, limit : 20, end : 20});
            }
            else if(this.props.lotname){
                let sType = 'lot-based';
                this.setState({ searchType : sType,
                selected : null, 
                helmetTitle: this.props.lotname.toUpperCase()  });
              this.props.dispatch(resetProductFilters());
              this.props.dispatch(clearProducts());
              this.props.dispatch(resetFilters());
              this.loadLotBasedProducts({start : 0, limit : 20, end : 20});
            }
            else
            {
                console.log(queryString.parse(this.props.location.search));
              let sType = 'query-based';
              this.setState({ searchType : sType,
                selected : null, 
                helmetTitle: (queryString.parse(this.props.location.search).industry ? 
                                        queryString.parse(this.props.location.search).industry.toUpperCase()
                                         : queryString.parse(this.props.location.search).query.toUpperCase())  });
              this.props.dispatch(resetProductFilters());
              this.props.dispatch(clearProducts());
              this.props.dispatch(resetFilters());
              this.loadQueryBasedProducts(this.props.location.search ? this.props.location.search : '',{start : 0, limit : 20, end : 20});
            }
        
        if (this.props.login_status == 'success') {
          // this.props.dispatch(loadWishlist());
        }
    }  

    openSideBarOption = () => {
      this.props.dispatch(sidebarOpen());
    }

    setLayout = (layout) => {
        this.setState(() => ({ layout }));
    };

    handlePageChange = (page) => {
        this.setState(() => ({ page }));
      //  let scroll = Scroll.animateScroll;
      let selected = page;
      this.setState({ selected : selected});
      let offsetLimit = Math.floor(this.props.infiniteLimit);
      let offsetStart = Math.ceil((selected - 1) * offsetLimit);
      let offsetEnd = Math.ceil(offsetStart + offsetLimit);
            console.log("offsetStart="+offsetStart+" offsetEnd="+offsetEnd+" offsetLimit="+offsetLimit);

      this.setState({ selected : selected, offsetStart: offsetStart, offsetLimit: offsetLimit }, () => {
        console.log(this.state.searchType);
        if(this.state.searchType == 'category-based'){
            this.loadCategoryBasedProducts({ start : offsetStart, limit : offsetLimit, end : offsetEnd});
        }
        else if(this.state.searchType == 'industry-based'){
            this.loadIndustryBasedProducts({ start : offsetStart, limit : offsetLimit, end : offsetEnd});
        }
        else if(this.state.searchType == 'lot-based'){
            this.loadLotBasedProducts({ start : offsetStart, limit : offsetLimit, end : offsetEnd});
        }
        else if(this.state.searchType == 'query-based' && this.state.filterIncluded) {
            this.loadQueryBasedProducts(this.props.location.search,{start : offsetStart, limit : offsetLimit, end : offsetEnd});
        }
        else
            this.loadQueryBasedProducts(this.props.location.search ? this.props.location.search : '',{start : offsetStart, limit : offsetLimit, end : offsetEnd});
       let number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;   
    //   scroll.scrollTo(0,{duration:0});
      });
    };

    render() {
        const {
            products,
            loading,
            grid,
            offcanvas,
            layout: propsLayout,
            totalCount,
            infiniteLimit,
            loadedCount,
            isLot
        } = this.props;
        const { page, layout: stateLayout, helmetTitle, searchType } = this.state;
        const layout = stateLayout || propsLayout;

        let viewModes = [
            { key: 'grid', title: 'Grid', icon: <LayoutGrid16x16Svg /> },
            { key: 'grid-with-features', title: 'Grid With Features', icon: <LayoutGridWithDetails16x16Svg /> },
            { key: 'list', title: 'List', icon: <LayoutList16x16Svg /> },
        ];

        viewModes = viewModes.map((viewMode) => {
            const className = classNames('layout-switcher__button', {
                'layout-switcher__button--active': layout === viewMode.key,
            });

            return (
                <button
                    key={viewMode.key}
                    title={viewMode.title}
                    type="button"
                    className={className}
                    onClick={() => this.setLayout(viewMode.key)}
                >
                    {viewMode.icon}
                </button>
            );
        });

        const productsList = products.map((product) => (
            <div key={product.id} className="products-list__item">
                <ProductCard product={product} isLot={isLot} />
            </div>
        ));

        const viewOptionsClasses = classNames('view-options', {
            'view-options--offcanvas--always': offcanvas === 'always',
            'view-options--offcanvas--mobile': offcanvas === 'mobile',
        });

        return (
        <React.Fragment>
         <Helmet>
            <title>{`${helmetTitle ? helmetTitle : 'Products'} — ${theme.name}`}</title>
            <meta name="og_title" property="og:title" content={`${this.props.item ? (this.props.item.description ? this.props.item.description.title : searchType) : searchType}`}/>
            <meta name="description" content={`${this.props.item ? (this.props.item.description ? this.props.item.description.desc : searchType) : searchType}`}/>
        </Helmet>
            <div className="products-view">
            {this.props.products && this.props.products.length ?
                <React.Fragment>
                <div className="products-view__options">
                    <div className={viewOptionsClasses}>
                        <div className="view-options__filters-button">
                            <button type="button" className="filters-button" onClick={this.openSideBarOption}>
                                <Filters16Svg className="filters-button__icon" />
                                <span className="filters-button__title">Filters</span>
                            </button>
                        </div>
                       
                        <div className="view-options__legend">Showing {this.props.loadedCount} of {this.props.totalCount} products</div>
                        <div className="view-options__divider" />
                        <div className="view-options__control">
                            <label htmlFor="view-options-sort">Sort By</label>
                            <div>
                                <select className="form-control form-control-sm" name="" id="view-options-sort">
                                    <option value="">Default</option>
                                    <option value="">Name (A-Z)</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="products-view__list products-list"
                    data-layout={layout !== 'list' ? grid : layout}
                    data-with-features={layout === 'grid-with-features' ? 'true' : 'false'}>
                    <div className="products-list__body">
                        {productsList}
                    </div>
                </div>
                
                <div className="products-view__pagination">
                    <Pagination
                        current={page}
                        siblings={2}
                        total={ Math.ceil(totalCount / infiniteLimit)}
                        onPageChange={this.handlePageChange}
                    />
                </div>
                </React.Fragment>
                :
                <React.Fragment>
                   {this.props.loading ? 
                   <div className="form-group btn-loading-center">
                    <button type="button" className={`btn btn-light btn-loading btn-xl btn-svg-icon`}>
                        <Quickview16Svg />
                    </button>
                </div>
                 :  <div className="container">
                <div className="not-found">
                    <div className="not-found__content">
                        <h1 className="not-found__title">No Products Found</h1>

                        <p className="not-found__text">
                            We can&apos;t seem to find the products you&apos;re looking for.
                          
                        </p>


                        <Link to="/post-your-request" className="btn btn-primary btn-sm">Post Your Requirements</Link>
                    </div>
                </div>
            </div> }
                 </React.Fragment>
                }
            </div>

        </React.Fragment>
        );
    }
}

ProductsView.propTypes = {
    /**
     * array of product objects
     */
    products: PropTypes.array,
    /**
     * products list layout (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    layout: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * products list layout (default: 'grid')
     * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
     */
    grid: PropTypes.oneOf(['grid-3-sidebar', 'grid-4-full', 'grid-5-full']),
    /**
     * indicates when sidebar bar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
};

ProductsView.defaultProps = {
    products: [],
    layout: 'grid',
    grid: 'grid-3-sidebar',
    offcanvas: 'mobile',
};
const mapStateToProps = (state, ownProps) => {
    return {
     loading : state.products.ploading,
     products:getVisibleProducts(state.products),
     totalCount: state.products.totalCount,
     infiniteLimit: state.products.infiniteLimit,
     loadedCount : state.products.loadedCount,
     trackPage : trackPage
    };
};

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(ProductsView));
