import { CURRENCY_CHANGE } from './currencyActionTypes';
import currencies from '../../data/shopCurrencies';

let defaultCurrencyIndex = 2;
let currencyObj = {};

if(localStorage.getItem('currencyObj')){
    console.log(localStorage.getItem('currencyObj'));
    let selectedCurrency = JSON.parse(localStorage.getItem('currencyObj'));
    currencyObj = currencies.filter((item) => {
        return item.currency.code == selectedCurrency.code;
    })[0].currency;
}
else
{
    localStorage.setItem('currencyObj', JSON.stringify(currencies[defaultCurrencyIndex].currency));
    currencyObj = JSON.parse(localStorage.getItem('currencyObj'));
 }
const initialState = currencyObj;
export default function currencyReducer(state = initialState, action) {
    if (action.type === CURRENCY_CHANGE && state.code !== action.currency.code) {
        console.log(action);
        localStorage.setItem('currencyObj',JSON.stringify(action.currency));
        return JSON.parse(JSON.stringify(action.currency));
    }
    return state;
}
