import {
    FETCH_LOT_CATEGORIES_BEGIN, FETCH_LOT_CATEGORIES_DONE, RECEIVE_LOT_CATEGORIES, RECEIVE_LOTS, EMPTY_LOTS } from "./lotsActionTypes";


const initialState = {
    lotcategories: [],
    categorylots:[],
    rloading : true,
    floading : true,
    tloading : true,
};

export default function categoriesReducer(state = initialState, action) {
     switch (action.type) {
         case FETCH_LOT_CATEGORIES_BEGIN:
            return { ...state,
                rloading: true };
                case FETCH_LOT_CATEGORIES_DONE:
            return { ...state,
                rloading: false };
                case RECEIVE_LOT_CATEGORIES:
            return { ...state,
                lotcategories: action.categories };
                case RECEIVE_LOTS:
            return { ...state,
                categorylots: action.lots };
             case EMPTY_LOTS:
                return { ...state,
                    categorylots: [] };
        default:
            return state;
    }
}
