export default [
    {
        type: 'link',
        label: 'Home',
        url: '/'
    },
    {
        type: 'link',
        label: 'Account',
        url: '/account'
        // children: [
        //     { type: 'link', label: 'Edit Profile', url: '/account/profile' },
        //     { type: 'link', label: 'Order History', url: '/account/orders' },
        //     { type: 'link', label: 'Address Book', url: '/account/addresses' },
        //     { type: 'link', label: 'Change Password', url: '/account/password' },
        // ],
    },
    {
        type: 'link',
        label: 'Pages',
        url: '/about-us',
        children: [
            { type: 'link', label: 'About Us', url: '/about-us' },
            { type: 'link', label: 'Contact Us', url: '/contact-us' },
            { type: 'link', label: 'Terms And Conditions', url: '/terms-and-conditions' },
            { type: 'link', label: 'Vision and Mission', url: '/vision-mission' },
            { type: 'link', label: 'Privacy Policy', url: '/privacy-policy' },
            { type: 'link', label: 'Discalimer', url: '/disclaimer' }
        ],
    },
    {
        type: 'button',
        label: 'Currency',
        children: [
            { type: 'button', label: 'SAR Saudi Riyal', data: { type: 'currency', code: 'SAR' } },
            { type: 'button', label: 'USD Dollar', data: { type: 'currency', code: 'USD' } },
            { type: 'button', label: 'INR Indian Rupee', data: { type: 'currency', code: 'INR' } },
        ],
    }
];
