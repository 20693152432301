export default [
    {
        id: 1,
        title: 'How Goospares Helped Tier 1 Manufacturer to Generate Revenue From Their Unused Idle Excess Inventory?',
        image: 'https://case-studies.goospares.com/wp-content/uploads/2018/09/skf-feature-380x200xc.jpg',
        categories: ['Special Offers'],
        date: '',
        link: 'https://case-studies.goospares.com/how-goospares-helped-tier-1-manufacturer-to-generate-revenue-from-their-unused-idle-excess-inventory/',
        description: 'As the largest tyre a manufacturer, having its global footprints in multiple domains like aircraft, automobiles, earthmovers, bicycles, motorcycles heavy…'
    },
    {
        id: 2,
        title: 'A Case Study: Liquidation of HDPE Raw Material',
        image: 'https://case-studies.goospares.com/wp-content/uploads/2019/11/HDPE-raw-material-feature-380x200xc.jpg',
        categories: ['Latest News'],
        date: '',
        link: 'https://case-studies.goospares.com/a-case-study-liquidation-of-hdpe-raw-material/',
        description : 'This client is one of the leading developers of pipe technologies and water solutions, a Provider of Water Management Consultancy & Engineering Services. Also, they supply tank systems, valves, water wells, manholes, & rubber components, etc.'
    },
    {
        id: 3,
        title: 'Iron and Steel Company liquidation',
        image: 'https://case-studies.goospares.com/wp-content/uploads/2020/10/iron-steel-company-feature-380x200xc.jpg',
        categories: ['New Arrivals'],
        date: '',
        link: 'https://case-studies.goospares.com/iron-and-steel-company-liquidation/',
        description: 'As the largest tyre a manufacturer, having its global footprints in multiple domains like aircraft, automobiles, earthmovers, bicycles, motorcycles heavy equipment, etc. As a leading brand, they always ensure their customer\'s satisfaction with upgrading to the latest technology.'

    }
];
