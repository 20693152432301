// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// application
import BlogCommentsList from './BlogCommentsList';

// data stubs
import comments from '../../data/blogPostComments';
import posts from '../../data/blogPosts';


export default function BlogPost3(props) {
    const { layout } = props;

    const postClasses = classNames('post__content typography', {
        'typography--expanded': layout === 'full',
    });

    const relatedPost = posts[2];

    return (
        <div className={`block post post--layout--${layout}`}>
            <div className={`post__header post-header post-header--layout--${layout}`}>
                <h1 className="post-header__title">{relatedPost.title}</h1>
                
            </div>

            <div className="post__featured">
                <Link to="/">
                    <img src={relatedPost.image} alt="" />
                </Link>
            </div>

            <div className={postClasses}>
                <p>
                    As the largest tyre a manufacturer, having its global footprints in multiple domains like aircraft, automobiles, earthmovers, bicycles, motorcycles heavy equipment, etc. As a leading brand, they always ensure their customer's satisfaction with upgrading to the latest technology.
                </p>

                <h4>The problem faced by the Seller</h4>
                <ol>
                    <li>
                       Holding excess idle inventory of value INR 390 million 
                    </li>
                    <li>
                       Increasing additional cost on the unused idle inventory like improper utilization of warehouse space, maintenance cost, resource cost, etc..
                    </li>
                </ol>
                <h4>How Goospares as an aggregator helped Seller? </h4>
                <p>
                   Goospares, on receiving the list from seller performed detail market research. Through the results generated, a series of demand generation campaigns for targeting buyers, which created and identified interest among buyers. 
                </p>
                <h4>Benefit to Seller </h4>
                <ol>
                    <li>
                       The realization value increased by 40%. by creating a market place for unused idle excess inventory 
                    </li>
                    <li>
                       Increased Profit by 30% from unused idle excess inventory.
                    </li>
                </ol>    
                <h4>Benefit to Buyer: </h4>
                <p>
                    A buyer who procured those quality unused spares at a competitive price which has raised their margin by 60%.
                </p>
                
                <hr />    
                <h2> Goospares:</h2>
                <h4> As an aggregator, Goospares.com have created a win-win situation for both Buyer and Seller</h4>
                <p>
                    Established in the year 2016, Goospares is a new business that brings in solutions to liquidate your un-used excess non-moving inventory products which include MRO Spares that are held as a stock for a long time by the business. Goospares is an online inventory control solution for businesses allowing them to simplify their multichannel retail businesses.
                </p>
            </div>
        </div>
    );
}

BlogPost3.propTypes = {
    /**
     * post layout
     * one of ['classic', 'full'] (default: 'classic')
     */
    layout: PropTypes.oneOf(['classic', 'full']),
};

BlogPost3.defaultProps = {
    layout: 'classic',
};
