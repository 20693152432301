import { toast } from 'react-toastify';
import * as types from "./reviewActionTypes";
import axios from 'axios';
import queryString from 'query-string';

const userdata = localStorage.getItem('gouserdata') ? JSON.parse(localStorage.getItem('gouserdata')) : {};

var axios_instance = '';

if(userdata.token)
  axios_instance = axios.create({headers: {authToken: userdata.token}});
else 
  axios_instance = axios.create();

export const fetchReviews= (reviews) => ({
    type: types.FETCH_REVIEWS,
    reviews
});

export const totalReviews= (count) => ({
    type: types.TOTAL_REVIEWS_COUNT,
    count
});

export const fetchReviewsBegin = () => ({
    type: types.FETCH_REVIEWS_BEGIN
});

export const fetchReviewsDone = () => ({
    type: types.FETCH_REVIEWS_END
});

export const updateBegin = () => ({
    type: types.UPDATE_BEGIN
});

export const updateEnd = () => ({
    type: types.UPDATE_END
});

export const updateToReview = review => ({
    type: types.UPDATE_SINGLE_REVIEW,
    review
});

export const addToReview = review => ({
    type: types.ADD_SINGLE_REVIEW,
    review
});

export const getReviews = (id,store) => {
   return  (dispatch) => {
      return new Promise((resolve, reject) => {
  dispatch(fetchReviewsBegin());
  axios_instance.get(process.env.PUBLIC_ENDPOINT + "/products/"+id+"/reviews?store="+store+"&lang=en")
     .then(response => response.data)
      .then((data) => {
        if(data && data.productReviewList && data.productReviewList.length){
        dispatch(totalReviews(data.total));
        dispatch(fetchReviews(data.productReviewList));
        dispatch(fetchReviewsDone());
        resolve(data.productReviewList);
        }
        return data;
    }).catch(function (error) {
        dispatch(fetchReviewsDone());
    });
  });
    };
}


export const reviewAvailable = (productId,custId)  => {
   return  (dispatch) => {
     return new Promise((resolve, reject) => {
        axios_instance.get(process.env.PUBLIC_ENDPOINT + "/reviewavailable/"+productId+"/"+custId+"?custId="+custId).then(response => response.data)
        .then((data) => {
          console.log('data',data);
            resolve(data);
        })
        .catch(function (error) {
          resolve(false);
        });
    });
  };
}


export const updateReview = (review) => dispatch => {
    dispatch(updateBegin());
    axios_instance.post(process.env.PUBLIC_ENDPOINT + "/products/reviews?lang=en" ,review ).then(response => response.data)
    .then((data) => {
        dispatch(updateEnd());
        dispatch(updateToReview(data));
        return review;
  })
  .catch(function (error) {
    dispatch(updateEnd());
  });
}


export const addReview = (review)  => {
   return  (dispatch) => {
      return new Promise((resolve, reject) => {
    dispatch(updateBegin());
    axios_instance.post(process.env.PUBLIC_ENDPOINT + "/products/reviews?lang=en" ,review ).then(response => response.data)
    .then((data) => {

        dispatch(updateEnd());
        dispatch(addToReview(data));
       resolve();
  })
  .catch(function (error) {
    reject();
    dispatch(updateEnd());
  });
})
}
}
