// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CurrencyFormat from 'react-currency-format';


function Currency(props) {
    const { value, currency, currentCurrency, uom } = props;
    const { symbol } = currency || currentCurrency;
    return <React.Fragment><span>
        <CurrencyFormat value={value} displayType={'text'} thousandSpacing={'2s'} thousandSeparator={true} prefix={symbol} />
        </span>
      { uom ? <span>&nbsp;/&nbsp;</span> : '' }
        { uom ? <span className="product-uom__section">{`${uom}`}</span> : '' }</React.Fragment>;
}

Currency.propTypes = {
    /** price value */
    value: PropTypes.number.isRequired,
    /** currency object, specify to override currentCurrency */
    currency: PropTypes.object,
    /** currency object */
    currentCurrency: PropTypes.object,
};

const mapStateToProps = (state) => {
    return { 
        currentCurrency: state.currency 
    };
};

export default connect(mapStateToProps)(Currency);
