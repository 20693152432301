import React, {Component} from 'react';

import Img from 'react-image';
import {Link} from 'react-router-dom'

import {connect} from "react-redux";
import Currency from '../shared/Currency';

import Moment from 'react-moment';
import 'moment-timezone';
import PageHeader from '../shared/PageHeader';
import { getSingleOrder } from '../../store/order';
import {getOrderDetail} from "../../services";


class AccountPageOrderDetail extends Component {

	  constructor (props) {
        super (props);
        this.state = {
            "subtitles": [
            {'title': 'My Orders', 'redirect': '/dashboard?active=orders'}
            ]
        };
    }

      componentWillMount(){
         let orderid = this.props.match.params.orderId;
         console.log(orderid);
         // console.log(productid,this.props.item);
       if(!this.props.item){
            this.props.dispatch(getSingleOrder(orderid));
        }
      }



    render (){

        const {item, ordloading,orderId,currency } = this.props;
        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        var current = new Date();
        var next5days = new Date(Date.now() + 5 * 86400000);
        let CheckDate = current.toLocaleDateString("en-US", options).toString()
        let deliveryDate = next5days.toLocaleDateString("en-US", options).toString()
         const breadcrumb = [
            { value: 'Account', lineage: '/account/profile' },
            { value: 'Orders', lineage: '/account/orders' },
            { value : item.orderId, lineage: ''}
        ];
        return (
             <React.Fragment>
            <PageHeader header={item.orderId} breadcrumb={breadcrumb} />
                <section className="cart-section section-b-space">
                 {(item)?
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row order-success-sec">
                                    <div className="card col-sm-6">
                                    <div className="card-body">
                                        <h4 className="billing-name">billing address</h4>
                                        <ul className="order-detail">
                                            <li>{item.companyName}</li>
                                            <li>{item.billing.address}</li>
                                            <li>{item.billing.country} {item.billing.postalCode}</li>
                                            <li>Contact No. {item.billing.phoneNumber}</li>
                                        </ul>
                                        </div>
                                        </div>
                                        <div className="card col-sm-6">
                                        <div className="card-body">
                                        <h4 className="billing-name">shipping address</h4>
                                        <ul className="order-detail">
                                            <li>{item.companyName}</li>
                                            <li>{item.delivery.address}</li>
                                            <li>{item.delivery.country} {item.delivery.postalCode}</li>
                                            <li>Contact No. {item.delivery.phoneNumber}</li>
                                        </ul>
                                        </div>
                                        </div>
                                </div>
                            </div>
                            <div className="order-products-section col-lg-12">
                                <div className="order-products">
                                    <h3>Products</h3>
                                     <table className="table cart-table table-responsive-xs">
                                    <thead>
                                    <tr className="table-head">
                                        <th scope="col">Image</th>
                                        <th scope="col">Product Name</th>
                                        <th className="cart-table__column--quantity" scope="col">Quantity</th>
                                        <th className="cart-table__column--total" scope="col">Price</th>
                                    </tr>
                                    </thead>
                                    <tbody >
                                    {item.products.map((item, index) => {
                                        return (
                                        
                                            <tr key={index}>
                                                <td className="cart-table__column--image">
                                                    <Link to={`/product/${item.productId}/${item.productName}`}><Img className="img-fluid-custom" src={`${process.env.PUBLIC_ENDPOINT}${process.env.PRODUCT_IMG_PATH}${item.image?
                                        item.image : item.image}`} unloader={ (<img
                                    src={`${process.env.PUBLIC_URL}/images/default.png`}
                                    className="img-fluid-custom"/>)}/></Link>
                                                </td>
                                                <td className="cart-table__column--product"><Link className="cart-table__product-name" to={`${process.env.PUBLIC_URL}/product/${item.productId}/${item.productName}`}  target={"_blank"}>{item.productName}</Link>
                                                <ul className="cart-table__options">
                                                    <li> SKU: {item.sku}</li>
                                                    <li> Mfg: {item.manufacturer}</li>
                                                </ul>
                                                </td>
                                                <td className="cart-table__column--quantity">
                                                    {item.quantity}
                                                </td>
                                                <td className="cart-table__column--total" data-title="Total">
                                                    <Currency value={Number(item[this.props.currency.cartProductSubTotalLabel])} />
                                                </td>
                                            </tr>
                                         )
                                    })}
                                    </tbody>
                                    <tfoot className="cart__totals-footer">
                                    {item.totals.map((item, index) => {
                                        return (
                                        <tr key={index}>
                                            <td></td>
                                            <td></td>
                                            <td className="cart-table__column--total-label">{item.module.toUpperCase()}</td>
                                            <td className="cart-table__column--total-value"><Currency value={Number(item[this.props.currency.orderTotalLabel])} /></td>
                                        </tr>
                                        )
                                    })}
                                    </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    : 
                     <div className="row">
                    { ordloading ? <div className="loading-cls"></div> : '' }
                    </div>
                   } 
                </section>
                </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let orderId = ownProps.match.params.orderId;
    return {
        item: getOrderDetail(state.order,Number(orderId)),
        orderId:orderId,
        ordloading : state.user.ordloading,
        currency: state.currency,
    }
}


export default connect(mapStateToProps) (AccountPageOrderDetail);


