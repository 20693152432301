// react
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';
import PageHeader from '../shared/PageHeader';
import { Helmet } from 'react-helmet';
import theme from '../../data/theme';
import { submitContactUsRequest } from '../../store/forms';
import SimpleReactValidator from 'simple-react-validator';
import { trackPage } from '../../services';
import Modal from 'react-modal';
import { Cross12Svg } from '../../svg';
import ReactGA from 'react-ga';

// application
import Pagination from '../shared/Pagination';
import StaticProductCard from '../shared/StaticProductCard';
import {
    Filters16Svg,
    LayoutGrid16x16Svg,
    LayoutGridWithDetails16x16Svg,
    LayoutList16x16Svg,
    Quickview16Svg
} from '../../svg';

Modal.setAppElement('#root');
class OurNeeds extends Component {
    constructor(props) {
        super(props);
         this.validator = new SimpleReactValidator();
    this.setStateFromInput = this.setStateFromInput.bind(this);
    this.state = {
          "comment": "",
          "emailId": "",
          "name": "",
          "subject": "",
          "showModal": false
        };
         this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    }

  
  handleOpenModal (product) {
      console.log(product);
      this.validator.hideMessages();
     this.setState(state => {
         state['comment'] = "";
          state['emailId'] =  "";
          state['name'] =  "";
         state['showModal'] = true;
         state['subject'] = 'Our Needs - '+product.displayText
          return state;
    });
  }
  
  handleCloseModal () {
      this.setState(state => {
          state['showModal'] = false;
          state['subject'] = '';
          return state;
    });
  }
  

    componentWillUnmount(){
     

    }

    componentDidUpdate(prevProps){

      
    }

   

    componentWillMount(){
         this.props.trackPage(this.props.location.pathname + this.props.location.search);
    }  

    setLayout = (layout) => {
        this.setState(() => ({ layout }));
    };

      setStateFromInput = (event) => {
        var obj = {};
        if(event.target.dataset.object){
             var key = event.target.dataset.object;
             var name = event.target.name;
             var value =  event.target.value;
                this.setState(state => {
                      state[key][name] = value;
                      return state;
                })
           }
           else{
               obj[event.target.name] = event.target.value;
               this.setState(obj);
            }
    }

    handleSubmit = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'Requests',
      action: 'Our Needs',
      label: 'Form Submit'
    });
    if (this.validator.allValid()) {
             this.props.dispatch(submitContactUsRequest(this.state))
              .then(response => {
                  this.handleCloseModal();
                })
                  .catch(err => {
                    console.log(err); 
                  });
       
    } else {
              this.validator.showMessages();
              this.forceUpdate();
    }
  }

    render() {
        const {
            grid,
            offcanvas,
            layout
        } = this.props;
         const breadcrumb = [{ value: 'Home', lineage: '' }];
        const product = {
               image : 'images/needs/Low-carbon-Wire-Rod.jpg',
               displayText : 'LOW CARBON WIRE ROD,GR 1018,6.5MM',
               itemName : 'WIRE ROD',
               material:'LOW CARBON STEEL',
               materialSpec:'ASTM A108 GRADE 1018',
               size:'6.5 MM',
               composition:'CARBON: 0.15 - 0.20 MAX,MANGANESE: 0.60 -0. 90 MAX,SULPHUR: 0.3 MAX,PHOSPHORUS: 0.025 MAX,TOLERANCE: +/- 0.20MM'

        };
        return (
             <React.Fragment>
              <Helmet>
                <title>{`Our needs — ${theme.name}`}</title>
                <meta name="Description" content={`Stay updated with us to know our needs and become a seller of our industrial requirement.`}/>
            </Helmet>
            <PageHeader header={'Our Needs'} breadcrumb={breadcrumb} />
        <Modal 
           isOpen={this.state.showModal}
           contentLabel="onRequestClose Example"
           onRequestClose={this.handleCloseModal}
           shouldCloseOnOverlayClick={false}
           className="react-go-modal"
           overlayClassName="react-go-modal-Overlay"
        > <div className="static-products-view container">
                                <div className="row">
                                    <div className="col-12 col-lg-12">
                                        <h4 className="contact-us__header card-title float-section-left">Send Response</h4>
                                        <button type="button"
                                             className="btn btn-light btn-sm btn-svg-icon float-section-right"
                                             onClick={this.handleCloseModal}>
                                            <Cross12Svg />
                                        </button>
                                        <form noValidate className="float-section-right">
                                            <div className="form-row">
                                            <div className="form-group col-md-6">
                                                   <input
                                                id="name"
                                                type="text"
                                                name="name"
                                                className="form-control "
                                                placeholder="Name"
                                                value={this.state.name} onChange={this.setStateFromInput} />
                                                    {this.validator.message('name', this.state.name, 'required')}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input
                                                id="emailId"
                                                type="email"
                                                name="emailId"
                                                className="form-control"
                                                placeholder="Email"
                                                value={this.state.emailId} onChange={this.setStateFromInput} />
                                                    {this.validator.message('emailId', this.state.emailId, 'required|email')}
                                                </div>
                                                 <div className="form-group col-md-12">
                                                <input
                                                id="subject"
                                                type="text"
                                                name="subject"
                                                className="form-control"
                                                placeholder="Subject"
                                                readOnly
                                                value={this.state.subject} onChange={this.setStateFromInput} />
                                                    {this.validator.message('subject', this.state.subject, 'required')}
                                            </div>
                                             <div className="form-group col-md-12">
                                               <textarea name="comment" rows="3    " placeholder="Comments" 
                                                className="form-control"  value={this.state.comment} onChange={this.setStateFromInput}>
                                                </textarea>
                                                    {this.validator.message('comment', this.state.comment, 'required')}
                                            </div>
                                             <button disabled={this.props.loading} onClick={this.handleSubmit.bind(this)} className={classNames('btn btn-secondary', {
                                            'btn-loading': this.props.loading,
                                        })}>Send Message {this.props.loading}</button>&nbsp;&nbsp;&nbsp;
                                             <button disabled={this.props.loading} onClick={this.handleCloseModal} className={classNames('btn btn-secondary')}>Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
        </Modal>
               
            <div className="static-products-view container">
            <div className="products-view__list products-list"
              data-layout={layout !== 'list' ? grid : layout}
              data-with-features={layout === 'grid-with-features' ? 'true' : 'false'}>
              <div className="products-list__body">
                  <div key={product.id} className="products-list__item">
                      <StaticProductCard product={product} handleOpenModal= {this.handleOpenModal} />
                  </div>
              </div>
            </div>
            </div>
            <br/>
            <br/>
            </React.Fragment>
        );
    }
}

OurNeeds.propTypes = {
    /**
     * array of product objects
     */
    products: PropTypes.array,
    /**
     * products list layout (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    layout: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * products list layout (default: 'grid')
     * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
     */
    grid: PropTypes.oneOf(['grid-3-sidebar', 'grid-4-full', 'grid-5-full']),
    /**
     * indicates when sidebar bar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
};

OurNeeds.defaultProps = {
    products: [],
    layout: 'list',
    grid: 'grid-3-sidebar',
    offcanvas: 'mobile',
};
const mapStateToProps = (state, ownProps) => {
    return {
         loading   : state.forms.cloading,
        trackPage : trackPage
    };
};

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OurNeeds);
