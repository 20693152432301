// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

import { trackPage } from '../../services';

function SitePageTerms(props) {

    trackPage(props.location.pathname+props.location.search);
    

    const breadcrumb = [
        { value: 'Home', lineage: '' },
        { value: 'Terms And Conditions', lineage: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Terms and Conditions - ${theme.name}`}</title>
                <meta name="Description" content={`Goospares online. Check out the terms and conditions on buying and selling industrial spares online from one of the top Industrial Spare selling store in India`}/>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title">Terms and Condition</h1>
                        </div>
                        <div className="document__content typography">
                            <p>
                                The following are the Terms and Conditions between 'Goospares' and Trader for sale or purchase of ‘Trader’s excess non-moving and slow-moving inventory’, through Goospares website (www.goospares.com) on best efforts basis.
                            </p>
                            <h6>Whereas</h6>
                             <ul>
                                <li>
                                    Goospares owns and operates a B2B marketplace on the website located at the URL www.goospares.in (hereinafter referred to as “website”), which is used by various entities to facilitate buying and selling of excess non-moving and slow-moving products, subject to certain terms and conditions mutually agreed by Goospares and the Seller hereunder
                                </li>
                                <li>
                                    A Trader is referred as the Seller or Buyer, who can interchange these roles, depending upon the nature of the transaction. A Trader will be referred to as a Seller when he lists items for sale on the Website. A Trader will be referred to as a Buyer when he shows interest in or buys items listed on the Websit

                                </li>
                            </ul>
                                
                            <hr />
                            <h6>Product Information</h6>
                            <ul>
                                <li><strong>Catalogue/Website Information - </strong>
                                    Goospares is a distributor of products. Information about the products in the Goospares catalogue or web site is provided by the Sellers. Product depictions in the catalogues and websites are for illustrative purposes only. Possession of, or access to, any Goospares catalogue, literature or websites does not constitute the right to purchase products. Goospares reserves the right to revise publishing errors in its catalogues or any of its websites. Despite our efforts, occasional pricing errors may occur in the Goospares catalogues and websites, and Goospares reserves the right to correct or change such pricing errors without notice. Goospares further reserves the right to cancel any and all orders resulting from such pricing errors, even if Customer has received an order confirmation from Goospares
                                </li>
                                <li>
                                    <strong>Prices - </strong>
                                   "Web Prices” displayed on goospares.com are on F.O.B basis. Prices do not include freight, handling fees, taxes, and/or duties. Prices are subject to change or correction at any time and without notice
                                </li>
                            </ul>

                            <hr />
                            <h6>GooSpares Obligations to Sellers</h6>
                            <ul>
                            <li>
                            Goospares will facilitate and offer guidance in regard to creation of listing of goods for the purpose of selling on the website
                            </li>
                            <li>
                            Goospares will facilitate and market Trader’s goods to the potential Buyers on the website and through other offline and online mediums to the best of their abilities
                            </li>
                            <li>
                            In case of any dispute between the Seller and the Buyer, Goospares will try to resolve the same amicably
                            </li>
                            <li>
                            Notwithstanding the above, while it is Goospares principle goal to ensure that the items listed on its website are sold to a Buyer within shortest possible time, it does not offer any guarantee, explicit or implied, or promises to be able to liquidate/sell/conclude a deal, in full or in part, of products listed by a Trader within any stipulated period of time
                            </li>
                            </ul>
                            <h6>Seller's Obligations</h6>
                            <ul>
                            <li>
                           The Seller shall provide Goospares with information on the goods with detailed specifications and description of the products including location, quantity, quality, defects, special remarks or restriction, if any
                            </li>
                            <li>
                            The Seller shall provide all relevant data including year of manufacturing, extent of usage, warranties, prices, production quantity, available inventory, etc. as per the format required by the website
                            </li>
                            <li>
                            Once a Buyer has requested and confirmed for purchase of a listed product, an agreement has to signed between the Seller, Buyer and Goospares. On receipt of the purchase order from the Buyer, the Seller has to generate an invoice within a stipulated time as mentioned in the agreement
                            </li>
                            <li>
                            Goospares would intimate the Seller upon the successful payment initiation by the Buyer. Then, the Seller needs to dispatch the goods to the Buyer to the destination as specified in the agreement
                            </li>
                            <li>
                            The seller hereby authorizes Goospares to use the Seller’s trademarks, logos, brand names and corporate name on the website and in any promotional material produced in connection with the website or listings thereon which, at appropriate time, will be used to enhance the saleability of the listed product. Goospares undertakes that the Trader’s information such as mentioned above would never be used in any manner detrimental to the interest of the Trader
                            </li>
                            <li>
                            <strong>Exclusivity:</strong> It is hereby agreed that, with respect to any goods offered for sale on the website by the Seller, Goospares shall be the sole and exclusive selling agent for a period the listing is active on Goospares.in. The Seller hereby expressly undertakes that the Seller shall not, during such active listing period, offer such goods for sale through any other platform nor circumvent nor attempt to circumvent Goospares by selling such goods to buyers directly. The seller expressly undertakes that, with respect to such goods, Seller shall not, during the period the validity of listing on website, solicit any buyers directly and that, should any buyer approach the Seller directly, the Seller shall immediately inform Goospares and refer any such Buyer to Goospares to take the negotiations and transactions forward
                            </li>
                            <li>
                            <strong>Payment Terms:</strong> Post receiving confirmation from Buyer of satisfactory receipt of the product as per listing on the website, the transaction will be deemed complete. Goospares will release the payment to the Seller as per payment cycle specified on the purchase order.
                            </li>
                            <li>
                            If material supplied by the Seller, does not meet the ordered/mutually agreed specifications (as per the agreement), the Buyer can return the same in part or in full after pointing out the discrepancies, and upon Goospares confirming the objection raised by the Buyer reasonable and sufficient, the Seller will then have to take back the material at the Seller’s cost. Any request by the Buyer for return of the material would be entertained within 7 days of material received by the Buyer
                            </li>
                            <li>
                            <strong>Commercial Terms:</strong> Listing of items will charged be as per the prevailing rates mentioned on the website. Listing is free for 60 days from the date of registration provided the registration is done prior to 30th June 2016. This offer is at the discretion of Goospares and can be withdrawn/altered anytime without prior notice to the Sellers. However, the terms on which certain listing packages have been purchased by the Sellers would be honoured, and would remain unaffected until the validity of the package bought by the Seller, from any changes made in the fees structure subsequent to purchase of the package. Every transaction will be charged a transaction fee at the rate of 5% (five percent) on the total invoice value. The fee will be payable by Buyer or Seller or both, depending upon nature of transaction and terms agreed during the negotiations/placement of order. All applicable taxes and duties prevailing at the time of transaction would be charged extra. In case of any conflict between terms of MoU and that of Purchase Order, the Purchase Order terms would prevail
                            </li>
                            <li>
                            The Seller confirms that he is the sole and undisputed owner of the products he has listed, and the products listed are of free and clear title without any encumbrances from creditors or other parties
                            </li>
                            <li>
                            Goospares, during its course of business, will bring Sellers and Buyers in contact to be able to trade excess inventory successfully. The Seller undertakes that they would not engage with such Buyers introduced to them by Goospares for the purpose of trading non-moving stock and excess inventory in future and if they do identify excess inventory items they can trade between them, then the Seller assures to conclude such transactions through Goospares only
                            </li>
                            </ul>

                            <hr />
                            <h6>GooSpares Obligations to Buyers</h6>
                            <ul>
                            <li>
                            Goospares will facilitate and offer guidance in regard to purchasing of listed goods on website
                            </li>
                            <li>
                            Goospares will facilitate the potential Buyers on website and through offline and online mediums
                            </li>
                            <li>
                            In case of any dispute between the Buyer and the Seller, Goospares will try to resolve the same amicably
                            </li>
                            <li>
                            Notwithstanding the above, while it is Goospares endeavour to ensure that the items listed on its website are as per the specifications published by the Seller, it does not offer any guarantee, explicit or implied, or promises that the products would function/perform or operate as claimed by the Seller
                            </li>
                            </ul>
                            <h6>Buyer's Obligations</h6>
                            <ul>
                            <li>
                            Once the Buyer decides to purchase the listed items after the price negotiation with the supplier, a Buyer has to enter an agreement with the supplier and Goospares. The agreement would cover the product description, technical specifications if any, the payment details and the details of logistics
                            </li>
                            <li>
                            The Buyer should then issue the purchase order to the seller. On receipt of the invoice from the Seller, the Buyer need to initiate the payment either by mode of Telegraphic Transfer (TT), Letter of Credit (LC) or Escrow account as in the agreement. The payment has to be initiated by the Buyer within a period of two working days from the receipt of the invoice in order to ensure delivery of the requested item
                            </li>
                            <li>
                            In case the Buyer uses any value-added services facilitated by Goospares such as testing, inspection, transportation charges etc., as mentioned in the agreement, the cost of the same will be borne by the Buyer over and above the value of goods purchased
                            </li>
                            <li>
                            Any material return process must be initiated within 7 days from the date of material receipt at the Buyer’s premises. Material return request will only be entertained in case the material received doesn’t match the description in the agreement. Goospares Dispute Resolution Team will work with both the Buyers and Sellers to clarify the necessary details
                            </li>
                            <li>
                            As a Buyer, the Trader agrees that Goospares will have sole right to cancel any transaction for any reason whatsoever not limited to system error, incorrect pricing, incorrect dispatch etc
                            </li>
                            <li>
                            <strong>Payment Terms:</strong> A Buyer will issue purchase order along with payment either by mode of Telegraphic Transfer (TT), Letter of Credit (LC) or Escrow account. terms of which would have been mutually agreed between Buyer and Goospares. In case of request for returns getting approved by the Goospares Dispute Resolution Team, the Buyer will be refunded the payment completely, without any interest thereon, as per Goospares payment cycle mentioned in the purchase order
                            </li>
                            <li>
                            The Buyer hereby authorizes Goospares to use Buyer’s trademarks, logos, brand names and corporate name on the website and in any promotional material produced in connection with the website or listings thereon. Goospares undertakes that the Trader information such as mentioned above would never be used in any manner detrimental to the interest of the Trader
                            </li>
                            <li>
                            Goospares, during its course of business, will bring Buyers and Sellers in contact to be able to trade excess inventory successfully. The Buyer undertakes that they would not engage with such Sellers introduced to them by Goospares for the purpose of trading non-moving stock and excess inventory in future and if they do identify excess inventory items they can trade between them, then the Buyer assures to conclude such transactions through Goospares
                            </li>
                            </ul>

                            <hr />
                            <h6>Term & Termination</h6>
                            <p>
                            These terms and conditions shall commence from the date of signing-up on the website, will remain in force unless either party serves notice to the other, of its intent to terminate this relationship. A 30-day prior notice will have to be given for such termination. No refunds will be made to the Trader of any amount paid to Goospares by them in case the termination is initiated by the Trader
                            </p>

                            <hr />
                            <h6>Indeminity & Exclusion of Liability</h6>
                            <p>
                            <strong>General Indemnity: </strong>
                            The Trader shall at all times and to the complete satisfaction of Goospares and without demur, at its own expense, indemnify, defend and hold harmless, Goospares and its officers, directors, employees, associates, affiliates, successors, representatives, assigns and agents in respect of all payments (including but not limited to settlements) made by Goospares, damages or penalties or awards imposed on Goospares and costs (including but not limited to attorneys’ fees and court fees) incurred by Goospares in relation to any claim by any Buyer or claims made by any person in relation to the services provided by Goospares in terms hereof or the sale or delivery of any of the Trader’s products whether Goospares is legally liable or not, and whether or not there has been any breach by Trader of any applicable consumer law or regulation whatsoever, and whether or not there has been any breach or alleged breach by Trader’s obligations, representations, or warranties hereunder
                            </p>

                            <hr />
                             <h6>Confidentiality & Non-Disclosure</h6>
                            <p>
                            Each Party acknowledges that, as a result of signing these terms and conditions, it may have access to certain information about the other (revealing) Party or its business which each party agrees not to use in any manner, during the term of the agreement and even after its termination, detrimental to the interest of the revealing party and to keep all such information completely and absolutely confidential. Each Party agrees that it shall not disclose any such information to any third party without the other Party’s prior written consent
                            </p>
                            
                            <hr />
                             <h6>Governing Law, Jurisdiction & Dispute Resolution</h6>
                            <p>
                             On the aspects where the understandings not covered by this MoU or for cases relating to deviation from these understandings, efforts would be made to resolve the issues through mutual dialogues and consultations between Goospares and Trader. If such a resolution is not possible then the unresolved disputes and differences shall first be referred to a mutually agreed Arbitrator and then to the Court of Law under the Chennai Jurisdiction. The decision of the Court of Law will be final and binding on the both the parties
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageTerms;
