// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// application
import BlogCommentsList from './BlogCommentsList';

// data stubs
import comments from '../../data/blogPostComments';
import posts from '../../data/blogPosts';


export default function BlogPost2(props) {
    const { layout } = props;

    const postClasses = classNames('post__content typography', {
        'typography--expanded': layout === 'full',
    });

    const relatedPost = posts[1];

    return (
        <div className={`block post post--layout--${layout}`}>
            <div className={`post__header post-header post-header--layout--${layout}`}>
                <h1 className="post-header__title">{relatedPost.title}</h1>
                
            </div>

            <div className="post__featured">
                <Link to="/">
                    <img src={relatedPost.image} alt="" />
                </Link>
            </div>

            <div className={postClasses}>
                <p>
                    This client is one of the leading developers of pipe technologies and water solutions, a Provider of Water Management Consultancy & Engineering Services. Also, they supply tank systems, valves, water wells, manholes, & rubber components, etc. 
                </p>
                <h4>Problems Faced by Seller:</h4>
                <ul>
                    <li>
                        The seller was holding an idle inventory of value 70 Million, 920 metric tonnes of pipe materials for around 3 years. 
                    </li>
                    <li>
                        Not able to find prospective buyers.
                    </li>
                    <li>
                        No competitive price offered.
                    </li>
                </ul>
                <h4>How Goospares Found a Solution?</h4>
                <h4>Physical inspection:</h4>
                <p>
                   Physical inspection was made to the site for verifying the material, where laboratory tests were also performed to inspect the quality. Where live images are also being captured.
                </p>
                <h4> Depreciation analysis:</h4>
                <p>
                    A market analysis was made to identify the buyer and materials demand, along with the market price based on the quality
                </p>
                <h4>Promotion:</h4>
                <p>
                    The product was uploaded to the portal with the live images. The promotions were made in both online and offline to identify the buyers.    
                </p>
                <h4>Cost Efficiencies and Time Savings: </h4>
                <p>
                   Goo spares were able to find the right buyer within 3 months, for the 920 metric tons of HDPE raw materials which were lying idle for nearly 5 years in a company located in the Middle East. 
                </p>
                <p>
                    The entire pipe materials which cost around INR 7 Million in the market were made available to the buyer for INR 2.8 Million.
                </p>
                <p>
                The seller was able to realize a good value of INR 7 Million for the deal as the scrap value was only around INR 0.7 Million. The pipes were used for laying sewage pipes and thus contributed to the social development of the region.
                </p>
                <h4>Economic and Social Benefits:</h4>
                <ul>
                    <li>
                    Liquidation of 920 metric tonnes of pipe materials resulted in procurement of low cost, good quality pipes for buyer
                    </li>
                    <li>
                      Infrastructural development in the region.
                    </li>
                    <li>
                    Infrastructural development in the field of sanitation.
                    </li>
                     <li>
                     State of health bound to improve in the region.
                    </li>
                </ul>
                 <blockquote>
                    <p>
                        Quality is in a product or service is not what you put into it. It is what the client or customer gets out of it.
                    </p>
                    <p><cite>Peter F.Drucker</cite></p>
                </blockquote>


                <hr />
                <h4>About Goospares</h4>
                <p>
                    Established in the year 2016, Goospares is a new business that brings in solutions to liquidate your un-used excess non-moving inventory products which include MRO Spares that are held as a stock for a long time by the business. Goospares is an online inventory control solution for businesses allowing them to simplify their multichannel retail businesses.
                </p>
                
            </div>
        </div>
    );
}

BlogPost2.propTypes = {
    /**
     * post layout
     * one of ['classic', 'full'] (default: 'classic')
     */
    layout: PropTypes.oneOf(['classic', 'full']),
};

BlogPost2.defaultProps = {
    layout: 'classic',
};
