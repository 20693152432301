// react
import React from 'react';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';

// data stubs
import theme from '../../data/theme';


export default function Footer() {
    const informationLinks = [
        { title: 'About Us', url: '/about-us' },
        { title: 'Vision & Mission', url: '/vision-mission' },
        { title: 'FAQ\'s', url: '/faq' },
        { title: 'Contact Us', url: '/contact-us' }
    ];

    const quickLinks = [
        { title: 'Become a Seller', url: '/become-a-seller' },
        { title: 'Post Your Requirements', url: '/post-your-request' },
        { title: 'Our Needs', url: '/our-needs' },
        { title: 'Lot Sale', url: '/lotsales' }
    ];

    const extQuickLinks =[
         { title: 'Blog', url: "https:\//blog.goospares.com/" } ,
         { title: 'Case Studies', url: "https:\//case-studies.goospares.com/" }  
    ];

    const accountLinks = [
        { title: 'Terms and Conditions', url: '/terms-and-conditions' },
        { title: 'Privacy Policy', url: '/privacy-policy' },
        { title: 'Disclaimer', url: '/disclaimer' }
    ];

    return (
        <React.Fragment>
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3">
                            <FooterLinks title="Quick Links" items={quickLinks}/>
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="Information" items={informationLinks}  extLinks={extQuickLinks}/>
                        </div>
                        <div className="col-6 col-md-3 col-lg-3">
                            <FooterLinks title="My Account" items={accountLinks} />
                        </div>
                        <div className="col-12 col-md-12 col-lg-4">
                            <FooterNewsletter />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="site-footer">
             <div className="container">
                  <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        Copyright © 2020
                        {' '}
                        <a href="http://goospares.com/" rel="noopener noreferrer" target="_blank">Goospares.com</a>
                        {''}
                         . All Rights Reserved.
                    </div>
                    <div className="site-footer__payments">
                    </div>
                </div>
             </div>
        </div>
        </React.Fragment>
    );
}
