// react
import React, { Component } from 'react';
import Img from 'react-image';
import { Helmet } from 'react-helmet';
import theme from '../../data/theme';
import PageHeader from '../shared/PageHeader';

// third-party
import PropTypes from 'prop-types';

// application
//import products from '../../data/shopProducts';
import { connect } from 'react-redux'

import {Link} from 'react-router-dom';

import classNames from 'classnames';

import { retrieveLotSaleCategories } from '../../store/lots';
import { getLotSaleCategories } from '../../services';


class SiteLotCategories extends Component {
    timeout;

    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            loading: false
        };
    }

    componentWillUnmount() {
    }

    componentDidMount() {
        if(!this.props.categories.length){
            this.props.dispatch(retrieveLotSaleCategories()).then(response => {
                
            }).catch(err => {
               // toast.error("Login Failed. Please enter valid credentials");
            });
        }
    }


    render() {
    const {
        layout,
        loading,
        withSidebar
    } = this.props;
    const containerClasses = classNames('product-card categories-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });
    const containerOuterClasses = classNames({
        container: !withSidebar,
    });
    const breadcrumb = [{ value: 'Home', lineage: '' }];

    const blockClasses = classNames('block block-products-carousel', {
        'block-products-carousel--loading': loading,
    });

    return (
        <React.Fragment>
              <Helmet>
              <title>{`Industrial lot sale|Stock Lot Website — ${theme.name}`}</title>
                <meta name="Description" content={`B2B Marketplace for spares lot liquidation. Buying Stock lot at discount prices. Bulk Sale of industrial Spares.Goospares.com Stock lot Sale website in India.`}/>
            </Helmet>
            <PageHeader header={'Lot Sale'} breadcrumb={breadcrumb} />
        <div className={blockClasses} data-layout={layout}>
            <div className={containerOuterClasses}>
        { this.props.categories && this.props.categories.length ?
                <div className="block-categories-carousel__slider">
                 {   this.props.categories.map((category) => (
                <div key={category.id} className="block-products-carousel__cell categories-height">
                    <Link to={`/lot/${category.id}/${category.lsCatName}`}>
                    <div className={containerClasses}>
        
        <div className="product-card__image">
                 <Img className="img-fluid-custom load-img-height" alt={category.lsCatImage} title={category.lsCatImage} src={`${process.env.PUBLIC_ENDPOINT}${process.env.LOT_IMG_PATH}${category.lsCatImage?
                                        category.lsCatImage : category.lsCatImage}`} unloader={ (<img
                                    src={`${process.env.PUBLIC_URL}/images/default.png`}
                                    className="img-fluid-custom default-img-height"/>)}/>
            </div>
        <div className="product-card__info">
                <span>{ (category.lsCatName.length > 50) ?
                        <span>{category.lsCatName.substring(0,50).concat('...')}</span>
                    : 
                        <span>{category.lsCatName}</span>
                    }</span>
        </div>
    </div>
    </Link>
    </div>
                 ) )}
                </div>
            : ''}
            </div>
        </div>
        </React.Fragment>
    );
    }
}


const mapStateToProps = (state, ownProps) => ({
    categories: getLotSaleCategories(state.lots)
})

export default connect(
    mapStateToProps
)(SiteLotCategories)

SiteLotCategories.propTypes = {
    layout: PropTypes.oneOf(['grid-4', 'grid-4-sm', 'grid-5', 'horizontal']),
    withSidebar: PropTypes.bool,
};

SiteLotCategories.defaultProps = {
    layout: 'grid-4',
    rows: 1,
    withSidebar: false,
};
