import {
  FETCH_REVIEWS,
TOTAL_REVIEWS_COUNT,
FETCH_REVIEWS_BEGIN,
FETCH_REVIEWS_END,
UPDATE_BEGIN,
UPDATE_END,
UPDATE_SINGLE_REVIEW,
ADD_SINGLE_REVIEW
    } from "./reviewActionTypes";

const initialState = {
    updating : false,
    reviews:[],
    loading:false,
    totalCount : 0
};

export default function reviewReducer(state = initialState, action) {
     switch (action.type) {
        case TOTAL_REVIEWS_COUNT:
            return { ...state,
                totalCount: action.count }; 
        case FETCH_REVIEWS:
            return { ...state,
                reviews: action.reviews }; 
        case FETCH_REVIEWS_BEGIN:
            return { ...state,
                loading: true }; 
        case FETCH_REVIEWS_END:
            return { ...state,
                loading: false };  
        case UPDATE_BEGIN:
            return { ...state,
                updating: true }; 
        case UPDATE_END:
            return { ...state,
                updating: false }; 
        case UPDATE_SINGLE_REVIEW: 
            const items = state.reviews.reduce((cartAcc, item) => {
                if (item.id === action.item.id) {
                    cartAcc.push({ ...item, ...action.item }) // Increment qty
                } else {
                    cartAcc.push(item)
                }

                return cartAcc
            }, []);
            return { ...state, items }
        case ADD_SINGLE_REVIEW:
            return { ...state, reviews: [...state.reviews, { ...action.review }] }    
        default:
            return state;
           
    }
}
