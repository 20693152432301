import {
    FETCH_SINGLE_CATEGORY,
    FETCH_CATEGORIES_BEGIN,FETCH_CATEGORIES_DONE,
    FETCH_FEATUREDCATEGORIES_BEGIN,FETCH_FEATUREDCATEGORIES_DONE,
    FETCH_TOPCATEGORIES_BEGIN,FETCH_TOPCATEGORIES_DONE,
    RECEIVE_CATEGORIES,ROOT_CATEGORY,RECEIVE_FEATURED_CATEGORIES,
RECEIVE_TOP_CATEGORIES } from "./categoriesActionTypes";


const initialState = {
     all_categories: [],
    featuredcategories:[],
    topcategories:[],
    rootCategoryMenu : [],
    manufacturers:[],
    industries:[],
    symbol: '$',
    category_details: [],
    rloading : true,
    floading : true,
    tloading : true,
};

export default function categoriesReducer(state = initialState, action) {
     switch (action.type) {
         case FETCH_CATEGORIES_BEGIN:
            return { ...state,
                rloading: true };
                case FETCH_CATEGORIES_DONE:
            return { ...state,
                rloading: false };
                case FETCH_FEATUREDCATEGORIES_BEGIN:
            return { ...state,
                floading: true };
                case FETCH_FEATUREDCATEGORIES_DONE:
            return { ...state,
                floading: false };
                case FETCH_TOPCATEGORIES_BEGIN:
            return { ...state,
                tloading: true };
                case FETCH_TOPCATEGORIES_DONE:
            return { ...state,
                tloading: false };
                case RECEIVE_CATEGORIES:
            return { ...state,
                all_categories: action.categories };
                case RECEIVE_FEATURED_CATEGORIES:
            return { ...state,
                featuredcategories: action.categories };
                case RECEIVE_TOP_CATEGORIES:
            return { ...state,
                topcategories: action.categories };
        case ROOT_CATEGORY:
            return { ...state,
                rootCategoryMenu: action.rootCategoryMenu };
        case FETCH_SINGLE_CATEGORY:
            if (state.categories.findIndex(category => category.id === action.categoryid) !== -1) {
                const singleItem = state.categories.reduce((itemAcc, category) => {
                    return category
                }, [])
                return { ...state,
                    category_details: singleItem };
            }
        default:
            return state;
    }
}
