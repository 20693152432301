// react
import React, {Component} from 'react';

// third-party
import { Helmet } from 'react-helmet';
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import { updateCustomer } from '../../store/user';
import SimpleReactValidator from 'simple-react-validator';
import classNames from 'classnames';

// data stubs
import theme from '../../data/theme';

class AccountPageProfile extends Component  {
    
     constructor(props) {
    super(props);
      this.state = {
     };
     this.validator = new SimpleReactValidator();
     this.setStateFromInput = this.setStateFromInput.bind(this);
    } 

    componentWillMount(){
        if(this.props.account_data){
             this.setState(this.props.account_data);
        }
    }

      setStateFromInput = (event) => {
        var obj = {};
        if(event.target.dataset.object){
             var key = event.target.dataset.object;
             var name = event.target.name;
             var value =  event.target.value;
                this.setState(state => {
                      state[key][name] = value;
                      return state;
                })
           }
           else{
               obj[event.target.name] = event.target.value;
               this.setState(obj);
        }
    }

     handleSubmit = (event) => {
    console.log(event);
    event.preventDefault();
    if (this.validator.allValid()) {
             this.props.dispatch(updateCustomer(this.state));
       
    } else {
              this.validator.showMessages();
              this.forceUpdate();
    }
  }


    render() { 
        const { updating } = this.props;
    let content;
     let loadSection;
    loadSection = () => {
      
         content = (<React.Fragment>
                                       <div className="form-group">
                                            <label htmlFor="login-email">First Name</label>
                                            <input
                                                id="register-firstname"
                                                type="text"
                                                name="custFirstName"
                                                className="form-control"
                                                placeholder="Enter first name"
                                                value={this.state.custFirstName} onChange={this.setStateFromInput} />
                                                    {this.validator.message('custFirstName', this.state.custFirstName, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="login-email">Last Name</label>
                                            <input
                                                id="register-lastname"
                                                type="text"
                                                name="custLastName"
                                                className="form-control"
                                                placeholder="Enter last name"
                                                value={this.state.custLastName} onChange={this.setStateFromInput} />
                                                    {this.validator.message('custLastName', this.state.custLastName, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="login-email">Email address</label>
                                            <input
                                                id="register-emailId"
                                                type="email"
                                                name="emailAddress"
                                                className="form-control"
                                                placeholder="Enter email"
                                                value={this.state.emailAddress} onChange={this.setStateFromInput} />
                                                    {this.validator.message('emailAddress', this.state.emailAddress, 'required|email')}
                                        </div>
                                         <div className="form-group">
                                            <label htmlFor="login-email">User Name</label>
                                            <input
                                                id="register-username"
                                                type="text"
                                                name="userName"
                                                className="form-control"
                                                placeholder="Enter user name"
                                                value={this.state.userName} onChange={this.setStateFromInput} />
                                                    {this.validator.message('userName', this.state.userName, 'required')}
                                        </div>

                                     
                                         
                                         <div className="form-group">
                                            <label htmlFor="login-email">Contact(Mobile)</label>
                                            <input
                                                id="register-mobile"
                                                type="text"
                                                name="mobileNumber"
                                                className="form-control"
                                                placeholder="Enter contact number"
                                                value={this.state.mobileNumber} onChange={this.setStateFromInput} />
                                                    {this.validator.message('mobileNumber', this.state.mobileNumber, 'required|phone')}
                                        </div>
                                         <div className="form-group">
                                            <label htmlFor="login-email">GSTIN</label>
                                            <input
                                                id="register-mobile"
                                                type="text"
                                                name="custGSTIN"
                                                className="form-control"
                                                placeholder="Enter GST"
                                                value={this.state.custGSTIN} onChange={this.setStateFromInput} />
                                                    {this.validator.message('custGSTIN', this.state.custGSTIN, 'required')}
                                        </div>
                                        <div className="form-group d--btn-block">
                                       
                                        <button type="submit" className={classNames('btn btn-primary d-flex-btn', {
                                        'btn-loading': updating,
                                    })}>
                                            Update
                                        </button>
                                        </div>
                                        </React.Fragment>)
     
   
    return content;
  }

  const account_detail = this.props.account_data;


    return (
        <div className="card">
            <Helmet>
                <title>{`Profile — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Edit Profile</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                 <form onSubmit={this.handleSubmit.bind(this)} noValidate>
                    {loadSection()}
                </form>
            </div>
        </div>
    );
}
}

function mapStateToProps(state) {
    return {
        account_data : state.user.account_detail,
        updating : state.user.updating
    }
}

 const mapDispatchToProps = dispatch => ({
      dispatch               
   })

export default connect(mapStateToProps,mapDispatchToProps)(AccountPageProfile);


