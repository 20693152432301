// react
import React, {Component} from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';
import Product from '../shared/Product';
import ProductTabs from './ProductTabs';

// blocks
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';
import {
    Quickview16Svg
} from '../../svg';
// widgets
import WidgetCategories from '../widgets/WidgetCategories';
import WidgetProducts from '../widgets/WidgetProducts';
import { connect } from 'react-redux';

// data stubs
import categories from '../../data/shopWidgetCategories';
import products from '../../data/shopProducts';
import theme from '../../data/theme';
import {getSingleProduct,fetchSingleProduct } from '../../store/products';
import {getProductItem,getBreadcrumbItemsFromLineage,trackPage } from "../../services";

class ShowProductPage extends Component {

constructor (props) {
        super (props)
    }

    componentWillMount(){
    	 let productid = this.props.match.params.productId;
    	  if(!this.props.item){
             this.props.dispatch(getSingleProduct(productid));
        }
    }

    componentWillUnmount(){
           this.props.dispatch(fetchSingleProduct({}));
    }

    render (){
    const { layout, sidebarPosition, match, item, breadcrumbItems,loading,trackPage,location } = this.props;
    
    let product;
    if (match.params.productId) {
        product = this.props.item;

    } else {
        product = {};
    }
    const breadcrumb = [ { value: 'Home', lineage: '' }, ...this.props.breadcrumbItems ];


    let content;
    function loadProduct(){
    product.quantity = product.quantityOrderMinimum; 
    trackPage(location.pathname + location.search);
    if (layout === 'sidebar') {
        console.log('if sidebar');
        const sidebar = (
            <div className="shop-layout__sidebar">
                <div className="block block-sidebar">
                    <div className="block-sidebar__item">
                        <WidgetCategories categories={categories} location="shop" />
                    </div>
                    <div className="block-sidebar__item d-none d-lg-block">
                        <WidgetProducts title="Latest Products" products={products.slice(0, 5)} />
                    </div>
                </div>
            </div>
        );

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === 'start' && sidebar}
                    <div className=" shop-layout__content">
                        <div className=" block">
                            <Product product={product} layout={layout} />
                            <ProductTabs withSidebar />
                        </div>

                        <BlockProductsCarousel title="Related Products" layout="grid-4-sm" products={products} withSidebar />
                    </div>
                    {sidebarPosition === 'end' && sidebar}
                </div>
            </div>
        );
    } else {
        console.log('ifelse sidebar');
        content = (
                <div className="block">
                    <div className="container">
                        <Product product={product} layout={layout} />
                        <ProductTabs  product={product} />
                    </div>
                </div>
        );
    }
    return content;
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${product ? product.name : 'Product'} — ${theme.name}`}</title>
                <meta name="og_title" property="og:title" content={product.prodmetatitle}/>
            <meta name="Keywords" content={product.tags}/>
            <meta name="Description" content={product.prodshortdesc}/>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
             {(item)?
            loadProduct() : 
             <React.Fragment>
                  {  this.props.loading ? 
                   
                   <div className="form-group btn-loading-center">
                        <button type="button" className={`btn btn-light btn-loading btn-xl btn-svg-icon`}>
                            <Quickview16Svg />
                        </button>
                    </div>
                    :
                    <div className="container">
                    <div className="not-found">
                            <div className="not-found__content">
                            <h1 className="not-found__title">Product Not Found</h1>

                            <p className="not-found__text">
                                We can&apos;t seem to find the product you&apos;re looking for.
                            </p>

                            <Link to="/post-your-request" className="btn btn-primary btn-sm">Post Your Requirements</Link>
                            </div>
                        </div>
                </div> 
                }
                 </React.Fragment>
               }
        </React.Fragment>
    );
}
}

ShowProductPage.propTypes = {
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShowProductPage.defaultProps = {
    layout: 'standard',
    sidebarPosition: 'start',
};

const mapStateToProps = (state, ownProps) => {
    let productId = ownProps.match.params.productId;
    return {
        loading : state.products.sloading,
        item: getProductItem(state.products,productId),
        breadcrumbItems : getBreadcrumbItemsFromLineage(state.products,state.categories,productId),
        trackPage : trackPage
    }
}

 const mapDispatchToProps = dispatch => ({
      dispatch               
   })

export default connect(mapStateToProps, mapDispatchToProps) (ShowProductPage);

