import queryString from 'query-string';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';


export const trackPage = (path) => {
    ReactGA.pageview(path);
    ReactPixel.pageView(); 
}

// Get Unique categories from filters Data
export const getCategoriesFilters = (filters) => {
    var uniqueCategories = [];
    if(filters && filters.facets){
    filters.facets.categories.map((category, index) => {
       if (uniqueCategories.indexOf(category.name) === -1) {
            uniqueCategories.push({name : category.name, count :category.count,type: 'parent', checked: false,disabled: false});
        }
        });   
    }
    return uniqueCategories;
}

// Get Unique brands from Json Data
export const getManufacturerFilters = (filters) => {
    var uniqueManufac = [];
    if(filters && filters.facets){
    filters.facets.manufacturer.map((manufac, index) => {
       if (uniqueManufac.indexOf(manufac.name) === -1) {
            uniqueManufac.push({name : manufac.name, count :manufac.count,type: 'parent', checked: false,disabled: false});
        }
        });   
    }
    return uniqueManufac;
}

// Get Unique Colors from Json Data
export const getAttrsFilters = (filters) => {
    var uniqueAttrs = [];
    if(filters && filters.facets){
    filters.facets.attrs.map((attr, index) => {
       if (uniqueAttrs.indexOf(attr.name) === -1) {
            uniqueAttrs.push({name : attr.name, count :attr.count, type:'name', subAttributes : (attr.subAttributes ? attr.subAttributes : [])  });
       }
    });   
    }
    return uniqueAttrs;
}

// Get Minimum and Maximum Prices from Json Data
export const getMinMaxPrice = (products) => {
    let min = 0, max = 1000;
    if(products.length){
    products.map((product, index) => {
        let v = Math.round(product.price);
        min = (v < min) ? v : min;
        max = (v > max) ? v : max;
    });
    }

    return {'min':min, 'max':max};
}

export const getProductItem= (products, productId) => {
     if(products.all_products.length && products.all_products.findIndex(el => el.id === productId) !== -1){
         return products.all_products.find(el => el.id === productId)
     } else if(products.product_details && Object.keys(products.product_details).length){
          return products.product_details;
     }
     else
        return '';
}

export const getBreadcrumbItemsFromLineage = (products, categories, productId) => {
    if(categories.rootCategoryMenu && categories.rootCategoryMenu.length){
     if(products.all_products.length && products.all_products.findIndex(el => el.id === productId) !== -1){
         let product = products.all_products.find(el => el.id === productId);
         let pathname = product.lineage2 + (product.categorisefurl ? '/'+product.categorisefurl[0] : '') ;
         return getBreadcrumbsDetails(categories, pathname);
     } else if(products.product_details && Object.keys(products.product_details).length){
         let pathname = products.product_details.lineage2 + (products.product_details.categorisefurl ? products.product_details.categorisefurl[0] : '');
          return getBreadcrumbsDetails(categories, pathname);
         // return data.product_details;
     }
     else
        return '';
    }
    else
        return '';
}

export const getOrderDetail= (data, orderId) => {
     if(data.orders.length && data.orders.findIndex(el => el.id === orderId) !== -1){
         return data.orders.find(el => el.id === orderId)
     } else if(Object.keys(data.order_details).length){
          return data.order_details;
     }
     else
        return '';
}


export const getSubCategories = (categories, { featured,top, sortBy }) => {
   return categories.filter(category => {
        return true;
    }).sort((cat1, cat2) => {
        if (sortBy === 'AscOrder') {
            return cat1.value.localeCompare(cat2.value);
        } else if (sortBy === 'DescOrder') {
            return cat2.value.localeCompare(cat1.value);
        } 
        // else{
        //     return cat2.id > cat1.id ? -1 : 1;
        // }
    });
}

export const getBreadcrumbsDetails = (categories, pathname) => {
    if(categories.rootCategoryMenu && categories.rootCategoryMenu.length && pathname){
        let i = 0;
        let categoryNames = pathname.split('/').filter(splits => { return splits;});
        let category;
        let categories_nxt = categories.rootCategoryMenu.length ? categories.rootCategoryMenu[0] : {};
        let breadcrumbItems;
        breadcrumbItems = categoryNames.map((name,index) => {
            if(name && index >0 && categories_nxt && categories_nxt.children && categories_nxt.children.length){
            categories_nxt = altFind(categories_nxt.children, function(category) {
                            return category.value2.toLowerCase() === name.toLowerCase();
                         });
            }
            if(index !=0)
                return categories_nxt;
        });
         breadcrumbItems = breadcrumbItems.map(category => {
            return category ? { ...category , type: 'category' } : false;
         });
        return breadcrumbItems.filter(category => {
            return category ? category : false;
         });
    }
    else
    {
        return '';
    }
}

export const getBreadcrumbItems = (categories, pathname) => {
    if(categories.rootCategoryMenu && categories.rootCategoryMenu.length && pathname){
        let i = 0;
        let categoryNames = pathname.split('/').filter(splits => { return splits;});
        let category;
        let categories_nxt = categories.rootCategoryMenu.length ? categories.rootCategoryMenu[0] : {};
        let breadcrumbItems;
        breadcrumbItems = categoryNames.map((name,index) => {    
            if(name && index >0 && categories_nxt && categories_nxt.children && categories_nxt.children.length){
            categories_nxt = altFind(categories_nxt.children, function(category) {
                            return category.value2.toLowerCase() === name.toLowerCase();
                         });
            }
            if(index !=0 && categoryNames.length - 1 != index)
                return categories_nxt;
        });
        breadcrumbItems = breadcrumbItems.map(category => {
            return category ? { ...category , type: 'category' } : false;
         });
        return breadcrumbItems.filter(category => {
            return category ? category : false;
         });
    }
    else
    {
        return '';
    }
}

export const checkIsIndustry = (pathname,search) => {
    return pathname.indexOf('industry/') != -1;
}    

export const checkIsLot = (pathname,search) => {
    return pathname.indexOf('lotitems/') != -1;
}    


export const getSubCategoryFromParent = (categories, pathname,search) => {
    if(categories.rootCategoryMenu && categories.rootCategoryMenu.length && pathname.indexOf('products/') != -1){
        let i = 0;
        let categoryNames = pathname.split('/').filter(splits => { return splits;});
        let category;
        let categories_nxt = categories.rootCategoryMenu.length ? categories.rootCategoryMenu[0] : {};
        category = categoryNames.map((name,index) => {

            if(name && index >0 && categories_nxt && categories_nxt.children && categories_nxt.children.length){
            categories_nxt = altFind(categories_nxt.children, function(category) {
                            return category.value2.toLowerCase() === name.toLowerCase();
                         });
            }
            if(categoryNames.length - 1 == index){
                return categories_nxt ? categories_nxt : {};
            }
        });
        return altFind(category, function(ctg) {
                            return ctg ? ctg.id : false;
        });
    }
    else if(queryString.parse(search) && (queryString.parse(search).query || queryString.parse(search).industry))
    {
        return 'queryMyProducts';
    }
    else 
    {
        return '';
    }
}

export const loadAsyncCategories = (categories) => {
    return  (dispatch) => {
        return new Promise((resolve, reject) => {
            if(categories.rootCategoryMenu.length){
                return resolve(categories.rootCategoryMenu[0].children.filter(category => {
                return true;
                }));
            }
            else
            {
                return reject([]);
            }
        });
    }
}


export const getCategoriesMenu = (categories) => {

     if(categories.rootCategoryMenu.length){
        return categories.rootCategoryMenu[0].children.filter(category => {
        return true;
    })
    }
     else
        return [];
   
}

export const getKeyBasedProducts = (products,key,keyparam) => {
     if(products.keybasedproducts[keyparam] && products.keybasedproducts[keyparam].length){
        return products.keybasedproducts[keyparam].filter(product => {
        return true;
    });
    }
     else{
        return [];
     }
   
}

export const getLotSaleCategories= (categories) => {
    if(categories && categories.lotcategories && categories.lotcategories.length){
        return categories.lotcategories.filter(category => {
        return true;
   });
   }
    else{
       return [];
    }
  
}

export const getLotsFromCategories = (categories) => {
    if(categories && categories.categorylots && categories.categorylots.length){
        return categories.categorylots.filter(category => {
            return true;
   });
   }
    else{
       return [];
    }
  
}

export const getLotBreadcrumb = (params) => {
    
        return [
            {
                value: 'Lot Sale',
                lineage: '/lotsales'
            },
            {
                value: params.lotmainname,
                lineage: '/lot/'+params.lotmainid+'/'+params.lotmainname
            }
        ]
}

export const getVisibleProducts = (products) => {
    if(products.all_products.length){
    return products.all_products.filter(product => {
        return true;
    });
    }
    else
        return [];
}




export const getBreadCrumbUsingCategory = (categoryname) => {
    
}

export const getManufacturers = (manufacturers) => {
    if(manufacturers && manufacturers.items.length){
        return manufacturers.items.filter(mfg => {
            return mfg.count;
        }).sort((mfg1, mfg2) => {
            return mfg1.value.localeCompare(mfg2.value);
        });
    }
    else
        return [];   
}

export const getIndustries = (categories) => {
    if(categories.industries.length){
        return categories.industries.filter(category => {
            return category.count;
        });
    }
    else
        return [];   
}

export const getFeaturedIndustries = (industries) => {
    if(industries.featuredindustries.length){
        return industries.featuredindustries.filter(industry => {
            return true;
        });
    }
    else
        return [];   
}

export const getAllCategoriesItems = (categories,  { featured,top, sortBy }) => {
    if(categories.all_categories.length){
        return categories.all_categories.filter(category => {
            return true;
        }).sort((cat1, cat2) => {
        if (sortBy === 'AscOrder') {
            return cat1.value.localeCompare(cat2.value);
        } else if (sortBy === 'DescOrder') {
            return cat2.value.localeCompare(cat1.value);
        } 
        // else{
        //     return cat2.id > cat1.id ? -1 : 1;
        // }
    });
    }
    else
        return [];   
}


export const getTopCategories = (categories, { featured,top, sortBy }) => {
    if(categories.topcategories.length){
      return categories.topcategories.filter(category => {
        return true;
    });
    }
    else
        return [];
  
}


export const getCartTotal = cartItems => {
    var total = 0;
    for(var i=0; i<cartItems.length; i++){
        total += parseInt(cartItems[i].qty, 10)*parseInt((cartItems[i].price.replace('SAR','')), 10);
    }
    return total;
}

// Get Trending Tag wise Collection
export const getTrendingTagCollection = (products, type, tag) => {
    const items = products.filter(product => {
        return product.category === type && product.tags.includes(tag);
    })
    return items.slice(0,8)
}

// Get Trending Collection
export const getTrendingCollection = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    })
    return items.slice(0,8)
}

// Get Special 5 Collection
export const getSpecialCollection = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    })
    return items.slice(0,5)
}

// Get TOP Collection
export const getTopCollection = products => {
    const items = products.filter(product => {
        return product.rating > 4;
    })
    return items.slice(0,8)
}

// Get New Products
export const getNewProducts = (products, type) => {
    const items = products.filter(product => {
        return product.new === true && product.category === type;
    })

    return items.slice(0,8)
}

// Get Related Items
export const getRelatedItems = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    })

    return items.slice(0,4)

}

// Get Best Seller Furniture
export const getBestSellerProducts = (products, type) => {
    const items = products.filter(product => {
        return product.sale === true && product.category === type;
    })

    return items.slice(0,8)
}

// Get Best Seller
export const getBestSeller = products => {
    const items = products.filter(product => {
        return product.sale === true;
    })

    return items.slice(0,8)
}

// Get Mens Wear
export const getMensWear = products => {
    const items = products.filter(product => {
        return product.category === 'men';
    })

    return items.slice(0,8)
}

// Get Womens Wear
export const getWomensWear = products => {
    const items = products.filter(product => {
        return product.category === 'women';
    })

    return items.slice(0,8)
}

// Get Single Product
export const getSingleItem = (products, id) => {

    const items = products.find((element) => {
        return element.id === id;
    })
    return items;
}

export const altFind = (arr, callback) => {
  for (var i = 0; i < arr.length; i++) {
    var match = callback(arr[i]);
    if (match) {
      return arr[i];
    }
  }
}


