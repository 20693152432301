// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Img from 'react-image';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import Rating from './Rating';
import { cartAddItem,addToCart } from '../../store/cart';
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from '../../svg';
import { compareAddItem } from '../../store/compare';
import { quickviewOpen } from '../../store/quickview';
import { wishlistAddItem,addToWishlist } from '../../store/wishlist';


function StaticLotItem(props) {
    const {
        lot,
        layout,
        handleOpenModal,
        currency
    } = props;
    const containerClasses = classNames('product-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });
    let image;

    if (lot.lsImage) {
        image = (
            <div className="lot-sales__static_image lot-sales__broderleft">
                <Img className="img-fluid-custom" src={process.env.PUBLIC_ENDPOINT+process.env.LOT_IMG_PATH+(lot.lsImage?
                                        lot.lsImage : lot.lsImage)} unloader={ (<img
                                    src={`${process.env.PUBLIC_URL}/images/default.png`}
                                    className="img-fluid-custom"/>)}/>
            </div>
        );
    }
    else
    {
        image = (
         <div className="lot-sales__static_image lot-sales__broderleft">
             <div className="card-image__default">
                <img src={`${process.env.PUBLIC_URL}/images/default.png`}/>
                </div>
            </div>
        )
    }

  

    return (
        <div className={containerClasses}>
             {image}
            <div className="lot-sales__desc lot-sales__broderleft">
                <div className="product-card__name">
                    {lot.lsName}
                </div>
                <p>
                    <span>{'Lot ID'}</span> : <span>{lot.lsId}</span>
                 </p>
                 <p>
                    <span>{'Condition'}</span> : <span>{lot.lsCondition}</span>
                 </p>
                 <p>
                    <span>{'Loc'}</span> : <span>{lot.lsLocation}</span>
                 </p>
                 <p>
                    <span>{'AGE'}</span> : <span>{lot.lsAge}</span>
                 </p>
                 <p>
                    <span>{'Lot Item Details'}</span> : <span className="lot-sales-txt-area">{lot.lsItemDetails}</span>
                 </p>
            </div>
           
            <div className="lot-sales__pdf lot-sales__broderleft">
            <div className="product-card__prices">
                <span className="product-card__new-price"><Currency value={lot[currency.lotDiscountPriceLabel]}/></span>
                {' '}
                <span className="product-card__old-price"><Currency value={lot[currency.lotPriceLabel]}/></span>
                {' '}
                <span className="product-card__old-discount">{lot.lsDisPercent}% OFF</span>
            </div>
            </div>
            <div className="lot-sales__actions">
            <div className="product-card__buttons">
                                <button onClick={(e) => handleOpenModal(e,lot, 'negotiate')}
                                    type="button"
                                    className='btn btn-secondary product-card__addtocart product-card__addtocart--list'
                                >
                                    &nbsp;&nbsp;&nbsp;Negotiate&nbsp;&nbsp;&nbsp;   
                                </button>
                </div>
                <div className="product-card__buttons">
                                <button onClick={(e) => handleOpenModal(e,lot, 'enquiry')}
                                    type="button"
                                    className='btn btn-secondary product-card__addtocart product-card__addtocart--list'
                                >
                                    Send Enquiry
                                </button>
                </div>
            </div>
        </div>
    );
}

StaticLotItem.propTypes = {
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = (state) => {
    return { 
         login_status :  state.user.login_status,
        currentState : state,
        currency: state.currency };
};

const mapDispatchToProps = {
    addToCart,
    cartAddItem,
    wishlistAddItem,
    addToWishlist,
    compareAddItem,
    quickviewOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StaticLotItem);
