// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// application
import BlogCommentsList from './BlogCommentsList';

// data stubs
import comments from '../../data/blogPostComments';
import posts from '../../data/blogPosts';


export default function BlogPost1(props) {
    const { layout } = props;

    const postClasses = classNames('post__content typography', {
        'typography--expanded': layout === 'full',
    });

    const relatedPost = posts[0];

    return (
        <div className={`block post post--layout--${layout}`}>
            <div className={`post__header post-header post-header--layout--${layout}`}>
                <h1 className="post-header__title">{relatedPost.title}</h1>
                
            </div>

            <div className="post__featured">
                <Link to="/">
                    <img src={relatedPost.image} alt="" />
                </Link>
            </div>

            <div className={postClasses}>
                 <p>
                    Established in the year 2018 at Coimbatore, Tamil Nadu “AVA Automation Industry” is a partnership based firm, engaged as the foremost Manufacturer of Stainless Steel Counter, Food processing machine and many more. Their products are high in demand due to their premium quality and affordable prices. Furthermore, they ensure to timely deliver the products to their clients, through which they have gained a huge clients base in the market. They also render Machine Repairing Services.
                </p>

                <h4>HOW BUYER APPROACHED GOOSPARES</h4>
                <ol>
                    <li>
                       Goospares also uses digital platform for online marketing to ease our customer efforts
                    </li>
                    <li>
                       Buyers and sellers of various verticals approach Goospares through the social networking
                    </li>
                    <li>
                        Ava automation industry used social media platform to connect with Goospares
                    </li>
                </ol>
                <h4>BUYER REQUIREMENT</h4>
                <p>
                    AVA AUTOMATION INDUSTRY is a manufacturer of food processing machines, and has regular requirements for Induction motor and gear heads which used to the production phase.
                </p>
                <h4>MARKETING PHASE</h4>
                <p>
                   Marketing team from Goospares approached AVA AUTOMATION to gather the detailed product description and requirement. Team Goospares rolled out a detailed market research to analyze the current market value of the product enquired. Goal of Goospares was to source for the right seller with highly competitive price. 
                </p>
                <p>
                    Goospares has a team of highly qualified sourcing engineers who helped in identifying the right supplier. This includes, sourcing the same product from multiple suppliers for pricing comparison. Supplier Optimization helped Goospares to choose the most cost effective and best quality suppliers from the list of suppliers and traders approached us with their quotations.
                </p>
                <p>
                    After identifying the right supplier, Goospares team had requested for physical verification to ensure we supply the best quality of the product to AVA Automation. 
                </p>
                 <h4>WHY BUYER SETTLE UPON GOOSPARES</h4>
                <ol>
                    <li>
                      Competitive price
                    </li>
                    <li>
                        Quality of product 
                    </li>
                    <li>
                        On time delivery
                    </li>
                    <li>
                         Client friendly attitude
                    </li>
                    <li>
                        Doorstep delivery
                    </li>
                </ol>
                <h4>COST & LOGISTICS</h4>
                <ul>
                    <li>
                      With the detailed market analysis Goospares was able to conclude the product value of Induction Motor and Gear Head, wherein Goospares supplied the product at a very competitive price with a high discount of 33% from the market value. 
                    </li>
                    <li>
                        Through our logistics partner for Goospares (Ecommerce portal), which enabled us to deliver the product on door steps with hassle free shipment.
                    </li>
                    <li>
                       With mutual agreement on the payment and delivery terms the transaction was successfully processed
                    </li>
                   
                </ul>
                <hr />
                <h4>FEEDBACK AND NEW REQUIREMENT</h4>
                <p>
                    Goospares believes in customers feedbacks and their opinion is truly valued and hence as a process of the business, we connected back to AVA Automation for feedback session which helped convince the customers to come back for new enquires. 
                </p>
            </div>
        </div>
    );
}

BlogPost1.propTypes = {
    /**
     * post layout
     * one of ['classic', 'full'] (default: 'classic')
     */
    layout: PropTypes.oneOf(['classic', 'full']),
};

BlogPost1.defaultProps = {
    layout: 'classic',
};
