import { toast } from 'react-toastify';
import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_UPDATE_QUANTITIES } from './cartActionTypes';
import * as types from './cartActionTypes'
import axios from 'axios';
import React from 'react';
import ReactGA from 'react-ga';

const userdata = localStorage.getItem('gouserdata') ? JSON.parse(localStorage.getItem('gouserdata')) : {};

var axios_instance = '';

if(userdata.token)
  axios_instance = axios.create({headers: {authToken: userdata.token}});
else 
  axios_instance = axios.create();


export function cartAddItemSuccess(product, options = [], quantity = 1) {
//    toast.success(`Product "${product.name}" added to cart!`);

    return {
        type: CART_ADD_ITEM,
        product,
        options,
        quantity,
    };
}

export function cartRemoveItemSuccess(itemId) {
    return {
        type: CART_REMOVE_ITEM,
        itemId,
    };
}

export function cartUpdateQuantitiesSuccess(quantities) {
    return {
        type: CART_UPDATE_QUANTITIES,
        quantities,
    };
}

export function cartAddItem(product, options = [], quantity = 1) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartAddItemSuccess(product, options, quantity));
                resolve();
            }, 2000);
        })
    );
}

export function cartRemoveItem(itemId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemoveItemSuccess(itemId));
                resolve();
            }, 2000);
        })
    );
}

export function cartUpdateQuantities(quantities) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateQuantitiesSuccess(quantities));
                resolve();
            }, 2000);
        })
    );
}



export const receiveCartList = (items) => ({
    type: types.LIST_CART,
    items
});



export const checkOrderReady= (readyToOrder) => ({
    type: types.READY_TO_ORDER,
    readyToOrder
});


export const fetchMinCartValue = (minValue) => ({
    type: types.CART_MINIMUM_VALUE,
    minValue
});

export const cartUpdateBegin = () => ({
    type: types.CART_UPDATE_BEGIN
});

export const cartUpdateEnd = () => ({
    type: types.CART_UPDATE_END
});

export const resetCart = () => ({
    type: types.RESET_CART
});

export const receiveCartINRTotal = (total) => ({
    type: types.CART_INR_TOTAL,
    total
});

export const receiveCartINRSubTotal= (subtotal) => ({
    type: types.CART_INR_SUBTOTAL,
    subtotal
});

export const receiveCartUSDTotal= (total) => ({
    type: types.CART_USD_TOTAL,
    total
});
export const receiveCartUSDSubTotal= (subtotal) => ({
    type: types.CART_USD_SUBTOTAL,
    subtotal
});
export const receiveCartSARTotal= (total) => ({
    type: types.CART_SAR_TOTAL,
    total
});
export const receiveCartSARSubTotal= (subtotal) => ({
    type: types.CART_SAR_SUBTOTAL,
    subtotal
});

export const listCart = (currency) => (dispatch) => {
  dispatch(cartUpdateBegin());
   axios_instance.get(process.env.PUBLIC_ENDPOINT + "/shop/shoppingCart?store=DEFAULT&cartCode="+localStorage.getItem('myvmarketcartcode'))
     .then(response => response.data)
      .then((data) => {
      dispatch(receiveCartList(data.shoppingCartItems));  
      dispatch(fetchCartTotals(data));
      dispatch(cartUpdateEnd());
      return data;
     })
};

export const removeFromCart = product => (dispatch) => {
    toast.error("Item Removed from Cart");
    dispatch({
        type: types.REMOVE_FROM_CART,
        product
    })
};

export const fetchCartTotals = (data) => (dispatch) => {
      dispatch(receiveCartINRTotal(data.total));
      dispatch(receiveCartINRSubTotal(data.subTotal));
      dispatch(receiveCartUSDTotal(data.usdTotal));
      dispatch(receiveCartUSDSubTotal(data.subUsdTotal));
      dispatch(receiveCartSARTotal(data.sarTotal));
      dispatch(receiveCartSARSubTotal(data.subSarTotal));
      dispatch(fetchMinCartValue(data.minOrder));
      dispatch(checkOrderReady(data.readyToOrder));
}

export const removeFromCartState = (product) => {
      return (dispatch) => (
        new Promise((resolve,reject) => {
    product.loading = true;
    var cartCode = product.code;
    var lineItemId = product.id;
    dispatch(cartUpdateBegin());
   axios_instance.post(process.env.PUBLIC_ENDPOINT + "/shop/removeitemcart?lang=en&store=DEFAULT&lineItemId="+lineItemId+"&cartCode="+cartCode ).then(response => response.data)
        .then((data) => {
        product.loading = false;
        if(!data.total || !data.subTotal){
          localStorage.setItem('myvmarketcartcode','');
        }
        dispatch(fetchCartTotals(data));
        dispatch(removeFromCart(product));
        dispatch(cartUpdateEnd());
        resolve();
      })
      .catch(function (error) {
         dispatch(cartUpdateEnd());
         reject();
        // console.log(error);
      });
       })
 );
}


//it seems that I should probably use this as the basis for "Cart"
export const addToCart = (product,qty,state) => {

   console.log(product,qty);
  var cartObject = '';
   return (dispatch) => (
        new Promise((resolve,reject) => {
 
  var productId = product.productId ? parseInt(product.productId) : parseInt(product.id);
  var extension = '';
  if (state.cart.items.findIndex(item => parseInt(item.productId) === parseInt(productId)) !== -1) {
      const items = state.cart.items.reduce((cartAcc, product) => {
           console.log(cartAcc, product)
          if (parseInt(product.productId) === parseInt(productId)) {
              cartAcc.push(product); // Increment qty
          }
          return cartAcc; 
      }, []);
      cartObject = Object.assign({},items[0]);
      cartObject.quantity = cartObject.quantity + qty;
      extension = "/shop/updateShoppingCartItem?lang=en&store="+product.store;
  }
  else
  {

      cartObject = {"productId": productId,"quantity": qty};
    if(localStorage.getItem('myvmarketcartcode'))
      cartObject.code = localStorage.getItem('myvmarketcartcode');
    if(localStorage.getItem('gouserdata')){
      extension = "/shop/additemtocart?lang=en&store="+product.store;
    }
    else
      extension = "/shop/additemtocart?lang=en&store="+product.store;

  }
  if(cartObject.quantity > product.quantityOrderMaximum){
    if (! toast.isActive(toastIdMin)) {
    toast.warn(<IconDelete quantity={product.quantityOrderMaximum} label={'maximum'}/>,{toastId: toastIdMin});
}
resolve();
  }
  else if(cartObject.quantity < product.quantityOrderMinimum){
    if (! toast.isActive(toastIdMin)) {
        toast.warn(<IconDelete quantity={product.quantityOrderMinimum} label={'minimum'}/>,{toastId: toastIdMin});
    }
    resolve();
  }
  else
  {
  dispatch(cartUpdateBegin());
  axios_instance.post(process.env.PUBLIC_ENDPOINT + extension ,cartObject ).then(response => response.data)
    .then((data) => {
       localStorage.setItem('myvmarketcartcode',data.code);
       if (! toast.isActive(toastIdInc))
           toast.success("Item Added to Cart",{toastId: toastIdInc});
           ReactGA.event({
            category: 'Cart',
            action: 'Add to cart',
            label: 'INR',
            value: Number(data.total)
          });
          dispatch(cartAddItemSuccess(product, [], qty));
         dispatch(fetchCartTotals(data));
       if(cartObject.id){
         var index =  data.shoppingCartItems.findIndex(item => parseInt(item.id) === parseInt(cartObject.id))
         dispatch(updateToCart(data.shoppingCartItems[index]));
       }
       else
       {
         var index =  data.shoppingCartItems.findIndex(item => parseInt(item.productId) === parseInt(cartObject.productId));
         dispatch(addToCartState(data.shoppingCartItems[index]));
       }
       dispatch(cartUpdateEnd());
       resolve();
  })
  .catch(function (error) {
    reject();
    dispatch(cartUpdateEnd());
    // console.log(error);
  });
}
  })
 );
}

export const addToCartAndRemoveWishlist = (product,qty) => (dispatch) => {

   // dispatch(removeFromWishlist(product));
    dispatch(addToCart(product, qty));
}

export const addToCartState = (item) => ({
    type: types.ADD_TO_CART,
    item
});

export const addToCartUnsafe = (item, qty) => ({
    type: types.ADD_TO_CART,
    item,
    qty
});


export const updateToCart = (item) => ({
    type: types.UPDATE_TO_CART,
    item
});


export const incrementQty = (product,qty) => (dispatch) => {
  console.log(qty);
  product.loading = true;
  var checkQuantity = product.quantity + qty;
    if(checkQuantity > 0 && checkQuantity <= product.quantityOrderMaximum){
      product.quantity = checkQuantity;
  var itemId = product.id;
  dispatch(cartUpdateBegin());
   axios_instance.post(process.env.PUBLIC_ENDPOINT + "/shop/updateShoppingCartItem?lang=en&store=DEFAULT",product ).then(response => response.data)
        .then((data) => {
          product.loading = false;
          if (! toast.isActive(toastIdInc))
           toast.success("Item Added to Cart",{toastId: toastIdInc});
           dispatch(fetchCartTotals(data));
           var index =  data.shoppingCartItems.findIndex(item => item.id === itemId)
           dispatch(updateToCart(data.shoppingCartItems[index]));
          dispatch(cartUpdateEnd());
           
      })
      .catch(function (error) {
        product.loading = false;
       dispatch(cartUpdateEnd());
      });
    }
    else
    {
      product.loading = false;
       if (! toast.isActive(toastIdMin))
        toast.warn(<IconDelete quantity={product.quantityOrderMaximum} label={'maximum'}/>,{toastId: toastIdMin});
    }
}
const IconDelete = ({quantity, label}) => <div><span>Item quantity should be {label} {quantity}. Item can be removed from cart using&nbsp;<button className="in-button"><i className="fa fa-times"></i></button>&nbsp;action</span></div>;

const toastIdDec = 'dec';
const toastIdInc = 'inc';
const toastIdMin = 'mincart';

export const decrementQty = (product,qty) => (dispatch) => {
  console.log(qty);
    product.loading = true;
    var checkQuantity = product.quantity - qty;
    if(checkQuantity > 0 && checkQuantity >= product.quantityOrderMinimum){
      product.quantity = checkQuantity;
    var itemId = product.id;
    dispatch(cartUpdateBegin());
   axios_instance.post(process.env.PUBLIC_ENDPOINT + "/shop/updateShoppingCartItem?lang=en&store=DEFAULT",product ).then(response => response.data)
        .then((data) => {
           product.loading = false;
           if (! toast.isActive(toastIdDec))
              toast.warn("Item Decrement Qty to Cart",{toastId: toastIdDec});
           dispatch(fetchCartTotals(data));
           var index =  data.shoppingCartItems.findIndex(item => item.id === itemId)
           dispatch(updateToCart(data.shoppingCartItems[index]));
           dispatch(cartUpdateEnd());
      })
      .catch(function (error) {
        product.loading = false;
        dispatch(cartUpdateEnd());
        // console.log(error);
      });
  }
  else
  {
     product.loading = false;
    if (! toast.isActive(toastIdMin))
        toast.warn(<IconDelete quantity={product.quantityOrderMinimum} label={'minimum'}/>,{toastId: toastIdMin});
  }
};



