import {
    FETCH_INDUSTRIES_BEGIN,
FETCH_INDUSTRIES_DONE,
    FETCH_FEATUREDINDUSTRIES_BEGIN,
FETCH_FEATUREDINDUSTRIES_DONE,
RECEIVE_FEATURED_INDUSTRIES,
RECEIVE_INDUSTRIES } from "./industriesActionTypes";


const initialState = {
     all_industries: [],
    featuredindustries:[],
    rloading : true,
    floading : true
};

export default function cartReducer(state = initialState, action) {
     switch (action.type) {
         case FETCH_INDUSTRIES_BEGIN:
            return { ...state,
                rloading: true };
                case FETCH_INDUSTRIES_DONE:
            return { ...state,
                rloading: false };
                case FETCH_FEATUREDINDUSTRIES_BEGIN:
            return { ...state,
                floading: true };
                case FETCH_FEATUREDINDUSTRIES_DONE:
            return { ...state,
                floading: false };
                case RECEIVE_INDUSTRIES:
            return { ...state,
                all_industries: action.industries };
                case RECEIVE_FEATURED_INDUSTRIES:
            return { ...state,
                featuredindustries: action.industries };
            default:
                return state;  
        }
}
