// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

import { trackPage } from '../../services';

function SiteVisionMission(props) {

    trackPage(props.location.pathname+props.location.search);
    

    const breadcrumb = [
        { value: 'Home', lineage: '' },
        { value: 'Vision & Mision', lineage: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Online Buy & Sell Industrial Spare Parts and MRO Supply - ${theme.name}`}</title>
                <meta name="Description" content={`Goospares - One stop destination for Industrial Spares for  seller and buyer to trade in excess, unused maintenance spare parts.`}/>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title">Our Vision</h1>
                        </div>
                        <div className="document__content typography">
                            <p>
                                To emerge as the world’s leading virtual warehouse for Industrial Goods and Service Equipment’s and to be the one stop destination for the sellers and buyers to trade in excess, unused and obsolete inventory. In provision of its service, GOOSPARES would commit to the following goals.
                            </p>

                            <ul>
                                <li><h6>Sellers</h6>
                                    To enable the sellers to liquidate their excess, unused and obsolete inventory at a fair value
                                </li>
                                <li><h6>Buyers</h6>
                                    To procure quality products at a competitive price
                                </li>
                                <li><h6>Economy</h6>
                                    To add value to the scarce resources and help in the capital formation of the economy

                                </li>
                                <li><h6>Society</h6>
                                    To contribute to the development of social capital and be a socially responsible organization

                                </li>
                                <li><h6>Planet</h6>	
                                		Being a globally responsible company that promotes the values of Reduce,Reuse and Recycle
                                </li>
                                <li><h6>People</h6>
                                    To develop a great place to work culture where leadership is fostered, talent is rewarded, and where core values are translated into action
                                </li>
                                <li><h6>Portfolio</h6>
                                    To constantly venture into new segments in the Industrial and Service sector to expand the product base at our virtual warehouse

                                </li>
                                <li><h6>Stakeholders</h6>
                                    Nurturing and building relationship with the stakeholders to ensure win -win situation for all the parties

                                </li>
                            </ul>
                            <hr/>
                        </div>
                        <br/>
                        <br/>
                        <div className="document__header">
                            <h1 className="document__title">Our Mission</h1>
                        </div>
                        <div className="document__content typography">
                            <p>
                                The mission statement declares our purpose as a company and it serves as the standard against which we weigh our actions and decisions
                            </p>

                            <ul>
                                <li>
                                    To enable the sellers of excess inventory, realize more than scrap value

                                </li>
                                <li>
                                    To enable the buyers, procure quality products at a price substantially lower than the product’s market price
                                </li>
                                <li>
                                    To extensively use technology to ensure that excess inventory at one place finds its relevant application in another place

                                </li>
                                <li>
                                   To ensure that the actions of the organization contribute to the socio-economic development

                                </li>
                                <li>
                                	To contribute to the sustainable development of the planet by promoting the values of Reduce, Reuse and Recycle
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SiteVisionMission;
