import {
    REGISTER_USER,
    LOGIN_USER,
    REGISTER_BEGIN,
REGISTER_END,
LOGIN_BEGIN,
LOGIN_END,
REGISTER_STATUS,
LOGOUT_USER,
LOGIN_STATUS,
ACCOUNT_DETAIL,
UPDATE_START,
UPDATE_END,
FETCH_ORDERS,
FETCH_SINGLE_ORDER_BEGIN,
FETCH_SINGLE_ORDER_DONE,
FETCH_SINGLE_ORDER,
COMMIT_ORDER_BEGIN,
COMMIT_ORDER_END
 } from "./userActionTypes";

const userdata = localStorage.getItem('gouserdata') ? JSON.parse(localStorage.getItem('gouserdata')) : {};

const initialState = {
    data: userdata,
    account_detail: '',
    register_loading : false,
    updating:false,
    login_loading : false,
    register_status : 'init',
    commit_order_loading : false,
    login_status : userdata ? (userdata.token ? 'success' : 'init') : 'init' 
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER_USER:
            return { ...state,
                data: action.data };
        case LOGIN_USER:
            return { ...state,
                data: action.data };
        case UPDATE_START:
            return { ...state,
                updating: true };
        case UPDATE_END:
            return { ...state,
                updating: false };
        case ACCOUNT_DETAIL:
            return { ...state,
                account_detail: action.account_detail };      
        case LOGOUT_USER : 
            localStorage.setItem('gouserdata','');
            return { ...state,
                data: {} };
        case REGISTER_BEGIN:
            return { ...state,
                register_loading: true };
        case REGISTER_END:
            return { ...state,
                register_loading: false };
        case REGISTER_STATUS:
            return { ...state,
                register_status: action.log };
        case LOGIN_BEGIN:
            return { ...state,
                login_loading: true };
        case LOGIN_END:
            return { ...state,
                login_loading: false };  
         case LOGIN_STATUS:
            return { ...state,
                 login_status: action.log }; 
  		case COMMIT_ORDER_BEGIN:
                  return { ...state,
                    commit_order_loading: true };
        case COMMIT_ORDER_END:
                  return { ...state,
                    commit_order_loading: false };      
        default:
            return state;     
    }
};
export default userReducer;