import * as types from './formsActionTypes';
import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
const userdata = localStorage.getItem('gouserdata') ? JSON.parse(localStorage.getItem('gouserdata')) : {};

var axios_instance = '';

if(userdata.token)
  axios_instance = axios.create({headers: {authToken: userdata.token}});
else 
  axios_instance = axios.create();


export const sendRequestBegin = () => ({
    type: types.SEND_REQUEST_BEGIN
});

export const sendRequestEnd = () => ({
    type: types.SEND_REQUEST_END
});

export const sendRequestStatus = (status) => ({
    type: types.SEND_REQUEST_SUCCESS,
    status
});

export const submitContactUsBegin = () => ({
    type: types.CONACTUS_REQUEST_BEGIN
});

export const submitContactUsEnd = () => ({
    type: types.CONACTUS_REQUEST_END
});

export const submitNotifyBegin = () => ({
    type: types.NOTIFY_REQUEST_BEGIN
});

export const submitNotifyEnd = () => ({
    type: types.NOTIFY_REQUEST_END
});

export const sendSellerRequestNow = (data) => {
  return  (dispatch) => {
    dispatch(sendRequestBegin());
    const formData = new FormData();
    formData.append('file',data.document);
    formData.append('companyName',data.companyName);
    formData.append('description',data.description);
    formData.append('emailId',data.emailId);
    formData.append('firstName',data.firstName);
    formData.append('phoneNumber',data.phoneNumber);
    return new Promise((resolve, reject) => {
      axios_instance.post(process.env.PUBLIC_ENDPOINT +'/seller',
               formData,{ headers: {'content-type': 'multipart/form-data'}})
     .then(response => response.data)
      .then((data) => {
        dispatch(sendRequestEnd());
        dispatch(sendRequestStatus(true));
        return resolve('success');
     })
      .catch(function (error) {
        dispatch(sendRequestEnd());
        return reject('failed');
      });
    });
  };
}

export const sendNotifyRequestNow = (data) => {
  return  (dispatch) => {
    dispatch(submitNotifyBegin());
    return new Promise((resolve, reject) => {
     axios_instance.post(process.env.PUBLIC_ENDPOINT +'/notify',data)
     .then(response => response.data)
      .then((data) => {
        dispatch(submitNotifyEnd());
        return resolve('success');
     })
      .catch(function (error) {
        dispatch(submitNotifyEnd());
        return reject('failed');
      });
    });
  };
}

export const sendRequestNow = (data) => {
  return  (dispatch) => {
  	const formData = new FormData();
    formData.append('file',data.document);
    formData.append('companyName',data.companyName);
    formData.append('description',data.description);
    formData.append('emailId',data.emailId);
    formData.append('firstName',data.firstName);
    formData.append('phoneNumber',data.phoneNumber);
    dispatch(sendRequestBegin());
    return new Promise((resolve, reject) => {
     axios_instance.post(process.env.PUBLIC_ENDPOINT +'/postrequirement',
               formData,{ headers: {'content-type': 'multipart/form-data'}})
     .then(response => response.data)
      .then((data) => {
        dispatch(sendRequestEnd());
        dispatch(sendRequestStatus(true));
        return resolve('success');
     })
      .catch(function (error) {
        dispatch(sendRequestEnd());
        return reject('failed');
      });
    });
  };
}

export const submitContactUsRequest = (data) => {
  return  (dispatch) => {
    dispatch(submitContactUsBegin());
    return new Promise((resolve, reject) => {
     axios_instance.post(process.env.PUBLIC_ENDPOINT + "/contactus?lang=en&store=DEFAULT", data)
     .then(response => response.data)
      .then((data) => {
        dispatch(submitContactUsEnd());
        toast.success("Sent message successfully.");
        return resolve('success');
     })
      .catch(function (error) {
        dispatch(submitContactUsEnd());
        return reject('failed');
      });
    });
  };
}

export const submitNegotiateRequest = (data) => {
  return  (dispatch) => {
    dispatch(submitContactUsBegin());
    return new Promise((resolve, reject) => {
     axios_instance.post(process.env.PUBLIC_ENDPOINT + "/lotrequest?lang=en&store=DEFAULT", data)
     .then(response => response.data)
      .then((data) => {
        dispatch(submitContactUsEnd());
        toast.success("Submitted successfully.");
        return resolve('success');
     })
      .catch(function (error) {
        dispatch(submitContactUsEnd());
        return reject('failed');
      });
    });
  };
}

export const uploadFile = (file,id) => {
        const formData = new FormData();
        formData.append('file',file);
        axios.post(process.env.PUBLIC_ENDPOINT +'/saveDocument/'+id+'?lang=en',
               formData,{ headers: {'content-type': 'multipart/form-data'}}) .then((data) => {
        }).catch((error) => {
        });
}

