// react
import React, { Component } from 'react';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import SimpleReactValidator from 'simple-react-validator';
import { toast  } from 'react-toastify';

// application
import Collapse from '../shared/Collapse';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';
import { accountDetail,resetPassword, getPrices,commitOrder,updateCustomerBeforeCommitOrder,loginCustomer } from '../../store/user';
import { checkOrderReady, resetCart,listCart } from '../../store/cart';
import { trackPage } from '../../services';

// data stubs
import payments from '../../data/shopPayments';
import theme from '../../data/theme';


class ShopPageCheckout extends Component {
    payments = payments;

    constructor(props) {
        super(props);

        this.state = {
            userName:'',
            password:'',
            storeCode: "DEFAULT",
            language: "en",
            "forgotPassword":false,
            "reset_success":false,
            "terms_and_conditions" : false,
            "account_data" : {
              "billing" : {},
              "delivery" : {}
            },
            'prices':{}
            };
             this.validator = new SimpleReactValidator();
             this.checkboxValidator = new SimpleReactValidator();
             this.setStateFromInput = this.setStateFromInput.bind(this);
             this.cancelEditAddress = this.cancelEditAddress.bind(this);
             this.handleSubmit = this.handleSubmit.bind(this);
             this.handleSubmitAddress = this.handleSubmitAddress.bind(this);
             this.forgotpasswordManage = this.forgotpasswordManage.bind(this);
    }

    forgotpasswordManage = () => {
        this.validator = new SimpleReactValidator();
        this.setState(state => {
               state['forgotPassword'] = !state.forgotPassword;
               state['reset_success'] = false;
               return state;
         });
     }

     cancelEditAddress = () => {
     this.validator = new SimpleReactValidator();
     this.setState(state => {
             state['editBillingAddress'] = false;
            state['editDeliveryAddress'] = false;
            return state;
      });
  }

   addEditBillingAddressManage = () => {
     this.validator = new SimpleReactValidator();
     this.setState(state => {
            state['editBillingAddress'] = !state.editBillingAddress;
            state['editDeliveryAddress'] = false;
            return state;
      });
  }

  addEditDeliveryAddressManage = () => {
     this.validator = new SimpleReactValidator();
     this.setState(state => {
            state['editDeliveryAddress'] = !state.editDeliveryAddress;
            state['editBillingAddress'] = false;
            return state;
      });
  }


        getTaxDetails(){
       this.props.dispatch(getPrices())
            .then(response => {
              this.setState(state => {
                state['prices'] = response;
                this.props.dispatch(checkOrderReady(response.readyToOrder));
                
                return state;
              });
              
            })
            .catch(err => {
            });
    }
    handlePaymentChange = (event) => {
        if (event.target.checked) {
            this.setState({ payment: event.target.value });
        }
    };

       setPropsFromInput = (event) => {
        var obj = {};
        if(event.target.dataset.object){
         var key = event.target.dataset.object;
         var name = event.target.name.replace('delivery_','').replace('billing_','');
         var value =  event.target.value;
         this.setState(state => {
                if (!state['account_data'][key])
                    state['account_data'][key] = {};
                state['account_data'][key][name] = value;
                return state;
            });
       }
     }

    setStateFromInput = (event) => {
    var obj = {};
    if(event.target.dataset.object){
         var key = event.target.dataset.object;
         var name = event.target.name.replace('delivery_','').replace('billing_','');
         var value =  event.target.value;
            this.setState(state => {
                  if(!state[key])
                    state[key] = {};
                  state[key][name] = value;
                  return state;
            });

       }
       else{
            var name = event.target.name.replace('delivery_','').replace('billing_','');
           obj[name] = event.target.value;
           this.setState(obj);
       }
}

 setStateFromCheckbox = (event) => {
          var obj = {};
          obj[event.target.name] = event.target.checked;
          this.setState(obj);

          if(!this.checkboxValidator.fieldValid(event.target.name))
          {
              this.checkboxValidator.showMessages();
          }
        }

    renderTotals() {
        const { cart } = this.props;
        let taxLines = '';
        if(this.state.prices.subTotal){
            taxLines = ( <React.Fragment>
                     <tr>
                        <th>Subtotal</th>
                        <td><Currency value={Number(this.state.prices[this.props.currency.checkoutSubtotalLabel])} /></td>
                    </tr>
               {this.state.prices.taxRate.map((item, index) => {
                 return (
                     <tr key={index}> 
                     <th>{item.name.toUpperCase()}&nbsp;{item.taxPercentage}%</th>
                     <th><Currency value={Number(item[this.props.currency.taxValueLabel])} /></th> 
                     </tr>
                     );
              })}
               </React.Fragment>);
            } else {
            taxLines =  
               this.state.prices.subTotal ? 
                <tr>
                <th>Subtotal</th>
                <td><Currency value={Number(this.state.prices[this.props.currency.checkoutSubtotalLabel])} /></td>
                </tr>
                :
                <tr>
                <th>Subtotal</th>
                <td><Currency value={Number(cart[this.props.currency.cartAllSubtotalLabel])} /></td>
                </tr>;
          }

       

        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    {taxLines}
                </tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { cart,currency } = this.props;
        const items = cart.items.map((item) => (
            <tr key={item.id}>
                <td>{`${item.name} × ${item.quantity}`}</td>
                <td><Currency  value={Number(item[this.props.currency.cartProductSubTotalLabel])} /></td>
            </tr>
        ));

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">

                    <tr>
                        <th>Product</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">
                    {items}
                </tbody>
                {this.renderTotals()}
                <tfoot className="checkout__totals-footer">
                    { this.state.prices.total ? 
                    <tr>
                    <th>Total Amount</th>
                    <td><Currency value={Number(this.state.prices[this.props.currency.checkoutTotalLabel])} /></td>
                    </tr>
                    :
                    <tr>
                    <th>Total Amount</th>
                    <td><Currency value={Number(cart[this.props.currency.cartAllTotalLabel])} /></td>
                    </tr>
                    }
                </tfoot>
            </table>
        );
    }

         componentWillMount() {
           this.props.trackPage(this.props.location.pathname + this.props.location.search);
          if (this.props.login_status == 'success') {
            this.props.dispatch(accountDetail())
            .then(response => {
              var acc_data = Object.assign({},this.props.account_detail);
              this.setState(state => {
                  state['account_data']['custFirstName'] = this.props.account_detail.custFirstName;
                  state['account_data']['custLastName'] = this.props.account_detail.custLastName;
                  state['account_data']['billing'] = {...this.props.account_detail.billing };
                  state['account_data']['delivery'] ={...this.props.account_detail.delivery };
                  return state;
              });
            })
            .catch(err => {
            });
            this.getTaxDetails();
        }

    }

     handleSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
        if(!this.state.forgotPassword) {
            this.props.dispatch(loginCustomer(this.state))
            .then(response => {
            this.props.dispatch(accountDetail())
            .then(response => {
                 if(localStorage.getItem('myvmarketcartcode')){
                this.props.dispatch(listCart(this.props.currency));
              }
              var acc_data = Object.assign({},this.props.account_detail);
              this.getTaxDetails();
              this.setState(state => {
                  state['account_data']['custFirstName'] = this.props.account_detail.custFirstName;
                  state['account_data']['custLastName'] = this.props.account_detail.custLastName;
                  state['account_data']['billing'] = {...this.props.account_detail.billing };
                  state['account_data']['delivery'] ={...this.props.account_detail.delivery };
                  return state;
              });
            })
            .catch(err => {
            });
            })
              .catch(err => {
                toast.error("Login Failed. Please enter valid credentials");
              });
        }
        else if(this.state.forgotPassword)
        {
           this.props.dispatch(resetPassword(this.state.userName))
            .then(response => {
                this.setState(state => {
                    state['reset_success'] = !state.reset_success;
                    return state;
                });
            })
              .catch(err => {
                toast.error("Unable to complete your request. Looks like your email address is not registered with us.");
              });
        }
    } else {
              this.validator.showMessages();
              this.forceUpdate();
    }
  }

    handleSubmitAddress = (event) => {
        event.preventDefault();
        if (this.validator.allValid()) {
          var acc_data = this.props.account_detail;
           acc_data.billing = this.state.account_data.billing;
           acc_data.delivery = this.state.account_data.delivery;
            this.props.dispatch(updateCustomerBeforeCommitOrder(acc_data))
            .then(res => {
              this.getTaxDetails();
             })
             .catch(err => {
                // toast.error('Unable to place the order due to the following errors.');
                // err.map((e, index) => {
                //     toast.error(e.defaultMessage);

                // });
             });
        } else {
              this.validator.showMessages();
              this.forceUpdate();
        }
    }
    checkIfOutOfStockExist(){
      return this.props.cart.items && this.props.cart.items.filter((item)=>{
        return item.productquantity == 0;
      }).length > 0;
    }
     payClick() {
        if (this.props.login_status == 'success') {
            if(this.validator.allValid() && this.checkboxValidator.allValid() && !this.checkIfOutOfStockExist()){
                     // var acc_data = this.props.account_detail;
                     // acc_data.billing = this.state.account_data.billing;
                     // acc_data.delivery = this.state.account_data.delivery;
                      // this.props.dispatch(updateCustomerBeforeCommitOrder(acc_data,this.state.editBillingMode || this.state.editDeliveryMode))
                      //  .then(res => {
                           this.props.dispatch(commitOrder())
                           .then(response => {
                              this.props.dispatch(resetCart());
                              this.props.history.push({
                                  pathname: '/order-success',
                                  state: { payment: 'success', orderId : response.orderId, companyName:response.companyName,billing: response.billing, delivery: response.delivery, items: response.products, orderTotal: response.total.price }
                              });
                           })
                          .catch(err => {
                             if(err){
                                 err && err.map((e, index) => {
                                  toast.error(e.defaultMessage);
                                  });

                             }
                             else
                             {
                                toast.error('Unable to place the order.');
                             }
                          });
                      //   })
                      // .catch(err => {
                      //     toast.error('Unable to place the order due to the following errors.');
                      //     err.map((e, index) => {
                      //         toast.error(e.defaultMessage);

                      //     });
                      // });
            }
              else {
                if(this.checkIfOutOfStockExist()){
                  toast.error('Out of stock items exist in the cart. Remove it before you place the order.');
                }
            this.validator.showMessages();
            this.forceUpdate();
            }
        } else {
            toast.error('Login as a customer to place the order');
        }
    }


    render() {
        const { cart, login_status, account_data , updating, commit_order_loading, login_loading,currency } = this.props;
        // if (cart.items.length < 1) {
        //     return <Redirect to="cart" />;
        // }

        const breadcrumb = [
            { value: 'Home', lineage: '' },
            { value: 'Checkout', lineage: '' }
        ];
        let content;
        let loadSection;
    loadSection = () => {
       if(!this.state.forgotPassword){
          content = (
      <React.Fragment>
       <div className="form-group">
                                            <label htmlFor="login-email">Email address</label>
                                            <input
                                                id="login-email"
                                                type="email"
                                                name="userName"
                                                className="form-control"
                                                placeholder="Enter email"
                                                value={this.state.userName} onChange={this.setStateFromInput} />
                                                    {this.validator.message('userName', this.state.userName, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="login-password">Password</label>
                                            <input
                                                id="login-password"
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                placeholder="Password"
                                                value={this.state.password} onChange={this.setStateFromInput} />
                                                    {this.validator.message('password', this.state.password, 'required')}
                                            <small className="form-text form-txt-mt login-helper-txt">
                                                <span onClick={this.forgotpasswordManage}>Forgot Password?</span>
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input
                                                            id="login-remember"
                                                            type="checkbox"
                                                            className="input-check__input"
                                                        />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="login-remember">
                                                    Remember Me
                                                </label>
                                            </div>
                                        </div>
                                         <button type="submit" className={classNames('btn btn-primary', {
                                            'btn-loading': login_loading,
                                        })}>
                                            Login
                                        </button>
                                        </React.Fragment>
      ) 
      }
      else if(this.state.forgotPassword)
      {
        content = (
             <React.Fragment>
              <div className="form-group">
                  <label htmlFor="login-email">Email address</label>
                  <input
                      id="login-email"
                      type="email"
                      name="userName"
                      className="form-control"
                      placeholder="Enter email"
                      value={this.state.userName} onChange={this.setStateFromInput} />
                          {this.validator.message('userName', this.state.userName, 'required')}
              </div>
               <div className="form-group d--btn-block">
                      <button type="submit" className={classNames('btn btn-primary', {
                          'btn-loading': login_loading,
                      })}>Reset</button>
                       <span className="d-flex-span login-helper-txt" onClick={this.forgotpasswordManage}>Having credentials, login now? </span>
                  </div>
              </React.Fragment>
          )
      }
   
    return content;
  }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Checkout — ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="Checkout" breadcrumb={breadcrumb} />
                { !cart.items.length ?
                 <Redirect to="/cart"/>  
                 : '' }
                <div className="checkout block">
                    <div className="container">
                        <div className="row">
                        { login_status == 'init' ?
                            <div className="col-12 mb-3">
                                <div className="alert alert-primary alert-lg">
                                    Returning customer? Login to place order
                                </div>
                            </div>
                            : ''}
                            <div className="col-12 col-lg-6 col-xl-7">
                                <div className="card mb-lg-0">
                                { login_status == 'success' ?
                                    <div className="card-body">
                                        <h3 className="card-title">Address Details</h3>
                                        {!(this.state.editBillingAddress || this.state.editDeliveryAddress) ? 
                                         <div>
                                         <div className="addresses-list__item card address-card"><div className="address-card__badge-title">Billing Address</div>
                        { this.state.account_data.billing.city ? 
                        <div className="address-card__body">
                            <div className="address-card__name">{`${this.state.account_data.custFirstName} ${this.state.account_data.custLastName}`}</div>
                            <div className="address-card__row">

                                {this.state.account_data.billing.address}
                                <br />
                                {this.state.account_data.billing.city},
                                {this.state.account_data.billing.postalCode}
                                 <br />
                                {this.state.account_data.billing.country}
                                 <br />
                                 {this.state.account_data.billing.phoneNumber}
                            </div>
                            <div className="address-card__footer">
                                <div className="btn btn-secondary btn-sm" onClick={this.addEditBillingAddressManage}>Change</div>
                            </div>
                        </div>
                        : 
                        <div className="address-card__body">
                            <div className="address-card__footer">
                                <div className="btn btn-secondary btn-sm" onClick={this.addEditBillingAddressManage}>Add</div>
                            </div>
                        </div>
                        }
                    </div>
                    <div className="addresses-list__item card address-card"><div className="address-card__badge-title">Shipping Address</div>
                        { this.state.account_data.delivery.city ? 
                        <div className="address-card__body">
                            <div className="address-card__name">{`${this.state.account_data.custFirstName} ${this.state.account_data.custLastName}`}</div>
                            <div className="address-card__row">
                                {this.state.account_data.delivery.address}
                                <br />
                                {this.state.account_data.delivery.city},
                                {this.state.account_data.delivery.postalCode}
                                 <br />
                                {this.state.account_data.delivery.country}
                                 <br />
                                 {this.state.account_data.delivery.phoneNumber}
                            </div>
                            <div className="address-card__footer">
                                <div className="btn btn-secondary btn-sm" onClick={this.addEditDeliveryAddressManage}>Change</div>
                            </div>
                        </div>
                        : 
                        <div className="address-card__body">
                            <div className="address-card__footer">
                                <div className="btn btn-secondary btn-sm" onClick={this.addEditDeliveryAddressManage}>Add</div>
                            </div>
                        </div>
                        }
                    </div>
                    </div>
                    :   <form onSubmit={this.handleSubmitAddress.bind(this)} noValidate>
                    {this.state.editBillingAddress ?   <h6>Billing Address</h6> :  
                                <h6>Delivery Address</h6>  }
                     {this.state.editBillingAddress ? 
                           <React.Fragment>
                                     <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-first-name">First Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-first-name"
                                                    placeholder="First Name"
                                                    name="custFirstName"
                                                    disabled={true}
                                                    value={this.state.account_data.custFirstName ? this.state.account_data.custFirstName : ''} onChange={this.setPropsFromInput} />
                                                
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-last-name">Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-last-name"
                                                    placeholder="Last Name"
                                                    name="custLastName"
                                                     disabled={true}
                                                    value={this.state.account_data.custLastName ? this.state.account_data.custLastName : ''} onChange={this.setPropsFromInput} />
                                                   
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>Country</label>
                                            <select className="form-control" name="country" data-object="billing" value={this.state.account_data.billing.country} onChange={this.setPropsFromInput}>
                                                <option value="IN" >India</option>
                                                <option value="SA">Saudi Arabia</option>
                                                <option  value="US">United States</option>
                                            </select>
                                            {this.validator.message('country', this.state.account_data.billing.country, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-street-address">Street Address</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="checkout-street-address"
                                                placeholder="Street Address"
                                                name="address"
                                                data-object="billing"
                                             value={this.state.account_data.billing.address} onChange={this.setPropsFromInput} />
                                                    {this.validator.message('address', this.state.account_data.billing.address, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-city">Town / City</label>
                                            <input type="text" className="form-control" data-object="billing" id="checkout-city" name="city" value={this.state.account_data.billing.city} onChange={this.setPropsFromInput} />
                                                    {this.validator.message('city', this.state.account_data.billing.city, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-postcode">Postcode / ZIP</label>
                                            <input type="text" className="form-control" data-object="billing" id="checkout-postcode" name="postalCode"  value={this.state.account_data.billing.postalCode} onChange={this.setPropsFromInput} />
                                                    {this.validator.message('postalCode', this.state.account_data.billing.postalCode, 'required')}
                                        </div>

                                        <div className="form-row">
                                           
                                            <div className="form-group col-md-12">
                                                <label htmlFor="checkout-phone">Phone</label>
                                                <input type="text" className="form-control" data-object="billing" id="checkout-phone" placeholder="Phone" 
                                                name="phoneNumber"
                                                 value={this.state.account_data.billing.phoneNumber} onChange={this.setPropsFromInput} />
                                                    {this.validator.message('phoneNumber', this.state.account_data.billing.phoneNumber, 'required')}
                                            </div>
                                        </div>
                                         <button type="submit" className={classNames('btn btn-primary', {
                                            'btn-loading': updating
                                        })}>
                                            Update
                                        </button>&nbsp;&nbsp;
                                        <button type="cancel" onClick={this.cancelEditAddress} className='btn btn-primary'>
                                            Close
                                        </button>
                 </React.Fragment>
                 : <React.Fragment>
                                     <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-first-name">First Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-first-name"
                                                    placeholder="First Name"
                                                    name="custFirstName"
                                                    disabled={true}
                                                    value={this.state.account_data.custFirstName ? this.state.account_data.custFirstName : ''} onChange={this.setPropsFromInput} />
                                                       
                                                
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-last-name">Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-last-name"
                                                    placeholder="Last Name"
                                                    name="custLastName"
                                                    disabled={true}
                                                    value={this.state.account_data.custLastName ? this.state.account_data.custLastName : ''} onChange={this.setPropsFromInput} />
                                                       
                                               
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>Country</label>
                                            <select className="form-control" data-object="delivery" name="delivery_country"  value={this.state.account_data.delivery.country} onChange={this.setPropsFromInput}>
                                                <option value="IN" >India</option>
                                                <option value="SA">Saudi Arabia</option>
                                                <option  value="US">United States</option>
                                            </select>
                                            {this.validator.message('delivery_country', this.state.account_data.delivery.country, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-street-address">Street Address</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="checkout-street-address"
                                                placeholder="Street Address"
                                                name="delivery_address"
                                                data-object="delivery" 
                                             value={this.state.account_data.delivery.address} onChange={this.setPropsFromInput} />
                                                    {this.validator.message('delivery_address', this.state.account_data.delivery.address, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-city">Town / City</label>
                                            <input type="text" className="form-control" data-object="delivery"  id="checkout-city" name="delivery_city" value={this.state.account_data.delivery.city} onChange={this.setPropsFromInput} />
                                                    {this.validator.message('delivery_city', this.state.account_data.delivery.city, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-postcode">Postcode / ZIP</label>
                                            <input type="text" className="form-control" data-object="delivery"  id="checkout-postcode" name="delivery_postalCode"  value={this.state.account_data.delivery.postalCode} onChange={this.setPropsFromInput} />
                                                    {this.validator.message('delivery_postalCode', this.state.account_data.delivery.postalCode, 'required|numeric')}
                                        </div>

                                        <div className="form-row">
                                           
                                            <div className="form-group col-md-12">
                                                <label htmlFor="checkout-phone">Phone</label>
                                                <input type="text" name="delivery_phoneNumber"  data-object="delivery"  className="form-control" id="checkout-phone" placeholder="Phone" 
                                                 value={this.state.account_data.delivery.phoneNumber} onChange={this.setPropsFromInput} />
                                                    {this.validator.message('delivery_phoneNumber', this.state.account_data.delivery.phoneNumber, 'required|phone')}
                                            </div>
                                        </div>
                                         <button type="submit" className={classNames('btn btn-primary', {
                                            'btn-loading': updating,
                                        })}>
                                            Update
                                        </button> &nbsp;&nbsp;
                                        <button type="cancel" onClick={this.cancelEditAddress} className='btn btn-primary'>
                                            Close
                                        </button>

                 </React.Fragment> }           
                    </form> }
                                    </div>
                                    :
                                    <div className="card-body">

{ !this.state.reset_success ? 
                                <div className="card-body">

                                {!this.state.forgotPassword ?    <h3 className="card-title">Login</h3> :  
                                <h3 className="card-title">Forgot your password</h3>  }
                                    
                                    <form onSubmit={this.handleSubmit.bind(this)} noValidate>
                                        {loadSection()}
                                    </form>

                                </div>
                                : 
                                <div className="card-body">
                                 <h3 className="card-title">Reset your password</h3>
                                  <p>If you are an existing customer, 
                                  the new password will be generated and mail will be sent to the <b>{this.state.userName}</b></p> 
                                      <button onClick={this.forgotpasswordManage} className="btn btn-primary d-flex-btn">Go To Login
                                      </button>
                                </div>
                                }
                                    </div>
                                }
                                </div>
                            </div>

                            <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <h3 className="card-title">Your Order</h3>
                                        {this.renderCart()}
                                        <p className="checkout-shipping-label">Excluding shipping charges</p>
                                        <div className="checkout__agree form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                    <input className="input-check__input" type="checkbox" id="checkout-terms" 
                                                    name="terms_and_conditions"
                                                     checked={this.state.terms_and_conditions} 
                                                     onChange={this.setStateFromCheckbox}/>
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="checkout-terms">
                                                    I have read and agree to the website&nbsp;
                                                    <Link to="/terms-and-conditions">terms and conditions</Link>
                                                    *
                                                </label>
                                                {this.checkboxValidator.message('terms_and_conditions', this.state.terms_and_conditions, 'accepted')}
                                            </div>
                                        </div>
                                        <button type="submit" disabled={!this.state.terms_and_conditions} onClick={() => this.payClick()} className={classNames('btn btn-primary btn-xl btn-block', {
                                            'btn-loading': commit_order_loading
                                        })} >Place Order</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
 return {
    cart: state.cart,
    login_loading : state.user.login_loading,
    commit_order_loading:state.user.commit_order_loading,
    wishlist: state.wishlist,
    login_status :  state.user.login_status,
    account_detail : state.user.account_detail,
    currency: state.currency,
    trackPage : trackPage

}
};

 const mapDispatchToProps = dispatch => ({
      dispatch               
   })

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout);
