
export const FETCH_PRODUCTS_BEGIN = 'FETCH_PRODUCTS_BEGIN'
export const FETCH_PRODUCTS_DONE = 'FETCH_PRODUCTS_DONE'
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'
export const RECEIVE_KEY_BASED_PRODUCTS = 'RECEIVE_KEY_BASED_PRODUCTS'
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'
export const TOTAL_PRODUCTS_COUNT = 'TOTAL_PRODUCTS_COUNT'
export const LOADED_PRODUCTS_COUNT = 'LOADED_PRODUCTS_COUNT'
export const INFINITE_LIMIT = 'INFINITE_LIMIT'
export const START_PRODUCTS_COUNT = 'START_PRODUCTS_COUNT'
export const RECEIVE_PRODUCTS_FILTERS = 'RECEIVE_PRODUCTS_FILTERS'
export const END_PRODUCTS_COUNT = 'END_PRODUCTS_COUNT'
export const RESET_FILTERS = 'RESET_FILTERS'
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'
export const FETCH_SINGLE_PRODUCT = 'FETCH_SINGLE_PRODUCT'
export const FETCH_SINGLE_PRODUCT_BEGIN = 'FETCH_SINGLE_PRODUCT_BEGIN'
export const FETCH_SINGLE_PRODUCT_DONE = 'FETCH_SINGLE_PRODUCT_DONE'
export const RESET_PRODUCTS_FILTERS = 'RESET_PRODUCTS_FILTERS'
export const RESUME_FILTERS = 'RESUME_FILTERS'