// third-party
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// reducer
import rootReducer from './rootReducer';


function load() {
    let state;

    try {
      //  state = localStorage.getItem('state');

        if (typeof state === 'string') {
            state = JSON.parse(state);
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return state || undefined;
}
const devTools = 
    //For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : function (f) {
        return f;
    }


const store = createStore(rootReducer, load(), compose(
    applyMiddleware(thunk),devTools));

function save() {
    try {
       //  localStorage.setItem('state', JSON.stringify(store.getState()));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
}

store.subscribe(() => save());

export default store;
