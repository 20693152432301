// react
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageHeader from '../shared/PageHeader';
import { Helmet } from 'react-helmet';
import theme from '../../data/theme';
import { submitContactUsRequest, submitNegotiateRequest } from '../../store/forms';
import SimpleReactValidator from 'simple-react-validator';
import { trackPage } from '../../services';
import Modal from 'react-modal';
import { Cross12Svg } from '../../svg';
import ReactGA from 'react-ga';

import StaticLotItem from '../shared/StaticLotItem';
import { retrieveLots, emptyLots } from '../../store/lots';
import { getLotsFromCategories } from '../../services';

Modal.setAppElement('#root');

class LotSales extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.setStateFromInput = this.setStateFromInput.bind(this);
        this.state = {
            "comment": "",
            "emailId": "",
            "name": "",
            "subject": "",
            "showModal": false
        };
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }


    handleOpenModal(e,lot, type) {
        e.stopPropagation();
        e.preventDefault();
        console.log(lot);
        this.validator.hideMessages();
        this.setState(state => {
            state['comment'] = "";
            state['emailId'] = "";
            state['name'] = "";
            state['showModal'] = true;
            state['subject'] = 'Lot Sale - ' + lot.lsName;
            state['price'] = '';
            state['phone'] = '';
            state['type'] = type;
            return state;
        });
    }

    handleCloseModal() {
        this.setState(state => {
            state['showModal'] = false;
            state['subject'] = '';
            return state;
        });
    }


    componentWillUnmount() {


    }

    componentDidMount() {
        let categoryid = this.props.match.params.lotid;
        if(!this.props.lots.length){
            this.props.dispatch(retrieveLots(categoryid)).then(response => {
            }).catch(err => {
            });
        }
    }

    componentDidUpdate(prevProps) {
        let categoryid = this.props.match.params.lotid;
        
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.props.dispatch(retrieveLots(categoryid)).then(response => {
            }).catch(err => {
            });
        }

    }

    componentWillUnmount() {
        this.props.dispatch(emptyLots());
    }


    componentWillMount() {
        this.props.trackPage(this.props.location.pathname + this.props.location.search);
    }

    setLayout = (layout) => {
        this.setState(() => ({ layout }));
    };

    setStateFromInput = (event) => {
        var obj = {};
        if (event.target.dataset.object) {
            var key = event.target.dataset.object;
            var name = event.target.name;
            var value = event.target.value;
            this.setState(state => {
                state[key][name] = value;
                return state;
            })
        } else {
            obj[event.target.name] = event.target.value;
            this.setState(obj);
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        ReactGA.event({
            category: 'Requests',
            action: 'Lot sale',
            label: 'Form Submit'
        });
        if (this.validator.allValid()) {
            this.props.dispatch(submitContactUsRequest(this.state))
                .then(response => {
                    this.handleCloseModal();
                })
                .catch(err => {
                    console.log(err);
                });

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    handleNegotiateSubmit= (event) => {
        event.preventDefault();
        ReactGA.event({
            category: 'Requests',
            action: 'Lot sale Negotiate',
            label: 'Form Submit'
        });
        if (this.validator.allValid()) {
            var payload = {
                lotName: this.state.subject,
                phone: this.state.phone,
                price: this.state.price
            }
            this.props.dispatch(submitNegotiateRequest(payload))
                .then(response => {
                    this.handleCloseModal();
                })
                .catch(err => {
                    console.log(err);
                });

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const {
            grid,
            layout
        } = this.props;
        const {
            lotid,
            lotname
        } = this.props.match.params;
        const breadcrumb = [{ value: 'Home', lineage: '' }, {
            value: 'Lot Sale',
            lineage: '/lotsales'
        }];

        const containerClasses = classNames('product-card', {
            'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
            'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
            'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
            'product-card--layout--list': layout === 'list',
            'product-card--layout--horizontal': layout === 'horizontal',
        });
        const placeholder = {
            id: "ITEM ID",
            image: "PRODUCT IMAGE",
            description: "DESCRIPTION",
            pdf: "LOT PRICE",
            actions: "ACTIONS"
        };
        // const items = [{
        //         description: "Bearing Lot List",
        //         display: [{ label: "Lot ID", "value": "GS1-27/09/2020" },
        //             { label: "Discount", "value": "50%" },
        //             { label: "Condition", "value": "Unused/ Excess Stock (Packed Condition)" },
        //             { label: "Loc", "value": "Jamshedpur" },
        //             { label: "MRP Value", "value": "2068842" },
        //             { label: "Lot Value", "value": "1034421" },
        //             { label: "AGE", "value": ">5 Years" },
        //             { label: "Lot Item Details", "value": "Ball Bearing, Roller Bearing, Conical Bearing, Bearing spares" }
        //         ],
        //         image: "https://www.goospares.com/api/productimages/Single-Row-Deep-Groove-Ball-Bearing-SKF.jpg",
        //         pdf: "images/lotsales/GS-Bearing-Lot-List-GS1-27092020.pdf"
        //     },
        //     {
        //         description: 'Valves Lot Sale',
        //         display: [{ label: "Lot ID ", "value": "GS2-27/09/2020" },
        //             { label: "Discount", "value": "60%" },
        //             { label: "Condition", "value": "Unused/ Excess Stock (Packed Condition)" },
        //             { label: "Loc", "value": "Jamshedpur" },
        //             { label: "MRP Value ", "value": "11835134" },
        //             { label: "Lot Value", "value": "4734054" },
        //             { label: "AGE", "value": ">5 Years " },
        //             { label: "Lot Item Details", "value": "Relief Valve, Check Valve, Shut-off Valve, Non-Return Valve, Ball Valve, Butterfly Valve, DC Valve, Valve Spares" }
        //         ],
        //         image: "https://www.goospares.com/api/productimages/Butterfly-Valve.jpg",
        //         pdf: "images/lotsales/GS-Valves-Lot-List-27092020.pdf"
        //     }
        // ];
        return (
            <React.Fragment>
              <Helmet>
                <title>{`Industrial lot sale|Stock Lot Website — ${theme.name}`}</title>
                <meta name="Description" content={`B2B Marketplace for spares lot liquidation. Buying Stock lot at discount prices. Bulk Sale of industrial Spares.Goospares.com Stock lot Sale website in India.`}/>
            </Helmet>
            <PageHeader header={ this.props.match.params.lotname ? this.props.match.params.lotname : 'Lots'} breadcrumb={breadcrumb} />
        <Modal 
           isOpen={this.state.showModal}
           contentLabel="onRequestClose Example"
           onRequestClose={this.handleCloseModal}
           shouldCloseOnOverlayClick={false}
           className="react-go-modal"
           overlayClassName="react-go-modal-Overlay"
        > <div className="static-products-view container">
                                <div className="row">
                                    <div className="col-12 col-lg-12">
                                    {this.state.type == 'enquiry' ?
                                        <h4 className="contact-us__header card-title float-section-left">Send Enquiry</h4>
                                        : 
                                        <h4 className="contact-us__header card-title float-section-left">Submit Negotiate Form</h4>
                                        }
                                        <button type="button"
                                             className="btn btn-light btn-sm btn-svg-icon float-section-right"
                                             onClick={this.handleCloseModal}>
                                            <Cross12Svg />
                                        </button>
                                        {this.state.type == 'enquiry' ? 
                                        <form noValidate className="float-section-right">
                                            <div className="form-row">
                                            <div className="form-group col-md-6">
                                                   <input
                                                id="name"
                                                type="text"
                                                name="name"
                                                className="form-control "
                                                placeholder="Name"
                                                value={this.state.name} onChange={this.setStateFromInput} />
                                                    {this.validator.message('name', this.state.name, 'required')}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <input
                                                id="emailId"
                                                type="email"
                                                name="emailId"
                                                className="form-control"
                                                placeholder="Email"
                                                value={this.state.emailId} onChange={this.setStateFromInput} />
                                                    {this.validator.message('emailId', this.state.emailId, 'required|email')}
                                                </div>
                                                 <div className="form-group col-md-12">
                                                <input
                                                id="subject"
                                                type="text"
                                                name="subject"
                                                className="form-control"
                                                placeholder="Subject"
                                                readOnly
                                                value={this.state.subject} onChange={this.setStateFromInput} />
                                                    {this.validator.message('subject', this.state.subject, 'required')}
                                            </div>
                                             <div className="form-group col-md-12">
                                               <textarea name="comment" rows="3    " placeholder="Comments" 
                                                className="form-control"  value={this.state.comment} onChange={this.setStateFromInput}>
                                                </textarea>
                                                    {this.validator.message('comment', this.state.comment, 'required')}
                                            </div>
                                             <button disabled={this.props.loading} onClick={this.handleSubmit.bind(this)} className={classNames('btn btn-secondary', {
                                            'btn-loading': this.props.loading,
                                        })}>Send Enquiry {this.props.loading}</button>&nbsp;&nbsp;&nbsp;
                                             <button disabled={this.props.loading} onClick={this.handleCloseModal} className={classNames('btn btn-secondary')}>Cancel</button>
                                            </div>
                                        </form>
                                        :
                                        <form noValidate className="float-section-right">
                                            <div className="form-row">
                                            <div className="form-group col-md-12">
                                                   <input
                                                id="price"
                                                type="text"
                                                name="price"
                                                className="form-control "
                                                placeholder="Price in INR"
                                                value={this.state.price} onChange={this.setStateFromInput} />
                                                    {this.validator.message('price', this.state.price, 'required')}
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <input
                                                id="phone"
                                                type="text"
                                                name="phone"
                                                className="form-control"
                                                placeholder="Phone"
                                                value={this.state.phone} onChange={this.setStateFromInput} />
                                                    {this.validator.message('phone', this.state.phone, 'required')}
                                                </div>
                                                 <div className="form-group col-md-12">
                                                <input
                                                id="lotname"
                                                type="text"
                                                name="lotname"
                                                className="form-control"
                                                placeholder="Lot Name"
                                                readOnly
                                                value={this.state.subject} onChange={this.setStateFromInput} />
                                                    {this.validator.message('subject', this.state.subject, 'required')}
                                            </div>
                                             <button disabled={this.props.loading} onClick={this.handleNegotiateSubmit.bind(this)} className={classNames('btn btn-secondary', {
                                            'btn-loading': this.props.loading,
                                        })}>Submit {this.props.loading}</button>&nbsp;&nbsp;&nbsp;
                                             <button disabled={this.props.loading} onClick={this.handleCloseModal} className={classNames('btn btn-secondary')}>Cancel</button>
                                            </div>
                                        </form>
                                    }
                                    </div>
                                </div>
                            </div>
        </Modal>
               
            <div className="static-products-view container">
            <div className="products-view__list products-list"
              data-layout={layout !== 'list' ? grid : layout}
              data-with-features={layout === 'grid-with-features' ? 'true' : 'false'}>
               <div className="products-list__body">
              <div className="products-list__item">
              <div className="lot-sales-card">
                  <div className="lot-sales__default__image lot-sales__broderleft">
                    <div className="product-card__name">
                        {placeholder.image}
                    </div>
                  </div>
                  <div className="lot-sales__desc lot-sales__broderleft">
                    <div className="product-card__name">
                        {placeholder.description}
                    </div>
                  </div>
                  <div className="lot-sales__pdf lot-sales__broderleft">
                    <div className="product-card__name">
                        {placeholder.pdf}
                    </div>
                  </div>
                  <div className="lot-sales__actions lot-sales__broderleft">
                    <div className="product-card__name">
                        {placeholder.actions}
                    </div>
                  </div>
                  </div>
                  </div>
                  </div>

              <div className="products-list__body">
              {this.props.lots && this.props.lots.map((lot, index) => {
                return (
                <div key={index} className="products-list__item">
                    <Link to={`/lotitems/${encodeURIComponent(lotid)}/${encodeURIComponent(lot.id)}/${encodeURIComponent(lotname)}/${encodeURIComponent(lot.lsName)}`}>
                      <StaticLotItem lot={lot} handleOpenModal= {this.handleOpenModal} />
                      </Link>
                  </div>
                )
               })}
              </div>
            </div>
            </div>
            <br/>
            <br/>
            </React.Fragment>
        );
    }
}

LotSales.propTypes = {
    /**
     * array of product objects
     */
    products: PropTypes.array,
    /**
     * products list layout (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    layout: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * products list layout (default: 'grid')
     * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
     */
    grid: PropTypes.oneOf(['grid-3-sidebar', 'grid-4-full', 'grid-5-full']),
    /**
     * indicates when sidebar bar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
};

LotSales.defaultProps = {
    products: [],
    layout: 'list',
    grid: 'grid-3-sidebar',
    offcanvas: 'mobile',
};
const mapStateToProps = (state, ownProps) => {
    let pathname = ownProps.location.pathname;
    return {
        lots: getLotsFromCategories(state.lots),
        loading: state.forms.cloading,
        trackPage: trackPage
    };
};

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LotSales);