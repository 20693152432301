// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// application
import languages from '../../i18n';
import MegamenuLinks from './MegamenuLinks';


function Megamenu(props) {
    const { menu, location, locale,size,image } = props;
    const { direction } = languages[locale];

    if (!menu) {
        return [];
    }

    const menuStyle = {
        backgroundImage: menu.imageUrl ? `url('${menu.imageUrl}')` : '',
    };




    return (
        <div className={`megamenu megamenu--${location}`} style={menuStyle}>
            <div className="row">
                <div className={`col-${size}`}>
            <MegamenuLinks links={menu.children.slice(0, Math.ceil(menu.children.length/3))} />
        </div>
          <div className={`col-${size}`}>
            <MegamenuLinks links={menu.children.slice(Math.ceil(menu.children.length/3), Math.ceil(menu.children.length/3) * 2 )} />
        </div>
          <div className={`col-${size}`}>
            <MegamenuLinks links={menu.children.slice(Math.ceil(menu.children.length/3) * 2, menu.children.length)} />
        </div>
            </div>
        </div>
    );
}

Megamenu.propTypes = {
    /** menu object (required) */
    menu: PropTypes.object,
    /** one of ['nav-links', 'department'] (default: 'nav-links') */
    location: PropTypes.oneOf(['nav-links', 'department']),
    /** current locale */
    locale: PropTypes.string,
};

Megamenu.defaultProps = {
    location: 'nav-links',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Megamenu);
