// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Img from 'react-image';
import {
    Quickview16Svg
} from '../../svg';
// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import InputNumber from '../shared/InputNumber';
import PageHeader from '../shared/PageHeader';
import { cartRemoveItem, cartUpdateQuantities, removeFromCartState,incrementQty, decrementQty } from '../../store/cart';
import { Cross12Svg } from '../../svg';
import { trackPage } from '../../services';

// data stubs
import theme from '../../data/theme';


class ShopPageCart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            /** example: [{itemId: 8, value: 1}] */
            quantities: [],
        };
    }

    componentWillMount(){
        this.props.trackPage(this.props.location.pathname + this.props.location.search);
    }

    getItemQuantity(item) {
        const { quantities } = this.state;
        const quantity = quantities.find((x) => x.itemId === item.id);

        return quantity ? quantity.value : item.quantity;
    }

    handleChangeQuantity = (item, quantity) => {
        console.log(item, quantity, this.state);
        if(quantity && quantity != 'max'  && quantity != 'min'){
        this.setState((state) => {
            const stateQuantity = state.quantities.find((x) => x.itemId === item.id);

            if (!stateQuantity) {
                state.quantities.push({ itemId: item.id, value: quantity });
            } else {
                stateQuantity.value = quantity;
            }

            return {
                quantities: state.quantities,
            };
        });
        }
    };

    cartNeedUpdate() {
        const { quantities } = this.state;
        const { cart } = this.props;

        return quantities.filter((x) => {
            const item = cart.items.find((item) => item.id === x.itemId);

            return item && item.quantity !== x.value;
        }).length > 0;
    }

    renderItems() {
        const { cart, cartRemoveItem,incrementQty,decrementQty,removeFromCartState,currency, loading } = this.props;

        return cart.items.map((item) => {
            let image;
            let options;

            
                image = <Link to={`/product/${item.productId}/${item.name}`}><Img className="img-fluid-custom" src={`${process.env.PUBLIC_ENDPOINT}${process.env.PRODUCT_IMG_PATH}${item.image?
                                        item.image : item.image}`} unloader={ (<img
                                    src={`${process.env.PUBLIC_URL}/images/default.png`}
                                    className="img-fluid-custom"/>)}/></Link>;
            

            if (item.options && item.options.length > 0) {
                options = (
                    <ul className="cart-table__options">
                        {item.options.map((option, index) => (
                            <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                        ))}
                    </ul>
                );
            }

            const removeButton = (
                <AsyncAction
                    action={() => removeFromCartState(item)}
                    render={({ run, loading }) => {
                        const classes = classNames('btn btn-light btn-sm btn-svg-icon', {
                            'btn-loading': loading,
                        });

                        return (
                            <button type="button" onClick={run} className={classes}>
                                <Cross12Svg />
                            </button>
                        );
                    }}
                />
            );

            return (
                <tr key={item.id} className="cart-table__row">
                    <td className="cart-table__column cart-table__column--image">
                        {image}
                    </td>
                    <td className="cart-table__column cart-table__column--product">
                        <Link to={`/product/${item.productId}/${item.name}`} className="cart-table__product-name">
                            {item.name}
                        </Link>
                         <ul className="cart-table__options">
                            <li> SKU: {item.sku}</li>
                            <li> Mfg: {item.manufacturer}</li>
                            <li> Location: {item.origin.source},&nbsp;{item.origin.countryName}</li>
                        </ul>
                    </td>
                    <td className="cart-table__column cart-table__column--availability" data-title="Availability">
                         { item.productquantity > 0 ?
                        <div className="badge badge-success">{item.productquantity}</div>
                        :
                        <div className="badge badge-danger">Out of stock</div>
                    }
                    </td>
                    <td className="cart-table__column cart-table__column--price" data-title="Price">
                        <Currency value={Number(item[this.props.currency.cartProductPriceLabel])} />
                    </td>
                    <td className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                        <InputNumber
                            onChange={(quantity) => this.handleChangeQuantity(item, quantity)}
                            increment = {() => this.props.incrementQty(item, 1)}
                            decrement = {() => this.props.decrementQty(item, 1)}
                            value={this.getItemQuantity(item)}
                            min={item.quantityOrderMinimum}
                            max={item.quantityOrderMaximum}
                        />
                    </td>
                    <td className="cart-table__column cart-table__column--total" data-title="Total">
                        <Currency value={Number(item[this.props.currency.cartProductSubTotalLabel])} />
                    </td>
                    <td className="cart-table__column cart-table__column--remove">
                        {removeButton}
                    </td>
                </tr>
            );
        });
    }

    renderTotals() {
        const { cart } = this.props;

        if (cart.extraLines.length <= 0) {
            return null;
        }

        const extraLines = cart.extraLines.map((extraLine, index) => {
            let calcShippingLink;

            if (extraLine.type === 'shipping') {
                calcShippingLink = <div className="cart__calc-shipping"><Link to="/">Calculate Shipping</Link></div>;
            }

            return (
                <tr key={index}>
                    <th>{extraLine.title}</th>
                    <td>
                        <Currency value={extraLine.price} />
                        {calcShippingLink}
                    </td>
                </tr>
            );
        });

        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th>Subtotal</th>
                        <td><Currency value={cart[this.props.currency.cartAllSubtotalLabel]} /></td>
                    </tr>
                </thead>
                <tbody className="cart__totals-body">
                    {extraLines}
                </tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { cart, cartUpdateQuantities } = this.props;
        const { quantities } = this.state;

      

        return (
            <div className="cart block">
                <div className="container">
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--image">Image</th>
                                <th className="cart-table__column cart-table__column--product">Product</th>
                                <th className="cart-table__column cart-table__column--availability">Availability</th>
                                <th className="cart-table__column cart-table__column--price">Price</th>
                                <th className="cart-table__column cart-table__column--quantity">Quantity</th>
                                <th className="cart-table__column cart-table__column--total">Total</th>
                                <th className="cart-table__column cart-table__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">
                            {this.renderItems()}
                        </tbody>
                    </table>
                    <div className="row justify-content-end pt-md-5 pt-4">
                    <div className="col-12 col-md-5 col-lg-6 col-xl-7">
                        <div className="cart__actions">
                        <div className="cart__buttons">
                            <Link to="/" className="btn btn-primary">Continue Shopping</Link>
                        </div>
                    </div>
                    </div>
                        <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Cart Totals</h3>
                                    <table className="cart__totals">
                                        {this.renderTotals()}
                                        <tfoot className="cart__totals-footer">
                                            <tr className="cart__totals-column">
                                                <th>Total Amount</th>
                                                <td><Currency value={Number(cart[this.props.currency.cartAllTotalLabel])} /></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <Link to="/checkout" className="btn btn-primary btn-xl btn-block cart__checkout-button">
                                        Proceed to checkout
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { cart, loading } = this.props;
        const breadcrumb = [
            { value: 'Home', lineage: '' },
            { value: 'Shopping Cart', lineage: '' },
        ];

        let content;

        if (cart.quantity) {
            content = this.renderCart();
        } else {
            if(loading){
                content = (
                <div className="form-group btn-loading-center">
                        <button type="button" className={`btn btn-light btn-loading btn-xl btn-svg-icon`}>
                            <Quickview16Svg />
                        </button>
                    </div>
                );
            }
            else
            {
                content = (
                    <div className="block block-empty">
                        <div className="container">
                            <div className="block-empty__body">
                                <div className="block-empty__message">Your shopping cart is empty!</div>
                                <div className="block-empty__actions">
                                    <Link to="/" className="btn btn-primary btn-sm">Continue</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`My Cart — ${theme.name}`}</title>
                    <meta name="Description" content={`My Cart in goospares website saves time and efforts. Convenience to check put the products and shop for best price.`}/>
                </Helmet>

                <PageHeader header="Shopping Cart" breadcrumb={breadcrumb} />

                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
// console.log(state);
 return {
    cart: state.cart,
    loading: state.cart.loading,
    wishlist: state.wishlist,
    currency: state.currency,
    trackPage : trackPage
}
};

const mapDispatchToProps = {
    cartRemoveItem,
    cartUpdateQuantities,incrementQty, decrementQty, removeFromCartState
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);
