// react
import React from 'react';
import { Link } from 'react-router-dom';


function ProductTabDocuments(props) {
    const { product } = props;
     const reviewsList = props.product.productdigitalimage.map((doc, index) => (
        <li key={index}>
            <a className="product-card__digital" target="_blank" href={`${process.env.PUBLIC_ENDPOINT}${doc}`}>
                <img src={`${process.env.PUBLIC_URL}/images/doc.png`} className="img-fluid-custom"/>
            </a>
        </li>
    ));
    return (

        <div className="typography">
            <h3>Digital Document</h3>
            <ol className="digital-list">
                {reviewsList}
            </ol>
        </div>
    );
}

export default ProductTabDocuments;
