// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Img from 'react-image';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import Rating from './Rating';
import { cartAddItem,addToCart } from '../../store/cart';
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from '../../svg';
import { compareAddItem } from '../../store/compare';
import { quickviewOpen } from '../../store/quickview';
import { wishlistAddItem,addToWishlist } from '../../store/wishlist';
import Truncate from 'react-truncate';


function ProductCard(props) {
    const {
        product,
        layout,
        quickviewOpen,
        cartAddItem,
        addToCart,
        wishlistAddItem,
        addToWishlist,
        compareAddItem,
        currentState,
        currency,
        login_status,
        isLot
    } = props;
    const containerClasses = classNames('product-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });

    let badges = [];
    let image;
    let price;
    let features;
    let discountpercentage;
  
    if (product.productimages && product.productimages.length > 0) {
        image = (
            <div className="product-card__image">
                { !isLot ?
                <Link to={`/product/${product.id}/${product.sefurl}`}> 
                    <Img className="img-fluid-custom" alt={product.productimagetitle} 
                    title={product.productimagetitle} 
                    src={`${process.env.PUBLIC_ENDPOINT}${process.env.PRODUCT_IMG_PATH}${product.productdefaultimage?
                    product.productdefaultimage : product.productdefaultimage}`} unloader={ (<img
                    src={`${process.env.PUBLIC_URL}/images/default.png`}
                    className="img-fluid-custom"/>)}/>
                </Link>
                :
                <Img className="img-fluid-custom" alt={product.productimagetitle} 
                    title={product.productimagetitle} 
                    src={`${process.env.PUBLIC_ENDPOINT}${process.env.PRODUCT_IMG_PATH}${product.productdefaultimage?
                    product.productdefaultimage : product.productdefaultimage}`} unloader={ (<img
                    src={`${process.env.PUBLIC_URL}/images/default.png`}
                    className="img-fluid-custom"/>)}/>
                }
            </div>
        );
    }
    else
    {
        image = (
         <div className="product-card__image">
             <div className="card-image__default">
                <img src={`${process.env.PUBLIC_URL}/images/default.png`}/>
                </div>
            </div>
        )
    }

    if (product.discount && product[props.currency.discountPriceLabel]) {
        price = (
            <div className="product-card__prices">
                <span className="product-card__new-price"><Currency value={Number(product[props.currency.discountPriceLabel])} uom={product.productuom}/></span>
                {' '}
                <span className="product-card__old-price"><Currency value={Number(product[props.currency.productListPriceLabel])}  /></span>
            </div>
        );
    } else {
        price = (
            <div className="product-card__prices">
                <Currency value={Number(product[props.currency.productListPriceLabel])} uom={product.productuom} />
            
            </div>
        );
    }

    if (product.discountpercentage) {
        discountpercentage = (
            <div className="product-card__new-percentage">{product.discountpercentage}% OFF</div>
        );
    }

    return (
        <div className={containerClasses}>
        
            {badges}
            {image}
            <div className="product-card__info">
            { !isLot ?
                <div className="product-card__name">
                {layout != 'grid-5' ? 
                    <Link to={`/product/${product.id}/${product.sefurl}`}>{product.name}</Link>
                    :
                    <Link to={`/product/${product.id}/${product.sefurl}`}>
                        { (product.name.length > 50) ?
                            <span>{product.name.substring(0,50).concat('...')}</span>
                        : 
                            <span>{product.name}</span>
                        }
                        </Link>
                }
                </div>
                :
                <div className="product-card__name">
                {layout != 'grid-5' ? 
                    <span>{product.name}</span>
                    :
                       <span> { (product.name.length > 50) ?
                            <span>{product.name.substring(0,50).concat('...')}</span>
                        : 
                            <span>{product.name}</span>
                        }
                        </span>
                }
                </div>
                }
                 <div className="product-card__rating">
                    <Rating value={product.avgrating} />
                    <div className=" product-card__rating-legend">{`${product.avgratingcount ? product.avgratingcount : 'No'} Reviews`}</div>
                </div>
                {layout != 'grid-5' ? 
                <div className="product-card__attr">
                    <div className=" product-card__attr-legend">UNSPSC:&nbsp;{product.unspsc}</div>
                  <div className=" product-card__attr-legend">HSN:&nbsp;{product.hsncode}</div>
                </div>
                : ''}
                 <div className="product-card__description">
                 <Truncate lines={4} ellipsis={<span>...</span>}>
                                        {product.description}
                                    </Truncate>
                     </div>
                 <div className="product-card__other-description">
                     <ul>
                         <li>SKU: {product.splSku}</li>
                         <li>Mfg: {product.manufacturer}</li>
                         <li>Location: {product.source},&nbsp;{product.origincountry}</li>
                     </ul>
                 </div>
                     
               
                {features}
            </div>
            { !isLot ?
            <div className="product-card__actions">
                <div className="product-card__availability">
                    Availability:{' '}
                    <span className="text-success">{product.productquantity}</span>
                </div>
                {price}
                {discountpercentage}
                  { layout != 'grid-5' && product.productquantity == 0 ? 
                  <div className="product-card__outstock">
                        <label>Out of stock</label>
                    </div>
                      :
                    ''
                }
                <div className="product-card__buttons">
                    <AsyncAction
                        action={() => addToCart(product,product.quantityOrderMinimum,currentState)}
                        render={({ run, loading }) => (
                            <React.Fragment>
                                <button
                                    type="button"
                                    onClick={run}
				    disabled={product.productquantity == 0}
                                    className={classNames('btn btn-primary product-card__addtocart', {
                                        'btn-loading': loading,
                                    })}
                                >
                                    Add To Cart
                                </button>
                                <button
                                    type="button"
                                    onClick={run}
				    disabled={product.productquantity == 0}
                                    className={classNames('btn btn-secondary product-card__addtocart product-card__addtocart--list', {
                                        'btn-loading': loading,
                                    })}
                                >
                                    Add To Cart
                                </button>
                            </React.Fragment>
                        )}
                    />
                    { login_status == 'success' ?
                    <AsyncAction
                        action={() => addToWishlist(product,currentState)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist', {
                                    'btn-loading': loading,
                                })}
                            >
                                <Wishlist16Svg />
                            </button>
                        )}
                    />
                    : ''}
                    <AsyncAction
                        action={() => compareAddItem(product)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare', {
                                    'btn-loading': loading,
                                })}
                            >
                                <Compare16Svg />
                            </button>
                        )}
                    />
                </div>
            </div>
            : ''
        }
        </div>
    );
}

ProductCard.propTypes = {
    layout: PropTypes.oneOf(['grid-4', 'grid-4-sm', 'grid-5','grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = (state) => {
    return { 
         login_status :  state.user.login_status,
        currentState : state,currency: state.currency };
};

const mapDispatchToProps = {
    addToCart,
    cartAddItem,
    wishlistAddItem,
    addToWishlist,
    compareAddItem,
    quickviewOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCard);
