import { toast } from 'react-toastify';
import * as types from "./filtersActionTypes";
import axios from 'axios';
import queryString from 'query-string';

const userdata = localStorage.getItem('gouserdata') ? JSON.parse(localStorage.getItem('gouserdata')) : {};

var axios_instance = '';

if(userdata.token)
  axios_instance = axios.create({headers: {authToken: userdata.token}});
else 
  axios_instance = axios.create();

// Filters
export const filterManufacturers = (manufacturers) => ({
    type: types.FILTER_MANUFACTURERS,
    manufacturers
});
export const filterCategory = (categories) => ({
    type: types.FILTER_CATEGORIES,
    categories
});
export const filterAttrs = (attrs) => ({
    type: types.FILTER_ATTRS,
    attrs
});
export const filterSort = (sort_by) => ({
    type: types.SORT_BY,
    sort_by
});

export const resetFilters = () => ({
    type: types.RESET_FILTERS
});

export const resetProductFilters = filters => ({
    type: types.RESET_PRODUCTS_FILTERS,
    filters
})


export const resumeFilters = (manufacturer,categories,attrs) => ({
    type: types.RESUME_FILTERS,
    manufacturer,
    categories,
    attrs
});

