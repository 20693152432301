export const REGISTER_USER = 'REGISTER_USER'
export const LOGIN_USER = 'LOGIN_USER'
export const REGISTER_BEGIN = 'REGISTER_BEGIN'
export const REGISTER_END = 'REGISTER_END'
export const REGISTER_INIT = 'REGISTER_INIT'
export const REGISTER_DONE = 'REGISTER_DONE'
export const REGISTER_STATUS = 'REGISTER_STATUS'
export const LOGIN_BEGIN = 'LOGIN_BEGIN'
export const LOGIN_END = 'LOGIN_END'
export const LOGIN_DONE = 'LOGIN_DONE'
export const LOGIN_STATUS = 'LOGIN_STATUS'
export const LOGOUT_USER = 'LOGOUT_USER'
export const ACCOUNT_DETAIL = 'ACCOUNT_DETAIL'
export const UPDATE_START = 'UPDATE_START'
export const UPDATE_END = 'UPDATE_END'
export const FETCH_ORDERS = 'FETCH_ORDERS'
export const FETCH_SINGLE_ORDER_BEGIN = 'FETCH_SINGLE_ORDER_BEGIN'
export const FETCH_SINGLE_ORDER_DONE = 'FETCH_SINGLE_ORDER_DONE'
export const FETCH_SINGLE_ORDER = 'FETCH_SINGLE_ORDER'
export const COMMIT_ORDER_BEGIN = 'COMMIT_ORDER_BEGIN'
export const COMMIT_ORDER_END = 'COMMIT_ORDER_END'
export const READY_TO_ORDER = 'READY_TO_ORDER'