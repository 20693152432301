// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import Rating from '../shared/Rating';
import { cartAddItem,addToCart } from '../../store/cart';
import { Cross12Svg } from '../../svg';
import { wishlistRemoveItem,loadWishlist } from '../../store/wishlist';
import Img from 'react-image';

// data stubs
import theme from '../../data/theme';


class ShopPageWishlist extends React.Component {
	constructor(props){
		super(props);
	}

	  componentWillMount(){
          if (this.props.login_status == 'success') {
              this.props.loadWishlist();
          }
    }


	render(){
    const { wishlist, cartAddItem,addToCart, wishlistRemoveItem,currentState,currency } = this.props;
    const breadcrumb = [
        { value: 'Home', lineage: '' },
        { value: 'Save List', lineage: '' },
    ];

    let content;

    if (wishlist.length) {
        const itemsList = wishlist.map((item) => {
            let image;

            if (item.productimages.length > 0) {
                image = (
                    <Link to={`/product/${item.productId}/${item.name}`}>
                    <Img className="img-fluid-custom" src={`${process.env.PUBLIC_ENDPOINT}${process.env.PRODUCT_IMG_PATH}${item.productimages[0]}`} unloader={ (<img
                                    src={`${process.env.PUBLIC_URL}/images/default.png`}
                                    className="img-fluid-custom"/>)}/>
                    </Link>
                );
            }

            const renderAddToCarButton = ({ run, loading }) => {
                const classes = classNames('btn btn-primary btn-sm', {
                    'btn-loading': loading,
                });

                return <button type="button" onClick={run} disabled={item.productquantity == 0} className={classes}>Add To Cart</button>;
            };

            const renderRemoveButton = ({ run, loading }) => {
                const classes = classNames('btn btn-light btn-sm btn-svg-icon', {
                    'btn-loading': loading,
                });

                return <button type="button" onClick={run} className={classes} aria-label="Remove"><Cross12Svg /></button>;
            };

            return (
                <tr key={item.id} className="wishlist__row">
                    <td className="wishlist__column wishlist__column--image">
                        {image}
                    </td>
                    <td className="wishlist__column wishlist__column--product">
                        <Link to={`/product/${item.productId}/${item.name}`} className="wishlist__product-name">{item.name}</Link>
                         <ul className="cart-table__options">
                            <li> SKU: {item.sku}</li>
                            <li> Mfg: {item.manufacturer}</li>
                            <li> Location: {item.origin.source},&nbsp;{item.origin.countryName}</li>
                        </ul>
                    </td>
                    <td className="wishlist__column wishlist__column--stock">
                    { item.productquantity > 0 ?
                        <div className="badge badge-success">{item.productquantity}</div>
                        :
                        <div className="badge badge-danger">Out of stock</div>
                    }
                    </td>
                    <td className="wishlist__column wishlist__column--price"><Currency value={Number(item[this.props.currency.wishListProductPriceLabel])} /></td>
                    <td className="wishlist__column wishlist__column--tocart">
                        <AsyncAction
                        	action={() => addToCart(item,item.quantityOrderMinimum,currentState)}
                            render={renderAddToCarButton}
                        />
                    </td>
                    <td className="wishlist__column wishlist__column--remove">
                        <AsyncAction
                            action={() => wishlistRemoveItem(item)}
                            render={renderRemoveButton}
                        />
                    </td>
                </tr>
            );
        });

        content = (
            <div className="block">
                <div className="container">
                    <table className="wishlist">
                        <thead className="wishlist__head">
                            <tr className="wishlist__row">
                                <th className="wishlist__column wishlist__column--image">Image</th>
                                <th className="wishlist__column wishlist__column--product">Product</th>
                                <th className="wishlist__column wishlist__column--stock">Stock Availability</th>
                                <th className="wishlist__column wishlist__column--price">Price</th>
                                <th className="wishlist__column wishlist__column--tocart" aria-label="Add to cart" />
                                <th className="wishlist__column wishlist__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="wishlist__body">
                            {itemsList}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="block block-empty">
                <div className="container">
                    <div className="block-empty__body">
                        <div className="block-empty__message">Your save list is empty!</div>
                        <div className="block-empty__actions">
                            <Link to="/" className="btn btn-primary btn-sm">Continue</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Add your favorite products in the save List  — ${theme.name}`}</title>
                <meta name="Description" content={`Save list for shoppers. It allow users to create a shopping Wishlist of industrial products from goospares.com. Save list helps to 
check out the products easily.`}/>

            </Helmet>

            <PageHeader header="Save List" breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}
}

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
    currentState : state,
    login_status :  state.user.login_status,
    currency: state.currency
});

const mapDispatchToProps = {
 	cartAddItem,
    wishlistRemoveItem,
    addToCart,
    loadWishlist
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShopPageWishlist);
