// react
import React from 'react';

// data stubs
import theme from '../../data/theme';


export default function FooterNewsletter() {
    const socialLinks = [
        {
            key: 'facebook',
            url: theme.author.fb_url,
            iconClass: '/images/logos/facebook.png',
        },
        {
            key: 'twitter',
            url: theme.author.tw_url,
            iconClass: '/images/logos/twitter.png',
        },
        {
            key: 'youtube',
            url: theme.author.yt_url,
            iconClass: '/images/logos/yt.png',
        },
        {
            key: 'linkedin',
            url: theme.author.ln_url,
            iconClass: '/images/logos/linkedin.png',
        },
        {
            key: 'instagram',
            url: theme.author.insta_url,
            iconClass: '/images/logos/insta.png',
        }
        
    ];

    const socialLinksList = socialLinks.map((item) => (
        <li key={item.key} className="footer-newsletter__social-link">
            <a href={item.url} target="_blank" rel="noopener noreferrer">
                <img src={`${process.env.PUBLIC_URL}${item.iconClass}`} />
            </a>
        </li>
    ));

    return (
        <div className="site-footer__widget footer-newsletter">
            <h5 className="footer-newsletter__title">Newsletter</h5>
            <div className="footer-newsletter__text">
               Get all the latest information on Events, Sales and Offers. Sign up for newsletter today.
            </div>

            <form action="" className="footer-newsletter__form">
                <label className="sr-only" htmlFor="footer-newsletter-address">Email Address</label>
                <input
                    type="text"
                    className="footer-newsletter__form-input form-control"
                    id="footer-newsletter-address"
                    placeholder="Email Address..."
                />
                <button type="submit" className="footer-newsletter__form-button btn btn-primary">Subscribe</button>
            </form>

            <div className="footer-newsletter__text footer-newsletter__text--social">
                Follow us on social networks
            </div>

            <ul className="footer-newsletter__social-links">
                {socialLinksList}
            </ul>
        </div>
    );
}
