// react
import React, {Component} from 'react';

// third-party
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// application
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';

// data stubs
import theme from '../../data/theme';
import SimpleReactValidator from 'simple-react-validator';
import {connect} from "react-redux";

import { toast  } from 'react-toastify';
import { loginCustomer,loginStatus,resetPassword,registerCustomer,logout } from '../../store/user';
import { listCart } from '../../store/cart';
import {withRouter} from 'react-router-dom';


class AccountPageLogin extends Component  {
      constructor(props) {
    super(props);
    this.state = {
     "password": "",
     "userName": "",
     "custFirstName": "",
     "custLastName": "",
     "emailAddress": "",
     "mobileNumber": "",
     "custGSTIN":"", 
     "storeCode":"DEFAULT",
     "language":"en",
     "forgotPassword":false,
     "reset_success":false,
     "registerOpen":false,
     "loginOpen":true
    };
     this.validator = new SimpleReactValidator();
    this.setStateFromInput = this.setStateFromInput.bind(this);
    this.forgotpasswordManage = this.forgotpasswordManage.bind(this);
    this.registerUserManage = this.registerUserManage.bind(this);
  }
  componentWillMount(){
    this.props.dispatch(logout());
  }


   setStateFromInput = (event) => {
        var obj = {};
        if(event.target.dataset.object){
             var key = event.target.dataset.object;
             var name = event.target.name;
             var value =  event.target.value;
                this.setState(state => {
                      state[key][name] = value;
                      return state;
                })
           }
           else{
               obj[event.target.name] = event.target.value;
               this.setState(obj);
        }
    }

     handleSubmit = (event) => {
    console.log(event);
    event.preventDefault();
    if (this.validator.allValid()) {
        if(this.state.loginOpen){
            this.props.dispatch(loginCustomer(this.state))
            .then(response => {
              if(localStorage.getItem('myvmarketcartcode')){
                this.props.dispatch(listCart(this.props.currency));
              }
              this.props.history.push("/");
            })
              .catch(err => {
                toast.error("Login Failed. Please enter valid credentials");
              });
        }
        else if(this.state.registerOpen){
             this.props.dispatch(registerCustomer(this.state))
              .then(response => {
                      toast.success("Registration Success.");
                })
                  .catch(err => {
                    console.log(err); 
                      toast.error(err.message);
                  });
        }
        else if(this.state.forgotPassword)
        {
           this.props.dispatch(resetPassword(this.state.userName))
            .then(response => {
                this.setState(state => {
                    state['reset_success'] = !state.reset_success;
                    return state;
                });
            })
              .catch(err => {
                toast.error("Unable to complete your request. Looks like your email address is not registered with us.");
              });
        }
    } else {
              this.validator.showMessages();
              this.forceUpdate();
    }
  }

  forgotpasswordManage = () => {
     this.validator = new SimpleReactValidator();
     this.setState(state => {
            state['forgotPassword'] = !state.forgotPassword;
            state['loginOpen'] = !state.loginOpen;
            state['reset_success'] = false;
            return state;
      });
  }

  registerUserManage = () => {
     this.validator = new SimpleReactValidator();
     this.setState(state => {
            state['registerOpen'] = !state.registerOpen;
            state['loginOpen'] = false;
            state['forgotPassword'] = false;
            state['reset_success'] = false;
            return state;
      });
   }

  render(){
    const breadcrumb = [
        { value: 'Home', lineage: '' },
        { value: 'My Account', lineage: '' },
    ];
    const { login_status , login_loading, currency } = this.props;
    let content;
    let loadSection;
    loadSection = () => {
       if(this.state.loginOpen){
          content = (
      <React.Fragment>
       <div className="form-group">
                                            <label htmlFor="login-email">Email address</label>
                                            <input
                                                id="login-email"
                                                type="email"
                                                name="userName"
                                                className="form-control"
                                                placeholder="Enter email"
                                                value={this.state.userName} onChange={this.setStateFromInput} />
                                                    {this.validator.message('userName', this.state.userName, 'required')}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="login-password">Password</label>
                                            <input
                                                id="login-password"
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                placeholder="Password"
                                                value={this.state.password} onChange={this.setStateFromInput} />
                                                    {this.validator.message('password', this.state.password, 'required')}
                                            <small className="form-text form-txt-mt login-helper-txt">
                                                <span onClick={this.forgotpasswordManage}>Forgot Password?</span>
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input
                                                            id="login-remember"
                                                            type="checkbox"
                                                            className="input-check__input"
                                                        />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="login-remember">
                                                    Remember Me
                                                </label>
                                            </div>
                                        </div>
                                         <button type="submit" className={classNames('btn btn-primary', {
                                            'btn-loading': login_loading,
                                        })}>
                                            Login
                                        </button>
                                        </React.Fragment>
      ) 
      }
      else if(this.state.forgotPassword)
      {
        content = (
             <React.Fragment>
              <div className="form-group">
                  <label htmlFor="login-email">Email address</label>
                  <input
                      id="login-email"
                      type="email"
                      name="userName"
                      className="form-control"
                      placeholder="Enter email"
                      value={this.state.userName} onChange={this.setStateFromInput} />
                          {this.validator.message('userName', this.state.userName, 'required')}
              </div>
               <div className="form-group d--btn-block">
                      <button type="submit" className={classNames('btn btn-primary', {
                          'btn-loading': login_loading,
                      })}>Reset</button>
                       <span className="d-flex-span login-helper-txt" onClick={this.forgotpasswordManage}>Having credentials, login now? </span>
                  </div>
              </React.Fragment>
          )
      }
   
    return content;
  }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login - ${theme.name}`}</title>
                <meta name="Description" content={`Login and get access to top brand industrial spare product suppliers - Goospares.com. Want to contact us? Email or Contact Us today!`}/>
            </Helmet>

            <PageHeader header="My Account" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                { !this.state.reset_success ? 
                                <div className="card-body">

                                {!this.state.forgotPassword ?    <h3 className="card-title">Login</h3> :  
                                <h3 className="card-title">Forgot your password</h3>  }
                                    
                                    <form onSubmit={this.handleSubmit.bind(this)} noValidate>
                                        {loadSection()}
                                    </form>

                                </div>
                                : 
                                <div className="card-body">
                                 <h3 className="card-title">Reset your password</h3>
                                  <p>If you are an existing customer, 
                                  the new password will be generated and mail will be sent to the <b>{this.state.userName}</b></p> 
                                      <button onClick={this.forgotpasswordManage} className="btn btn-primary d-flex-btn">Go To Login
                                      </button>
                                </div>
                                }

                            </div>
                        </div>
                        <div className="col-md-5 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title">New Customer</h3>
                                    <h6 className="title-font">Create An Account</h6>
                                    <p>Sign up for a free account at our store. Registration is quick and easy. It
                                        allows you to be able to order from our shop. To start shopping click
                                        register.</p>
                                         <Link className="btn btn-primary mt-2 mt-md-3 mt-lg-4" to="/account/register">
                                            Create Account
                                        </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
}


function mapStateToProps(state) {
    return {
        login_loading : state.user.login_loading,
        login_status :  state.user.login_status,
        currency : state.currency
    }
}

export default connect(mapStateToProps)(withRouter(AccountPageLogin))




